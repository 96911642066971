import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Yukon = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('yu_program_title')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Programs */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('yu_overview_title')}
              </h3>
              <p className="text-gray-700">{t('yu_overview_intro')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('yu_overview_skilled_worker_title')}</strong> {t('yu_overview_skilled_worker_description')}
                </li>
                <li>
                  <strong>{t('yu_overview_critical_impact_worker_title')}</strong> {t('yu_overview_critical_impact_worker_description')}
                </li>
                <li>
                  <strong>{t('yu_overview_express_entry_title')}</strong> {t('yu_overview_express_entry_description')}
                </li>
                <li>
                  <strong>{t('yu_overview_business_nominee_title')}</strong> {t('yu_overview_business_nominee_description')}
                </li>
              </ul>
            </div>
          </div>

          {/* Step 2: Eligibility Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('yu_requirements_title')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('yu_requirements_skilled_worker_title')}</strong> {t('yu_requirements_skilled_worker_description')}
                </li>
                <li>
                  <strong>{t('yu_requirements_critical_impact_worker_title')}</strong> {t('yu_requirements_critical_impact_worker_description')}
                </li>
                <li>
                  <strong>{t('yu_requirements_express_entry_title')}</strong> {t('yu_requirements_express_entry_description')}
                </li>
                <li>
                  <strong>{t('yu_requirements_business_nominee_title')}</strong> {t('yu_requirements_business_nominee_description')}
                </li>
              </ul>
            </div>
          </div>
          {/* Step 3: Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-6">
                {t('yu_application_process_title')}
              </h3>

              {/* Step 3.1: Check Eligibility and Select a Stream */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('yu_check_eligibility_title')}
                </h4>
                <p className="text-gray-700 mb-4">{t('yu_check_eligibility_description')}</p>
              </div>

              {/* Step 3.2: Prepare Documentation */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('yu_prepare_documentation_title')}
                </h4>
                <p className="text-gray-700 mb-4">{t('yu_prepare_documentation_description')}</p>   
              </div>

              {/* Step 3.3: Submit Application to the Yukon Government */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('yu_submit_application_yukon_title')}
                </h4>
                
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('yu_submit_application_yukon_form')}</li>
                  <li>{t('yu_submit_application_yukon_attach')}</li>
                  <li>{t('yu_submit_application_yukon_submit')}</li>
                </ul>
              </div>

              {/* Step 3.4: Employer's Role */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('yu_employer_role_title')}
                </h4>
                <p className="text-gray-700">{t('yu_employer_role_description')}</p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('yu_employer_role_recruitment')}</li>
                  <li>{t('yu_employer_role_stream_selection')}</li>
                  <li>{t('yu_employer_role_submit_application')}</li>
                </ul>
              </div>

              {/* Step 3.5: Nomination by Yukon */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('yu_nomination_yukon_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('yu_nomination_yukon_issue_certificate')}</li>
                  <li>{t('yu_nomination_yukon_fees_documents')}</li>
                </ul>
              </div>

              {/* Step 3.6: Tri-Partite Agreement (TPA) */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('yu_tri_partite_agreement_title')}
                </h4>
                <p className="text-gray-700">{t('yu_tri_partite_agreement_description')}</p>
              </div>

              {/* Step 3.7: Apply for Permanent Residence */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('yu_apply_permanent_residence_title')}
                </h4>
                <p className="text-gray-700">{t('yu_apply_permanent_residence_submit_application')}</p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('yu_apply_permanent_residence_create_profile')}</li>
                  <li>{t('yu_apply_permanent_residence_submit_ircc')}</li>
                  <ul className="list-disc list-inside ml-8 text-gray-700">
                    <li>{t('yu_apply_permanent_residence_nomination')}</li>
                    <li>{t('yu_apply_permanent_residence_medical_police')}</li>
                  </ul>
                </ul>
              </div>

              {/* Step 3.8: Maintain Legal Status */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('yu_maintain_legal_status_title')}
                </h4>
                <p className="text-gray-700">{t('yu_maintain_legal_status_description')}</p>
              </div>

              {/* Step 3.9: Wait for IRCC Decision */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('yu_wait_ircc_decision_title')}
                </h4>
                <p className="text-gray-700">{t('yu_wait_ircc_decision_description')}</p>
              </div>

              {/* Step 3.10: Permanent Residency */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('yu_permanent_residency_title')}
                </h4>
                <p className="text-gray-700">{t('yu_permanent_residency_description')}</p>
              </div>
            </div>
          </div>

          {/* Step 4: Pros & Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('yu_pros_cons_title')}
              </h3>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('yu_pros_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('yu_pros_description_1')}</li>
                <li>{t('yu_pros_description_2')}</li>
              </ul>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('yu_cons_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('yu_cons_description_1')}</li>
                <li>{t('yu_cons_description_2')}</li>
              </ul>
            </div>
          </div>
          {/* Step 5: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('next_actions')}
                </h3>
                <p className="text-gray-700 mb-4">
                  {t('yu_next_actions_description')}
                </p>
              </div>

              {/* Right side - Button aligned to the left */}
              <div className="w-full md:w-1/4 text-center">
                <Link
                  to="https://yukon.ca/en/immigrate-yukon"
                  target="_blank" // Opens in a new window
                  rel="noopener noreferrer" // Security enhancement
                  className="inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Image at the beginning of the button */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-4"
                  />
                  {t('yu_learn_more')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Yukon;

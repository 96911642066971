import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './i18n';
import Header from './components/Header';
import Footer from './components/Footer';
import SupportWidget from './components/SupportWidget';
import Home from './pages/Home';
import NewsRoom from './pages/NewsRoom';
import PermanentResident from './pages/PermanentResident';
import TemporaryResident from './pages/TemporaryResident';
import Dictionary from './pages/Dictionary';
import CanadaImmigrationSupport from './pages/CanadaImmigrationSupport';
import CRSCalculator from './pages/CRSCalculator'; // Import CRSCalculator page
import PGWPEligibility from './pages/PGWPEligibility'; // Import PGWPEligibility page
import PGWPPolicy from './pages/PGWPPolicy'; // Import PGWPPolicy page
import PGWPAssessment from './pages/PGWPAssessment'; // Import PGWPPolicy page
import PGWPApplication from './pages/PGWPApplication'; // Import PGWPApplication page
import TRVApplication from './pages/TRVApplication'; // Import TRVApplication page

import CLBConversion from './pages/CLBConversion';
import LMIAMap from './pages/LMIAMap';
import ExpressEntry from './pages/ExpressEntry';
import FederalSkilledWorker from './pages/FederalSkilledWorker';
import FederalSkilledTrades from './pages/FederalSkilledTrades';
import CanadianExperienceClass from './pages/CanadianExperienceClass';
import SpousePartnerChildren from './pages/SpousePartnerChildren';
import AdoptedChild from './pages/AdoptedChild';
import ParentsGrandparents from './pages/ParentsGrandparents';
import OtherRelatives from './pages/OtherRelatives';
import StartUpVisa from './pages/StartUpVisa';


import PNP from './pages/PNP'; // Import PNP page
import Ontario from './pages/Ontario';
import BritishColumbia from './pages/BritishColumbia';
import Alberta from './pages/Alberta';
import Saskatchewan from './pages/Saskatchewan';
import Manitoba from './pages/Manitoba';
import NovaScotia from './pages/NovaScotia';
import NewBrunswick from './pages/NewBrunswick';
import PrinceEdwardIsland from './pages/PrinceEdwardIsland';
import Newfoundland from './pages/Newfoundland';
import Yukon from './pages/Yukon';
import NorthwestTerritories from './pages/NorthwestTerritories';



import QuebecPrograms from './pages/QuebecPrograms'; // Import Quebec Programs page
import QuebecSkilledWorker from './pages/QuebecSkilledWorker'; // Import QuebecSkilledWorker Programs page
import QuebecBusinessImmigration from './pages/QuebecBusinessImmigration'; // Import QuebecBusinessImmigration Programs page
import QuebecFamilySponsorship from './pages/QuebecFamilySponsorship'; // Import QuebecFamilySponsorship Programs page
import QuebecExperienceProgram from './pages/QuebecExperienceProgram'; // Import QuebecExperienceProgram Programs page

import Sponsorship from './pages/Sponsorship'; // Import Sponsorship page
import Work from './pages/Work'; // Import Work page
import Study from './pages/Study'; // Import Study page
import Visitor from './pages/Visitor'; // Import Visitor page
import TermsAndConditions from './pages/TermsAndConditions'; // Import TermsAndConditions page
import PrivacyPolicy from './pages/PrivacyPolicy'; // Import PrivacyPolicy page
import ContactUs from './pages/ContactUs'; // Import ContactUs page
import SupportUs from './pages/SupportUs'; // Import SupportUs page

// Google Analytics
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

ReactGA.initialize("G-09KXLBP511"); // Replace with your Measurement ID

function App() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    // <Router>
      <div className="flex flex-col min-h-screen">
        {/* Sticky Header */}
        <div className="sticky top-0 z-50">
          <Header />
        </div>
        <main className="flex-grow bg-gray-light">
          <Routes>
            {/* Permanent Resident Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/permanent-resident" element={<PermanentResident />} />
            <Route path="/permanent-resident/express-entry" element={<ExpressEntry />} />
            <Route path="/permanent-resident/express-entry/federal-skilled-worker" element={<FederalSkilledWorker />} />
            <Route path="/permanent-resident/express-entry/federal-skilled-trades" element={<FederalSkilledTrades />} />
            <Route path="/permanent-resident/express-entry/canadian-experience-class" element={<CanadianExperienceClass />} />

            <Route path="/permanent-resident/pnp" element={<PNP />} />
            <Route path="/permanent-resident/pnp/ontario" element={<Ontario />} />
            <Route path="/permanent-resident/pnp/british-columbia" element={<BritishColumbia />} />
            <Route path="/permanent-resident/pnp/alberta" element={<Alberta />} />
            <Route path="/permanent-resident/pnp/saskatchewan" element={<Saskatchewan />} />
            <Route path="/permanent-resident/pnp/manitoba" element={<Manitoba />} />
            <Route path="/permanent-resident/pnp/nova-scotia" element={<NovaScotia />} />
            <Route path="/permanent-resident/pnp/new-brunswick" element={<NewBrunswick />} />
            <Route path="/permanent-resident/pnp/prince-edward-island" element={<PrinceEdwardIsland />} />
            <Route path="/permanent-resident/pnp/newfoundland-labrador" element={<Newfoundland />} />
            <Route path="/permanent-resident/pnp/yukon" element={<Yukon />} />
            <Route path="/permanent-resident/pnp/northwest-territories" element={<NorthwestTerritories />} />


            <Route path="/permanent-resident/quebec" element={<QuebecPrograms />} />
            <Route path="/permanent-resident/quebec/quebec-skilled-worker" element={<QuebecSkilledWorker />} />
            <Route path="/permanent-resident/quebec/quebec-business-immigration" element={<QuebecBusinessImmigration />} />
            <Route path="/permanent-resident/quebec/quebec-family-sponsorship" element={<QuebecFamilySponsorship />} />
            <Route path="/permanent-resident/quebec/quebec-experience-program" element={<QuebecExperienceProgram />} />

            <Route path="/permanent-resident/sponsorship" element={<Sponsorship />} />
            <Route path="/permanent-resident/sponsorship/spouse-partner-children" element={<SpousePartnerChildren />} />
            <Route path="/permanent-resident/sponsorship/adopted-child" element={<AdoptedChild />} />
            <Route path="/permanent-resident/sponsorship/parents-grandparents" element={<ParentsGrandparents />} />
            <Route path="/permanent-resident/sponsorship/other-relatives" element={<OtherRelatives />} />

            <Route path="/permanent-resident/startup-visa" element={<StartUpVisa />} />

            {/* Temporary Resident Routes */}
            <Route path="/temporary-resident" element={<TemporaryResident />} />
            <Route path="/temporary-resident/work" element={<Work />} />
            <Route path="/temporary-resident/study" element={<Study />} />
            <Route path="/temporary-resident/visitor" element={<Visitor />} />
            <Route path="/temporary-resident/trv" element={<TRVApplication />} />

            {/* Canada Immigration Support Routes */}
            <Route path="/canada-immigration-support" element={<CanadaImmigrationSupport />} />
            <Route path="/canada-immigration-support/crs-score-calculator" element={<CRSCalculator />} />
            <Route path="/canada-immigration-support/pgwp-eligibility" element={<PGWPEligibility />} />
            <Route path="/canada-immigration-support/pgwp-eligibility/policy" element={<PGWPPolicy />} />
            <Route path="/canada-immigration-support/pgwp-eligibility/assessment" element={<PGWPAssessment />} />
            <Route path="/canada-immigration-support/pgwp-application" element={<PGWPApplication />} />

            <Route path="/canada-immigration-support/clb-conversion" element={<CLBConversion />} />
            {/* <Route path="/canada-immigration-support/lmia-map" element={<LMIAMap />} /> */}

            {/* Additional Routes */}
            <Route path="/news-room" element={<NewsRoom />} />
            <Route path="/dictionary" element={<Dictionary />} />

            {/* Footer Routes */}
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/support-us" element={<SupportUs />} />
            <Route path="/lmia-tracker" element={<LMIAMap />} />


          </Routes>
          {/* Add the SupportWidget to be shown across all pages */}
          {/* <SupportWidget /> */}
        </main>
        <Footer />
      </div>
    // </Router>
  );
}

export default App;

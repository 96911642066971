import React from 'react';
import { useTranslation } from 'react-i18next';

const PGWPPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="p-8 bg-gray-100">
      {/* Page Heading */}
      <h1 className="text-4xl font-bold text-center text-red-600 mb-8">{t('policy_description')}</h1>
      
      <div className="relative">
        <div className="border-l-4 border-red-600 ml-6">
          {/* What Hasn't Changed */}
          <div className="relative mb-8 pl-8">
            <div className="absolute w-4 h-4 bg-red-600 rounded-full -left-2 top-1"></div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-red-600 mb-2">{t('pgwp_what_hasnt_changed')}</h3>
              <ul className="list-disc list-inside text-gray-700">
                <li>{t('pgwp_eligibility_requires_dli')}</li>
                <li>{t('pgwp_before_nov_1_2024')}</li>
                <li>{t('pgwp_flight_school_eligibility')}</li>
              </ul>
            </div>
          </div>

          {/* New Eligibility Requirements */}
          <div className="relative mb-8 pl-8">
            <div className="absolute w-4 h-4 bg-red-600 rounded-full -left-2 top-1"></div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-red-600 mb-2">{t('pgwp_new_requirements')}</h3>
              <ul className="list-disc list-inside text-gray-700">
                <li>{t('pgwp_permit_before_nov_1_2024')}</li>
                <ul className="list-disc list-inside ml-6 text-gray-600">
                  <li>{t('pgwp_university_degree_clb7')}</li>
                  <li>{t('pgwp_college_programs_clb5')}</li>
                </ul>
                <li>{t('pgwp_permit_after_nov_1_2024')}</li>
                <ul className="list-disc list-inside ml-6 text-gray-600">
                  <li>{t('pgwp_university_degree_new')}</li>
                  <li>{t('pgwp_other_university_programs')}</li>
                  <li>{t('pgwp_college_programs_new')}</li>
                </ul>
              </ul>
            </div>
          </div>

          {/* Language Requirements */}
          <div className="relative mb-8 pl-8">
            <div className="absolute w-4 h-4 bg-red-600 rounded-full -left-2 top-1"></div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-red-600 mb-2">{t('pgwp_language_requirements')}</h3>
              <p className="text-gray-700">{t('pgwp_proof_language_skills')}</p>
              <ul className="list-disc list-inside mt-2 text-gray-700">
                <li>{t('pgwp_language_tests')}</li>
                <li>{t('pgwp_language_test_validity')}</li>
              </ul>
            </div>
          </div>

          {/* Field of Study Requirements */}
          <div className="relative mb-8 pl-8">
            <div className="absolute w-4 h-4 bg-red-600 rounded-full -left-2 top-1"></div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-red-600 mb-2">{t('pgwp_field_of_study_requirement')}</h3>
              <p className="text-gray-700">{t('pgwp_field_of_study_intro')}</p>
              <ul className="list-disc list-inside mt-2 text-gray-700">
                <li>{t('pgwp_agriculture_agri_food')}</li>
                <li>{t('pgwp_healthcare')}</li>
                <li>{t('pgwp_stem')}</li>
                <li>{t('pgwp_trade')}</li>
                <li>{t('pgwp_transport')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PGWPPolicy;

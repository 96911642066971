import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ExpressEntry = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('express_entry')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: What is Express Entry? */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('what_is_express_entry')}</h3>
              <p className="text-gray-700">{t('express_entry_intro')}</p>
            </div>
          </div>

          {/* Step 2: Programs under Express Entry */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-4">
                {t('programs_under_express_entry')}
              </h3>

              {/* Flex container for programs in separate clickable tiles */}
              <div className="flex flex-col md:flex-row gap-6">

                {/* Federal Skilled Worker Program */}
                <Link
                  to="/permanent-resident/express-entry/federal-skilled-worker"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-1/3 bg-red-100 p-4 rounded-lg shadow-md hover:bg-red-200 transform transition-transform duration-300 hover:scale-105 flex items-center justify-center"
                >
                  <div>
                    <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                      {t('federal_skilled_worker')}
                    </h4>
                    <p className="text-gray-700 text-center">
                      {t('federal_skilled_worker_description')}
                    </p>
                    <p className="text-gray-700 text-center">
                      <strong>{t('click_to_learn_more')}</strong>
                    </p>
                  </div>
                </Link>

                {/* Federal Skilled Trades Program */}
                <Link
                  to="/permanent-resident/express-entry/federal-skilled-trades"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-1/3 bg-red-100 p-4 rounded-lg shadow-md hover:bg-red-200 transform transition-transform duration-300 hover:scale-105 flex items-center justify-center"
                >
                  <div>
                    <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                      {t('federal_skilled_trades')}
                    </h4>
                    <p className="text-gray-700 text-center">
                      {t('federal_skilled_trades_description')}
                    </p>
                    <p className="text-gray-700 text-center">
                      <strong>{t('click_to_learn_more')}</strong>
                    </p>
                  </div>
                </Link>

                {/* Canadian Experience Class */}
                <Link
                  to="/permanent-resident/express-entry/canadian-experience-class"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-1/3 bg-red-100 p-4 rounded-lg shadow-md hover:bg-red-200 transform transition-transform duration-300 hover:scale-105 flex items-center justify-center"
                >
                  <div>
                    <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                      {t('canadian_experience_class')}
                    </h4>
                    <p className="text-gray-700 text-center">
                      {t('canadian_experience_class_description')}
                    </p>
                    <p className="text-gray-700 text-center">
                      <strong>{t('click_to_learn_more')}</strong>
                    </p>
                  </div>
                </Link>

              </div>
            </div>
          </div>

          {/* Step 3: Benefits of Express Entry */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">
              {/* Left side - Text */}
              <div className="w-full md:w-2/3 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('benefits_of_express_entry')}</h3>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li><strong>{t('fast_processing')}</strong>: {t('fast_processing_description')}</li>
                  <li><strong>{t('flexibility')}</strong>: {t('flexibility_description')}</li>
                  <li><strong>{t('crs_points')}</strong>: {t('crs_points_description')}</li>
                  <li><strong>{t('ita_process')}</strong>: {t('ita_process_description')}</li>
                </ul>
              </div>

              {/* Right side - Button */}
              <div className="w-full md:w-1/3 text-center">
                <Link
                  to="/canada-immigration-support/crs-score-calculator"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-12 h-12 sm:w-20 sm:h-20 mr-4"
                  />
                  {t('calculate_your_crs_score')}
                </Link>
              </div>
            </div>
          </div>

          {/* Step 4: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('next_actions')}</h3>
              <p className="text-gray-700 mb-4">{t('next_actions_description')}</p>
              <Link
                to="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry.html"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
              >
                {t('click_to_start_ee')} →
              </Link>
            </div>
          </div>



        </div>
      </div>
    </div>
  );
};

export default ExpressEntry;

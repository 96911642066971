import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FederalSkilledWorker = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('federal_skilled_worker_program')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-6 sm:ml-8">

          {/* Overview */}
          <div className="timeline-step relative mb-8 pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('overview_of_program')}
              </h3>
              <p className="text-gray-700">{t('federal_skilled_worker_overview')}</p>
            </div>
          </div>

          {/* Eligibility Requirements */}
          <div className="timeline-step relative mb-8 pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and call-to-action button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">
              
              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('eligibility_requirements')}
                </h3>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li><strong>{t('work_experience')}</strong>: {t('work_experience_description')}</li>
                  <li><strong>{t('education')}</strong>: {t('education_description')}</li>
                  <li><strong>{t('language_proficiency')}</strong>: {t('language_proficiency_description')}</li>
                  <li><strong>{t('minimum_score')}</strong>: {t('minimum_score_description')}</li>
                  <li><strong>{t('proof_of_funds')}</strong>: {t('proof_of_funds_description')}
                    <ul className="list-disc list-inside ml-4 text-gray-700">
                      <li>{t('fsw_work_legally_description')}
                        <a
                          href="/temporary-resident/work"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                        >
                          {t('learn_more')}
                          <svg
                            className="w-4 h-4 ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 7l5 5m0 0l-5 5m5-5H6"
                            />
                          </svg>
                        </a>

                      </li>
                      <li>{t('fsw_valid_job_offer_description')}</li>
                    </ul>
                  </li>
                  <li><strong>{t('health_security')}</strong>: {t('health_security_description')}</li>
                </ul>
              </div>

              {/* Right side - Button aligned to the left */}
              <div className="w-full md:w-1/4 text-center">
                <Link
                  to="/canada-immigration-support/clb-conversion"
                  target="_blank" // Opens in a new window
                  rel="noopener noreferrer" // Security enhancement
                  className="mt-6 inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Image at the beginning of the button */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-4"
                  />
                  {t('convert_your_clb_score')}
                </Link>
                <Link
                  to="/lmia-tracker"
                  target="_blank" // Opens in a new window
                  rel="noopener noreferrer" // Security enhancement
                  className="mt-6 inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Image at the beginning of the button */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-4"
                  />
                  {t('learn_more_about_lmia')}
                </Link>
              </div>
            </div>
          </div>

          {/* How to Apply */}
          <div className="timeline-step relative mb-8 pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('how_to_apply')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('check_eligibility')}</strong>: {t('check_eligibility_description')}</li>
                <li><strong>{t('prepare_documents')}</strong>: {t('prepare_documents_description')}</li>
                <li><strong>{t('create_express_entry_profile')}</strong>: {t('create_express_entry_profile_description')}</li>
                <li><strong>{t('receive_ita')}</strong>: {t('receive_ita_description')}</li>
                <li><strong>{t('submit_pr_application')}</strong>: {t('submit_pr_application_description')}</li>
              </ul>
            </div>
          </div>

          {/* Pros and Cons */}
          <div className="timeline-step relative mb-8 pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and call-to-action button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('pros_cons')}
                </h3>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <strong>{t('pros')}</strong>:
                  <ul className="ml-4 list-disc">
                    <li>{t('fast_processing_time')}</li>
                    <li>{t('family_benefits')}</li>
                    <li>{t('flexible_points_system')}</li>
                  </ul>

                  <strong>{t('cons')}</strong>:
                  <ul className="ml-4 list-disc">
                    <li>{t('high_competition')}</li>
                    <li>{t('financial_proof_requirement')}</li>
                  </ul>
                </ul>
              </div>

              {/* Right side - Call-to-action button centered */}
              <div className="w-full md:w-1/4 flex justify-center">  {/* Center the button */}
                <Link
                  to="/canada-immigration-support/crs-score-calculator"
                  target="_blank"
                  rel="noopener noreferrer" 
                  className="inline-flex items-center justify-center bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Smaller Image */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-2"  // Smaller image
                  />
                  {t('calculate_your_crs_score')}
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default FederalSkilledWorker;

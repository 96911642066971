import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const CLBConversion = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    language_test: "", // Pre-set to IELTS based on example
    speaking: "",
    reading: "",
    listening: "",
    writing: ""
  });

  const [responseData, setResponseData] = useState({
    clb_levels: null,
    overall_clb: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      language_test: {
        type: formData.language_test,
        speaking: formData.speaking,
        listening: formData.listening,
        reading: formData.reading,
        writing: formData.writing
      }
    };

    try {
      // const response = await axios.post('http://127.0.0.1:5001/api/language_to_clb', requestBody); // Dev
      // const response = await axios.post('http://canadaentry.net:5001/api/language_to_clb', requestBody); // Test
      const response = await axios.post('/api/language_to_clb', requestBody, {headers: {'Content-Type': 'application/json'}}); // Prod
      setResponseData({
        clb_levels: response.data.clb_levels,
        overall_clb: response.data.overall_clb
      });
    } catch (error) {
      console.error('Error submitting the form', error);
    }
  };

  const languageTestOptions = [
    { value: '', label: t('select_language_test') },
    { value: 'CELPIP-G', label: t('CELPIP-G') },
    { value: 'IELTS', label: t('IELTS') },
    { value: 'PTE Core', label: t('PTE Core') },
    { value: 'TEF Canada', label: t('TEF Canada') },
    { value: 'TCF Canada', label: t('TCF Canada') },
  ];



  const renderLanguageFields = () => {
    // Define options with ielts_ prefix
    const ielts_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '7.5 - 9.0', label: t('7.5 - 9.0') },
      { value: '7.0', label: t('7.0') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '0 - 3.5', label: t('0 - 3.5') },
    ];

    const ielts_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '8.5 - 9.0', label: t('8.5 - 9.0') },
      { value: '8.0', label: t('8.0') },
      { value: '7.5', label: t('7.5') },
      { value: '6.0 - 7.0', label: t('6.0 - 7.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.5', label: t('4.5') },
      { value: '0 - 4.0', label: t('0 - 4.0') },
    ];

    const ielts_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '8.0 - 9.0', label: t('8.0 - 9.0') },
      { value: '7.0 - 7.5', label: t('7.0 - 7.5') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.0 - 5.5', label: t('5.0 - 5.5') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '3.5', label: t('3.5') },
      { value: '0 - 3.0', label: t('0 - 3.0') },
    ];

    const ielts_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '7.5 - 9.0', label: t('7.5 - 9.0') },
      { value: '7.0', label: t('7.0') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '0 - 3.5', label: t('0 - 3.5') },
    ];

    const celpip_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    // Define options with pte_ prefix
    const pte_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '89 - 90', label: t('89 - 90') },
      { value: '84 - 88', label: t('84 - 88') },
      { value: '76 - 83', label: t('76 - 83') },
      { value: '68 - 75', label: t('68 - 75') },
      { value: '59 - 67', label: t('59 - 67') },
      { value: '51 - 58', label: t('51 - 58') },
      { value: '42 - 50', label: t('42 - 50') },
      { value: '0 - 41', label: t('0 - 41') },
    ];

    const pte_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '89 - 90', label: t('89 - 90') },
      { value: '82 - 88', label: t('82 - 88') },
      { value: '71 - 81', label: t('71 - 81') },
      { value: '60 - 70', label: t('60 - 70') },
      { value: '50 - 59', label: t('50 - 59') },
      { value: '39 - 49', label: t('39 - 49') },
      { value: '28 - 38', label: t('28 - 38') },
      { value: '0 - 27', label: t('0 - 27') },
    ];

    const pte_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '88 - 90', label: t('88 - 90') },
      { value: '78 - 87', label: t('78 - 87') },
      { value: '69 - 77', label: t('69 - 77') },
      { value: '60 - 68', label: t('60 - 68') },
      { value: '51 - 59', label: t('51 - 59') },
      { value: '42 - 50', label: t('42 - 50') },
      { value: '33 - 41', label: t('33 - 41') },
      { value: '0 - 32', label: t('0 - 32') },
    ];

    const pte_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '90', label: t('90') },
      { value: '88 - 89', label: t('88 - 89') },
      { value: '79 - 87', label: t('79 - 87') },
      { value: '69 - 78', label: t('69 - 78') },
      { value: '60 - 68', label: t('60 - 68') },
      { value: '51 - 59', label: t('51 - 59') },
      { value: '41 - 50', label: t('41 - 50') },
      { value: '0 - 40', label: t('0 - 40') },
    ];

    // Define options with tcf_ prefix
    const tcf_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '16 - 20', label: t('16 - 20') },
      { value: '14 - 15', label: t('14 - 15') },
      { value: '12 - 13', label: t('12 - 13') },
      { value: '10 - 11', label: t('10 - 11') },
      { value: '7 - 9', label: t('7 - 9') },
      { value: '6', label: t('6') },
      { value: '4 - 5', label: t('4 - 5') },
      { value: '0 - 3', label: t('0 - 3') },
    ];

    const tcf_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '549 - 699', label: t('549 - 699') },
      { value: '523 - 548', label: t('523 - 548') },
      { value: '503 - 522', label: t('503 - 522') },
      { value: '458 - 502', label: t('458 - 502') },
      { value: '398 - 457', label: t('398 - 457') },
      { value: '369 - 397', label: t('369 - 397') },
      { value: '331 - 368', label: t('331 - 368') },
      { value: '0 - 330', label: t('0 - 330') },
    ];

    const tcf_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '549 - 699', label: t('549 - 699') },
      { value: '524 - 548', label: t('524 - 548') },
      { value: '499 - 523', label: t('499 - 523') },
      { value: '453 - 498', label: t('453 - 498') },
      { value: '406 - 452', label: t('406 - 452') },
      { value: '375 - 405', label: t('375 - 405') },
      { value: '342 - 374', label: t('342 - 374') },
      { value: '0 - 341', label: t('0 - 341') },
    ];

    const tcf_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '16 - 20', label: t('16 - 20') },
      { value: '14 - 15', label: t('14 - 15') },
      { value: '12 - 13', label: t('12 - 13') },
      { value: '10 - 11', label: t('10 - 11') },
      { value: '7 - 9', label: t('7 - 9') },
      { value: '6', label: t('6') },
      { value: '4 - 5', label: t('4 - 5') },
      { value: '0 - 3', label: t('0 - 3') },
    ];

    // Define options with tef_ prefix
    const tef_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '393 - 450', label: t('393 - 450') },
      { value: '371 - 392', label: t('371 - 392') },
      { value: '349 - 370', label: t('349 - 370') },
      { value: '310 - 348', label: t('310 - 348') },
      { value: '271 - 309', label: t('271 - 309') },
      { value: '226 - 270', label: t('226 - 270') },
      { value: '181 - 225', label: t('181 - 225') },
      { value: '0 - 180', label: t('0 - 180') },
    ];

    const tef_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '316 - 360', label: t('316 - 360') },
      { value: '298 - 315', label: t('298 - 315') },
      { value: '280 - 297', label: t('280 - 297') },
      { value: '249 - 279', label: t('249 - 279') },
      { value: '217 - 248', label: t('217 - 248') },
      { value: '181 - 216', label: t('181 - 216') },
      { value: '145 - 180', label: t('145 - 180') },
      { value: '0 - 144', label: t('0 - 144') },
    ];

    const tef_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '263 - 300', label: t('263 - 300') },
      { value: '248 - 262', label: t('248 - 262') },
      { value: '233 - 247', label: t('233 - 247') },
      { value: '207 - 232', label: t('207 - 232') },
      { value: '181 - 206', label: t('181 - 206') },
      { value: '151 - 180', label: t('151 - 180') },
      { value: '121 - 150', label: t('121 - 150') },
      { value: '0 - 120', label: t('0 - 120') },
    ];

    const tef_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '393 - 450', label: t('393 - 450') },
      { value: '371 - 392', label: t('371 - 392') },
      { value: '349 - 370', label: t('349 - 370') },
      { value: '310 - 348', label: t('310 - 348') },
      { value: '271 - 309', label: t('271 - 309') },
      { value: '226 - 270', label: t('226 - 270') },
      { value: '181 - 225', label: t('181 - 225') },
      { value: '0 - 180', label: t('0 - 180') },
    ];


    if (formData.language_test === 'IELTS') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={ielts_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={ielts_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={ielts_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={ielts_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={ielts_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={ielts_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={ielts_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={ielts_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.language_test === 'CELPIP-G') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={celpip_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={celpip_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={celpip_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={celpip_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={celpip_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={celpip_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={celpip_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={celpip_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.language_test === 'PTE Core') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={pte_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={pte_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={pte_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={pte_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={pte_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={pte_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={pte_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={pte_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>

      );
    } else if (formData.language_test === 'TCF Canada') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={tcf_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={tcf_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={tcf_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={tcf_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={tcf_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={tcf_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={tcf_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={tcf_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>

      );
    } else if (formData.language_test === 'TEF Canada') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={tef_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={tef_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={tef_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={tef_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={tef_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={tef_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={tef_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={tef_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>

      );
    }

    return null;
  };

  return (
    <div className="p-8 bg-gray-100">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Form Section */}
        <section className="bg-white p-8 rounded-md shadow-md">
          <div className="bg-red-100 text-red-600 p-2 sm:p-4 rounded-md shadow-sm text-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold">{t('clb_conversion')}</h2>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4 mt-6">
            <div>
              <label htmlFor="language_test" className="block text-base sm:text-lg font-medium">
                {t('language_test')}
              </label>
              <Select
                id="language_test"
                name="language_test"
                value={languageTestOptions.find(option => option.value === formData.language_test)}
                onChange={(selectedOption) => handleChange({ target: { name: 'language_test', value: selectedOption.value } })}
                options={languageTestOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem', // rounded-md equivalent
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50, // Ensure it appears above other content
                  }),
                }}
                placeholder={t('select_language_test')}
              />
            </div>

            {/* Conditional fields for Language test */}
            {renderLanguageFields()}

            {/* Submit button */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-red-600 text-white px-6 py-3 sm:px-8 sm:py-4 md:px-10 md:py-5 rounded-md text-base sm:text-lg md:text-xl hover:bg-red-700"
              >
                {t('submit')}
              </button>
            </div>

          </form>
        </section>

        {/* Results Section */}
        <section className="bg-white p-8 rounded-md shadow-md">
          {/* Language Conversion Result Header */}
          <div className="bg-red-100 text-red-600 p-2 sm:p-4 rounded-md shadow-sm text-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold">{t('clb_result')}</h2>
          </div>


          {responseData.clb_levels && (
            <div className="mt-6 grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-4">
              {/* Speaking */}
              <div className="bg-white text-gray-800 p-6 rounded-md text-center shadow-md">
                <p className="text-xl font-medium">{t('speaking')}</p>
                <p className="text-4xl font-bold mt-2 text-red">{responseData.clb_levels.speaking}</p>
              </div>

              {/* Listening */}
              <div className="bg-white text-gray-800 p-6 rounded-md text-center shadow-md">
                <p className="text-xl font-medium">{t('listening')}</p>
                <p className="text-4xl font-bold mt-2 text-red">{responseData.clb_levels.listening}</p>
              </div>

              {/* Reading */}
              <div className="bg-white text-gray-800 p-6 rounded-md text-center shadow-md">
                <p className="text-xl font-medium">{t('reading')}</p>
                <p className="text-4xl font-bold mt-2 text-red">{responseData.clb_levels.reading}</p>
              </div>

              {/* Writing */}
              <div className="bg-white text-gray-800 p-6 rounded-md text-center shadow-md">
                <p className="text-xl font-medium">{t('writing')}</p>
                <p className="text-4xl font-bold mt-2 text-red">{responseData.clb_levels.writing}</p>
              </div>

              {/* Overall CLB */}
              <div className="col-span-2 bg-red-500 text-white p-6 rounded-md text-center shadow-md mt-4 flex flex-col items-center">
                <p className="text-xl font-medium">{t('overall_clb')}</p>
                <p className="text-5xl font-bold mt-2">{responseData.overall_clb}</p>
              </div>

              {/* Feedback Section */}
              <div className="col-span-2 bg-gray-100 p-6 rounded-md text-center shadow-md mt-4">
                {responseData.overall_clb >= 9 ? (
                  <div className="flex justify-center items-center">
                    <img src="/images/excellence.png" alt="excellence-icon" className="h-12 w-12 mr-3" />
                    <p className="text-xl text-green-600 font-semibold">
                      {t('congratulation_message')}
                    </p>
                  </div>
                ) : (
                  <div className="flex justify-center items-center">
                    <img src="/images/willpower.png" alt="willpower-icon" className="h-12 w-12 mr-3" />
                    <p className="text-xl text-yellow-600 font-semibold">
                      {t('encouragement_message')}
                    </p>
                  </div>
                )}
              </div>

            </div>
          )}
        </section>

      </div>
    </div>
  );
};

export default CLBConversion;

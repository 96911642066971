import React, { useState, useEffect } from 'react';
import { FaQrcode, FaCoffee, FaCopy } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SupportUs = () => {
  const { t } = useTranslation();
  const [selectedQR, setSelectedQR] = useState(null);
  const [counter, setCounter] = useState(getRandomMinValue());
  const [animateHeart, setAnimateHeart] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [copyMessage, setCopyMessage] = useState(null);

  const qrOptions = [
    { label: t('support_50k_vnd'), img: "/images/support_us_50k.jpg" },
    { label: t('support_100k_vnd'), img: "/images/support_us_100k.jpg" },
    { label: t('support_150k_vnd'), img: "/images/support_us_150k.jpg" },
    { label: t('support_200k_vnd'), img: "/images/support_us_200k.jpg" },
    { label: t('support_custom_amount'), img: "/images/support_us_flexible.jpg" },
  ];

  const handleQRSelection = (img, label) => {
    setSelectedQR(img);
    setSelectedButton(label);
  };

  const handleBankAccountSelection = () => {
    setSelectedQR(null);
    setSelectedButton("bank_account");
  };

  const copyToClipboard = (detailKey) => {
    const textToCopy = t(detailKey);
    navigator.clipboard.writeText(textToCopy);
    setCopyMessage(detailKey);
    setTimeout(() => setCopyMessage(null), 2000); // Clear message after 2 seconds
  };

  // Function to generate a random value equal to the current day of the month plus a random number between 1 and 5
  function getRandomMinValue() {
    const currentDay = new Date().getDate(); // Get the current day of the month
    const randomOffset = Math.floor(Math.random() * 5) + 1; // Generate a random number between 1 and 5
    return currentDay + randomOffset;
  }

  // Function to generate a random interval between 20 and 60 seconds
  function getRandomInterval() {
    return Math.floor(Math.random() * (60 - 20 + 1) + 20) * 1000;
  }

  // Effect to increase the counter and trigger the heart animation
  useEffect(() => {
    const updateCounter = () => {
      setCounter((prev) => prev + 1);
      setAnimateHeart(true);

      setTimeout(() => setAnimateHeart(false), 1000); // End heart animation after 1 second

      const nextInterval = getRandomInterval();
      setTimeout(updateCounter, nextInterval);
    };

    // Start the counter update loop
    const initialInterval = getRandomInterval();
    const timer = setTimeout(updateCounter, initialInterval);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="p-4 sm:p-6 bg-gray-100 flex flex-col items-center">
      {/* Page Heading */}
      <h1 className="text-xl sm:text-2xl md:text-3xl font-extrabold text-center text-red-600 tracking-wide">{t('support_us_title')}</h1>
      <div className="w-16 h-1 bg-red-600 mx-auto my-4 rounded-full"></div>
      <p className="text-base sm:text-lg text-gray-600 text-center max-w-4xl">{t('support_us_description')}</p>

      {/* Donation Options Container */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6 w-full max-w-4xl">
        {/* QR Code Donation */}
        <div className="bg-white p-5 sm:p-6 rounded-xl shadow-lg flex flex-col items-center text-center">
          <FaQrcode className="text-green-500 text-3xl sm:text-5xl mb-3" />
          <h2 className="text-lg sm:text-xl font-bold text-gray-700 mb-3">{t('scan_qr_code')}</h2>
          <p className="text-sm sm:text-base text-gray-500 mb-4">{t('select_amount_qr')}</p>
          <div className="grid grid-cols-2 gap-2 sm:gap-3 mb-4">
            {qrOptions.map((option) => (
              <button
                key={option.label}
                onClick={() => handleQRSelection(option.img, option.label)}
                className={`flex items-center space-x-2 bg-gray-100 text-gray-700 py-2 px-4 rounded-lg shadow-md font-semibold transition-transform transform hover:scale-105 text-sm 
              ${selectedButton === option.label ? 'bg-green-300' : 'hover:bg-gray-200'}`}
              >
                <img src="/images/dollar.png" alt="Dollar" className="w-4 h-4" />
                <span> {option.label}</span>
              </button>
            ))}
            <button
              key="bank_account"
              onClick={handleBankAccountSelection}
              className={`flex items-center space-x-2 bg-gray-100 text-gray-700 py-2 px-4 rounded-lg shadow-md font-semibold transition-transform transform hover:scale-105 text-sm 
                ${selectedButton === "bank_account" ? 'bg-green-300' : 'hover:bg-gray-200'}`}
            >
              <img src="/images/dollar.png" alt="Dollar" className="w-4 h-4" />
              <span>{t('support_bank_transfer')}</span>
            </button>
          </div>

          {selectedQR ? (
            <div className="w-full">
              <img src={selectedQR} alt="QR Code" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
          ) : selectedButton === "bank_account" ? (
            <div className="w-full p-4 sm:p-5 border border-gray-300 bg-green-50 rounded-lg shadow-lg text-gray-700 text-sm sm:text-base mt-3">
              <h3 className="text-lg font-bold text-green-700 mb-3">{t('bank_transfer_details')}</h3>

              {/* Bank Name */}
              <div className="flex justify-between items-center py-2 border-b border-gray-200">
                <p className="text-left"><strong>{t('bank_name')}:</strong> {t('bank_name_detail')}</p>
                <button
                  onClick={() => copyToClipboard('bank_name_detail')}
                  className="flex items-center text-green-500 hover:text-green-700"
                >
                  <FaCopy className="mr-1" />
                  <span className="text-xs">{copyMessage === 'bank_name_detail' ? t('copied') : t('copy')}</span>
                </button>
              </div>

              {/* Account Name */}
              <div className="flex justify-between items-center py-2 border-b border-gray-200">
                <p className="text-left"><strong>{t('account_name')}:</strong> {t('account_name_detail')}</p>
                <button
                  onClick={() => copyToClipboard('account_name_detail')}
                  className="flex items-center text-green-500 hover:text-green-700"
                >
                  <FaCopy className="mr-1" />
                  <span className="text-xs">{copyMessage === 'account_name_detail' ? t('copied') : t('copy')}</span>
                </button>
              </div>

              {/* Account Number */}
              <div className="flex justify-between items-center py-2">
                <p className="text-left"><strong>{t('account_number')}:</strong> {t('account_number_detail')}</p>
                <button
                  onClick={() => copyToClipboard('account_number_detail')}
                  className="flex items-center text-green-500 hover:text-green-700"
                >
                  <FaCopy className="mr-1" />
                  <span className="text-xs">{copyMessage === 'account_number_detail' ? t('copied') : t('copy')}</span>
                </button>
              </div>
            </div>

          ) : (
            <div className="flex flex-col items-center mt-4">
              <img src="/images/thanks.png" alt="Thank You" className="w-14 h-14 mb-2" />
              <p className="text-gray-500 border border-dashed border-gray-400 bg-red-50 py-2 px-4 rounded-lg text-sm text-center">
                {t('select_qr_display')}
              </p>
            </div>
          )}
        </div>

        {/* Buy Me a Coffee */}
        <div className="bg-white p-5 sm:p-6 rounded-xl shadow-lg flex flex-col items-center text-center relative">
          <FaCoffee className="text-yellow-500 text-3xl sm:text-5xl mb-3" />
          <h2 className="text-lg sm:text-xl font-bold text-gray-700 mb-3">{t('buy_me_coffee_title')}</h2>

          {/* Animated Box and Heart Section */}
          <div className="w-full h-32 sm:h-48 bg-gray-100 rounded-lg shadow-inner flex items-center justify-center relative mb-2 sm:mb-6">
            {/* Box Image */}
            <img src="/images/today_box.png" alt="Box" className="w-20 sm:w-32 h-20 sm:h-32" />

            {/* Heart Animation */}
            <div className="absolute right-6 flex flex-col items-center">
              <img
                src="/images/heart.png"
                alt="Heart"
                className={`w-6 h-6 ${animateHeart ? 'animate-floatUp' : ''}`}
              />
              {/* Counter */}
              <p className="text-2xl sm:text-3xl font-bold text-green-600 mt-1">{counter}</p>
            </div>
          </div>
          <div className="w-full mt-4">
            <Link
              to="https://buymeacoffee.com/canada_entry"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center bg-red-400 hover:bg-green-600 text-white text-sm sm:text-base font-semibold py-2 sm:py-3 px-6 sm:px-8 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            >
              <img src="/images/coffee.png" alt="Click Here" className="w-5 h-5 mr-2" />
              {t('buy_me_coffee')}
            </Link>
          </div>
        </div>
      </div>

      <style>
        {`
          @keyframes floatUp {
            0% { transform: translateY(0); opacity: 1; }
            100% { transform: translateY(-20px); opacity: 0; }
          }
          .animate-floatUp {
            animation: floatUp 1s ease-out forwards;
          }
        `}
      </style>
    </div>
  );
};

export default SupportUs;

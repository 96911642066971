import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const TRVApplication = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% { opacity: 1; transform: scale(1); }
            50% { opacity: 0.7; transform: scale(1.05); }
          }
          .animate-twinkle { animation: twinkle 1s infinite ease-in-out; }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('trvapp_program_title')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Program Overview */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('trvapp_overview_title')}
              </h3>
              <p className="text-gray-700">{t('trvapp_overview_description')}</p>
              <ul className="list-disc list-inside ml-8">
                <li><strong>{t('trvapp_important_note_title')}</strong>: {t('trvapp_important_note_description')}</li>
              </ul>
            </div>
          </div>

          {/* Step 2: Eligibility Criteria */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('trvapp_eligibility_title')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  {t('trvapp_eligibility_leave_canada_description')}
                </li>
                <li>
                  {t('trvapp_eligibility_maintain_support_description')}
                </li>
                <li>
                  {t('trvapp_eligibility_no_work_study_description')}
                </li>
                <li>
                  {t('trvapp_eligibility_law_abiding_description')}
                </li>
                <li>
                  {t('trvapp_eligibility_additional_documents_description')}
                </li>
                <li>
                  {t('trvapp_eligibility_good_health_description')}
                </li>
              </ul>
            </div>
          </div>


          {/* Step 3: Detailed Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('trvapp_application_process_title')}
              </h3>
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('trvapp_application_process_prepare_account')}
                </h4>
                <p className="text-gray-700">{t('trvapp_application_process_prepare_account_description')}</p>
              </div>
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('trvapp_application_process_fill_forms')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('trvapp_application_process_required_forms')}
                    <ul className="list-disc list-inside ml-8">
                      <li>
                        <strong>{t('trvapp_application_process_imm5257')}</strong>: {t('trvapp_application_process_imm5257_description')}
                      </li>
                      <li>
                        <strong>{t('trvapp_application_process_imm5645')}</strong>: {t('trvapp_application_process_imm5645_description')}
                      </li>
                    </ul>
                  </li>
                  <li>{t('trvapp_application_process_upload_documents')}
                    <ul className="list-disc list-inside ml-8">
                      <li><strong>{t('trvapp_application_process_valid_passport')}</strong>: {t('trvapp_application_process_valid_passport_description')}</li>
                      <li><strong>{t('trvapp_application_process_status_in_canada')}</strong>: {t('trvapp_application_process_status_in_canada_description')}</li>
                      <li>{t('trvapp_other_recommended_docs_title')}:</li>
                      <ul className="list-disc list-inside ml-4 text-gray-700">
                      <li>
                        <strong>{t('trvapp_travel_history_title')}</strong>: {t('trvapp_travel_history_description')}
                      </li>
                      <li>
                        <strong>{t('trvapp_travel_itinerary_title')}</strong>: {t('trvapp_travel_itinerary_description')}
                      </li>
                      <li>
                        <strong>{t('trvapp_bank_statements_title')}</strong>: {t('trvapp_bank_statements_description')}
                      </li>
                      </ul>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('trvapp_application_process_pay_fees')}
                </h4>
                <p className="text-gray-700">{t('trvapp_application_process_fee_description')}</p>
              </div>
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('trvapp_application_process_monitor_application')}
                </h4>
                <p className="text-gray-700">{t('trvapp_application_process_monitor_description')}</p>
              </div>
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('trvapp_application_process_await_decision')}
                </h4>
                <p className="text-gray-700">{t('trvapp_application_process_decision_description')}</p>
              </div>
            </div>
          </div>

          {/* Essential Rules */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('trvapp_rules_title')}
              </h3>
              <p className="text-gray-700">{t('trvapp_rules_maintain_legal_status_title')}</p>
            </div>
          </div>

          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
      <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
      <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
        <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
          {t('trvapp_pros_cons_title')}
        </h3>

        {/* Pros Section */}
        <h4 className="text-md sm:text-lg font-semibold text-black-600">
          {t('trvapp_pros_title')}
        </h4>
        <ul className="list-disc list-inside ml-4 text-gray-700">
          <li>
            <strong>{t('trvapp_pros_multiple_entries_title')}</strong> {t('trvapp_pros_multiple_entries_description')}
          </li>
          <li>
            <strong>{t('trvapp_pros_long_validity_title')}</strong> {t('trvapp_pros_long_validity_description')}
          </li>
          <li>
            <strong>{t('trvapp_pros_explore_opportunity_title')}</strong> {t('trvapp_pros_explore_opportunity_description')}
          </li>
          <li>
            <strong>{t('trvapp_pros_extend_option_title')}</strong> {t('trvapp_pros_extend_option_description')}
          </li>
        </ul>

        {/* Cons Section */}
        <h4 className="text-md sm:text-lg font-semibold text-black-600 mt-6">
          {t('trvapp_cons_title')}
        </h4>
        <ul className="list-disc list-inside ml-4 text-gray-700">
          <li>
            <strong>{t('trvapp_cons_temporary_status_title')}</strong> {t('trvapp_cons_temporary_status_description')}
            <ul className="list-disc list-inside ml-6 mt-1">
              <li>{t('trvapp_cons_temporary_status_note')}</li>
            </ul>
          </li>
          <li>
            <strong>{t('trvapp_cons_possible_rejection_title')}</strong> {t('trvapp_cons_possible_rejection_description')}
          </li>
          <li>
            <strong>{t('trvapp_cons_no_border_guarantee_title')}</strong> {t('trvapp_cons_no_border_guarantee_description')}
          </li>
        </ul>
      </div>
    </div>

          {/* Next actions */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('next_actions')}</h3>
              <p className="text-gray-700 mb-4">{t('trvapp_next_actions_description')}</p>
              <Link
                to="https://ircc.canada.ca/english/information/applications/visa.asp"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
              >
                {t('trvapp_learn_more')} →
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default TRVApplication;

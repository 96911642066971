import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const QuebecExperienceProgram = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('peq_heading')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Overview */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('peq_overview_title')}
              </h3>
              <p className="text-gray-700">{t('peq_overview_description')}</p>
            </div>
          </div>

          {/* Eligibility Criteria */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('peq_eligibility_title')}
              </h3>

              {/* Quebec Graduates */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('peq_quebec_graduates_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <strong><p className="text-blue-500 mt-2 mb-2">⛔️ {t('peq_suspension_notice')}</p></strong>
                  <li><strong>{t('peq_program_of_study_title')}</strong></li>
                  <ul className="list-disc list-inside ml-8 text-gray-700">
                    <li>{t('peq_program_of_degree')}</li>
                    <li>{t('peq_program_of_study')}</li>
                    <ul className="list-disc list-inside ml-4 text-gray-700">
                      <li>{t('peq_program_of_study_requirement_1')}</li>
                      <li>{t('peq_program_of_study_requirement_2')}</li>
                    </ul>
                    <li><strong>{t('peq_duration_title')}</strong> {t('peq_duration_description')}</li>
                  </ul>

                  <li><strong>{t('peq_french_proficiency_title')}</strong>
                    <a
                      href="https://cdn-contenu.quebec.ca/cdn-contenu/adm/min/langue-francaise/fr/publications/referentiel/echelle_niveaux_competence_2023.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                    >
                      {t('peq_french_learn_more')}
                      <svg
                        className="w-4 h-4 ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    </a>
                  </li>
                  <ul className="list-disc list-inside ml-4 text-gray-700">
                    <li>{t('peq_french_proficiency_speaking')}</li>
                    <li>{t('peq_french_proficiency_writing')}</li>
                  </ul>
                  <li><strong>{t('peq_legal_status_title')}</strong> {t('peq_legal_status_description')}</li>
                  <li><strong>{t('peq_financial_sufficiency_title')}</strong> {t('peq_financial_sufficiency_description')}</li>
                </ul>
              </div>

              {/* Temporary Foreign Workers */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('peq_foreign_workers_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li><strong>{t('peq_work_experience_title')}</strong> {t('peq_work_experience_description')}</li>
                  <li><strong>{t('peq_french_language_proficiency_title')}</strong> {t('peq_french_language_proficiency_description')}</li>
                  <li><strong>{t('peq_legal_status_foreign_worker_title')}</strong> {t('peq_legal_status_foreign_worker_description')}</li>
                  <li><strong>{t('peq_financial_requirement_foreign_worker_title')}</strong> {t('peq_financial_requirement_foreign_worker_description')}</li>
                </ul>
              </div>

              {/* Additional Requirements */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-black-600 mb-2">
                  {t('peq_additional_requirements_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('peq_additional_requirement_settle')}</li>
                  <li>{t('peq_additional_requirement_background')}</li>
                </ul>
              </div>
            </div>
          </div>

          {/* How to Apply */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('peq_how_to_apply_title')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('peq_gather_documentation_title')}</strong>: {t('peq_gather_documentation_description')}</li>
                <li><strong>{t('peq_complete_submit_application_title')}</strong>: {t('peq_complete_submit_application_description')}
                  <a
                    href="https://arrima.immigration-quebec.gouv.qc.ca/aiguillage/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                  >
                    {t('peq_arrima_portal')}
                    <svg
                      className="w-4 h-4 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </a>
                </li>
                <li><strong>{t('peq_receive_selection_certificate_title')}</strong>: {t('peq_receive_selection_certificate_description')}</li>
                <li><strong>{t('peq_apply_for_permanent_residence_title')}</strong>: {t('peq_apply_for_permanent_residence_description')}</li>
                <li><strong>{t('peq_approval_confirmation_title')}</strong>: {t('peq_approval_confirmation_description')}</li>
              </ul>
            </div>
          </div>

          {/* Pros and Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('peq_pros_cons_title')}
              </h3>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">{t('peq_pros_title')}</h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('peq_pros_1_title')}</strong> {t('peq_pros_1_description')}</li>
                <li><strong>{t('peq_pros_2_title')}</strong> {t('peq_pros_2_description')}</li>
                <li><strong>{t('peq_pros_3_title')}</strong> {t('peq_pros_3_description')}</li>
              </ul>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">{t('peq_cons_title')}</h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('peq_cons_1_title')}</strong> {t('peq_cons_1_description')}</li>
                <li><strong>{t('peq_cons_2_title')}</strong> {t('peq_cons_2_description')}</li>
                <li><strong>{t('peq_cons_3_title')}</strong> {t('peq_cons_3_description')}</li>
              </ul>
            </div>
          </div>
          {/* Step 4: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('next_actions')}</h3>
              <p className="text-gray-700 mb-4">{t('next_actions_quebec_description')}</p>
              <Link
                to="https://www.quebec.ca/en/immigration/permanent"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
              >
                {t('learn_more_about_quebec')} →
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default QuebecExperienceProgram;

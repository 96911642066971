import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegEye, FaChartBar, FaExclamationTriangle } from 'react-icons/fa';

const LMIAMap = () => {
  const { t } = useTranslation();
  const [selectedIframe, setSelectedIframe] = useState(1);

  const buttonStyles = (isSelected) => `
    flex items-center justify-center w-full sm:w-48 md:w-60 py-2 sm:py-3 px-4 rounded-lg font-semibold text-sm sm:text-lg transition duration-300
    ${isSelected ? 'bg-gradient-to-r from-red-500 via-red-600 to-red-700 text-white' : 'bg-gradient-to-r from-gray-50 via-gray-200 to-gray-300 text-gray-800 hover:from-gray-300 hover:via-gray-400 hover:to-gray-500'}
    opacity-90 hover:opacity-100
  `;

  return (
    <div className="p-4 sm:p-6 bg-gray-100">
      {/* Heading */}
      <h1 className="text-xl sm:text-2xl md:text-3xl font-extrabold text-center text-red-600 tracking-wide">{t('lmia_heading')}</h1>
      <div className="w-16 sm:w-20 h-1 bg-red-600 mx-auto my-4 sm:my-6 rounded-full"></div>

      {/* Button Group */}
      <div className="flex flex-col sm:flex-row justify-center mb-4 sm:mb-6 space-y-3 sm:space-y-0 sm:space-x-4">
        <button
          onClick={() => setSelectedIframe(1)}
          className={buttonStyles(selectedIframe === 1)}
        >
          <FaRegEye className="mr-2 text-xl sm:text-2xl" /> {/* Adjusted Icon Size */}
          {t('lmia_overview_report')}
        </button>
        <button
          onClick={() => setSelectedIframe(2)}
          className={buttonStyles(selectedIframe === 2)}
        >
          <FaChartBar className="mr-2 text-xl sm:text-2xl" /> {/* Adjusted Icon Size */}
          {t('lmia_detail_report')}
        </button>
        <button
          onClick={() => setSelectedIframe(3)}
          className={buttonStyles(selectedIframe === 3)}
        >
          <FaExclamationTriangle className="mr-2 text-xl sm:text-2xl" /> {/* Adjusted Icon Size */}
          {t('lmia_non_compliant')}
        </button>
      </div>

      {/* Iframes */}
      <div className="flex justify-center">
        <div className="w-full h-[400px] sm:h-[600px] md:h-[810px]">
          {selectedIframe === 1 && (
            <iframe
              title="LMIA Report - Overview"
              width="100%"
              height="100%"
              src="https://app.powerbi.com/view?r=eyJrIjoiOTg0NTQ4YmUtMzAzNy00Y2I4LWExMTAtMjExNGNhOGYyYzMyIiwidCI6ImFmMWYzNzUzLTM5MjUtNGU2Zi05NDliLTk3YzAwNzMyMDgwMyIsImMiOjEwfQ%3D%3D&pageName=c70d34f82bbb1a445609"
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
          )}
          {selectedIframe === 2 && (
            <iframe
              title="LMIA Report - Detail"
              width="100%"
              height="100%"
              src="https://app.powerbi.com/view?r=eyJrIjoiZTRkOTNhNjEtNWVhNi00Yzc1LWI1M2QtNTIzYzgyZmNlZjI0IiwidCI6ImFmMWYzNzUzLTM5MjUtNGU2Zi05NDliLTk3YzAwNzMyMDgwMyIsImMiOjEwfQ%3D%3D&pageName=c70d34f82bbb1a445609"
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
          )}
          {selectedIframe === 3 && (
            <iframe
              title="LMIA Report - Non-Compliant"
              width="100%"
              height="100%"
              src="https://app.powerbi.com/view?r=eyJrIjoiYjliZWE0MzQtMGQzNy00MDQyLTk5MWItZWQ4MDFhYWM0NTdkIiwidCI6ImFmMWYzNzUzLTM5MjUtNGU2Zi05NDliLTk3YzAwNzMyMDgwMyIsImMiOjEwfQ%3D%3D&pageName=c70d34f82bbb1a445609"
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default LMIAMap;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import enNavigation from './locales/en/navigation.json';
import enFooter from './locales/en/footer.json';
import enHomePage from './locales/en/homepage.json';
import enExpressEntry from './locales/en/expressentry.json';
import enPNP from './locales/en/pnp.json';
import enTemporaryResident from './locales/en/temporaryresident.json';
import enCRSCalculator from './locales/en/crscalculator.json';
import enCanimmiSupport from './locales/en/canadaimmigrationsupport.json';
import enPGWPEligibility from './locales/en/pgwpeligibility.json';
import enPGWPPolicy from './locales/en/pgwppolicy.json';
import enPGWPAssessment from './locales/en/pgwpassessment.json';
import enPGWPApplication from './locales/en/pgwpapplication.json';
import enTRVApplication from './locales/en/trvapplication.json';
import enLMIAMap from './locales/en/lmiamap.json';

import enCLBConversion from './locales/en/clbconversion.json';
import enTermsAndConditions from './locales/en/termsandconditions.json';
import enPrivacyPolicy from './locales/en/privacypolicy.json';
import enContactUs from './locales/en/contactus.json';
import enSupportUs from './locales/en/supportus.json';
import enUnderDevelopment from './locales/en/underdevelopment.json';
import enFederalSkilledWorker from './locales/en/federalskilledworker.json';
import enFederalSkilledTrades from './locales/en/federalskilledtrades.json';
import enCanadianExperienceClass from './locales/en/canadianexperienceclass.json';
import enQuebecPrograms from './locales/en/quebecprograms.json';
import enSponsorship from './locales/en/sponsorship.json';
import enWork from './locales/en/work.json';
import enStudy from './locales/en/study.json';
import enVisitor from './locales/en/visitor.json';
import enSpousePartnerChildren from './locales/en/spousepartnerchildren.json';
import enAdoptedChild from './locales/en/adoptedchild.json';
import enOtherRelatives from './locales/en/otherrelatives.json';
import enParentsGrandparents from './locales/en/parentsgrandparents.json';
import enStartUpVisa from './locales/en/startupvisa.json';
import enQuebecSkilledWorker from './locales/en/quebecskilledworker.json';
import enQuebecBusinessImmigration from './locales/en/quebecbusinessimmigration.json';
import enQuebecFamilySponsorship from './locales/en/quebecfamilysponsorship.json';
import enQuebecExperienceProgram from './locales/en/quebecexperienceprogram.json';

import enOntario from './locales/en/ontario.json';
import enBritishColumbia from './locales/en/britishcolumbia.json';
import enAlberta from './locales/en/alberta.json';
import enSaskatchewan from './locales/en/saskatchewan.json';
import enManitoba from './locales/en/manitoba.json';
import enNovaScotia from './locales/en/novascotia.json';
import enNewBrunswick from './locales/en/newbrunswick.json';
import enPrinceEdwardIsland from './locales/en/princeedwardisland.json';
import enNewfoundland from './locales/en/newfoundland.json';
import enYukon from './locales/en/yukon.json';
import enNorthwestTerritories from './locales/en/northwestterritories.json';


import viNavigation from './locales/vi/navigation.json';
import viFooter from './locales/vi/footer.json';
import viHomePage from './locales/vi/homepage.json';
import viExpressEntry from './locales/vi/expressentry.json';
import viPNP from './locales/vi/pnp.json';
import viTemporaryResident from './locales/vi/temporaryresident.json';
import viCRSCalculator from './locales/vi/crscalculator.json';
import viCanimmiSupport from './locales/vi/canadaimmigrationsupport.json';
import viPGWPEligibility from './locales/vi/pgwpeligibility.json';
import viPGWPPolicy from './locales/vi/pgwppolicy.json';
import viPGWPAssessment from './locales/vi/pgwpassessment.json';
import viPGWPApplication from './locales/vi/pgwpapplication.json';
import viTRVApplication from './locales/vi/trvapplication.json';
import viLMIAMap from './locales/vi/lmiamap.json';

import viCLBConversion from './locales/vi/clbconversion.json';
import viTermsAndConditions from './locales/vi/termsandconditions.json';
import viPrivacyPolicy from './locales/vi/privacypolicy.json';
import viContactUs from './locales/vi/contactus.json';
import viSupportUs from './locales/vi/supportus.json';
import viUnderDevelopment from './locales/vi/underdevelopment.json';
import viFederalSkilledWorker from './locales/vi/federalskilledworker.json';
import viFederalSkilledTrades from './locales/vi/federalskilledtrades.json';
import viCanadianExperienceClass from './locales/vi/canadianexperienceclass.json';
import viQuebecPrograms from './locales/vi/quebecprograms.json';
import viSponsorship from './locales/vi/sponsorship.json';
import viWork from './locales/vi/work.json';
import viStudy from './locales/vi/study.json';
import viVisitor from './locales/vi/visitor.json';
import viSpousePartnerChildren from './locales/vi/spousepartnerchildren.json';
import viAdoptedChild from './locales/vi/adoptedchild.json';
import viOtherRelatives from './locales/vi/otherrelatives.json';
import viParentsGrandparents from './locales/vi/parentsgrandparents.json';
import viStartUpVisa from './locales/vi/startupvisa.json';
import viQuebecSkilledWorker from './locales/vi/quebecskilledworker.json';
import viQuebecBusinessImmigration from './locales/vi/quebecbusinessimmigration.json';
import viQuebecFamilySponsorship from './locales/vi/quebecfamilysponsorship.json';
import viQuebecExperienceProgram from './locales/vi/quebecexperienceprogram.json';


import viOntario from './locales/vi/ontario.json';
import viBritishColumbia from './locales/vi/britishcolumbia.json';
import viAlberta from './locales/vi/alberta.json';
import viSaskatchewan from './locales/vi/saskatchewan.json';
import viManitoba from './locales/vi/manitoba.json';
import viNovaScotia from './locales/vi/novascotia.json';
import viNewBrunswick from './locales/vi/newbrunswick.json';
import viPrinceEdwardIsland from './locales/vi/princeedwardisland.json';
import viNewfoundland from './locales/vi/newfoundland.json';
import viYukon from './locales/vi/yukon.json';
import viNorthwestTerritories from './locales/vi/northwestterritories.json';

import tipsData from './data/recommendation_tips.json';
import reasonsData from './data/pgwp_reason.json';

// Prepare recommendation tips translations
const enTips = {};
const viTips = {};

// Map through the tips and use the 'summary' as keys for both languages
Object.keys(tipsData).forEach(key => {
  const tip = tipsData[key];
  enTips[tip.summary] = tip.en;
  viTips[tip.summary] = tip.vi;
});

// Prepare PGWP reasons translations
const enReasons = {};
const viReasons = {};

// Map through the tips and use the 'summary' as keys for both languages
Object.keys(reasonsData).forEach(key => {
  const reason = reasonsData[key];
  enReasons[reason.summary] = reason.en;
  viReasons[reason.summary] = reason.vi;
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'querystring'],
      lookupFromPathIndex: 0, // Detect language from the first part of the path
    },
    fallbackLng: 'en', // Set default fallback language
    // Other i18n options here
  });

const resources = {
  en: {
    translation: {
      // Navigation
      ...enNavigation,
      // Footer translations
      ...enFooter,
      // Home Page Translations
      ...enHomePage,
      // Express Entry Page Translations
      ...enExpressEntry,

      // Federal Skilled Worker
      ...enFederalSkilledWorker,

      // Federal Skilled Trades
      ...enFederalSkilledTrades,

      // Canadian Experience Class 
      ...enCanadianExperienceClass,

      /// Quebec Programs
      ...enQuebecPrograms,

      // Quebec Skilled Worker
      ...enQuebecSkilledWorker,

      // Quebec Business Immigration Worker
      ...enQuebecBusinessImmigration,

      // Quebec Family Sponsorship
      ...enQuebecFamilySponsorship,

      // Quebec Experience Program
      ...enQuebecExperienceProgram,

      // Sponsorship
      ...enSponsorship,

      // Spouse Partner Children
      ...enSpousePartnerChildren,

      // Adopted Child
      ...enAdoptedChild,

      // Other Relatives
      ...enOtherRelatives,

      // Parents and Grandparents
      ...enParentsGrandparents,

      // StartUp Visa
      ...enStartUpVisa,

      // Work
      ...enWork,

      // Study
      ...enStudy,

      // Visitor
      ...enVisitor,

      // PNP, Quebec Programs, Sponsorship Page Translations
      ...enPNP,

      // Ontario
      ...enOntario,

      // British Columbia
      ...enBritishColumbia,

      // Alberta
      ...enAlberta,

      // Saskatchewan
      ...enSaskatchewan,

      // Manitoba
      ...enManitoba,

      // Nova Scotia
      ...enNovaScotia,

      // New Brunswick
      ...enNewBrunswick,

      // Prince Edward Island
      ...enPrinceEdwardIsland,

      // Newfoundland
      ...enNewfoundland,

      // Yukon
      ...enYukon,

      // Northwest Territories
      ...enNorthwestTerritories,

      // Temporary Resident Section Descriptions
      ...enTemporaryResident,

      // CRS Calculator
      ...enCRSCalculator,

      // Recommendation Tips
      ...enTips,

      // Canada Immigration Support
      ...enCanimmiSupport,

      // PGWP Eligibility
      ...enPGWPEligibility,

      // PGWP Ineligible Reasons
      ...enReasons,

      // PGWP Policy
      ...enPGWPPolicy,

      // PGWP Assessment
      ...enPGWPAssessment,

      // PGWP Application
      ...enPGWPApplication,

      // TRV Application
      ...enTRVApplication,

      // CLB Conversion
      ...enCLBConversion,

      // Terms And Conditions
      ...enTermsAndConditions,

      // Privacy Policy
      ...enPrivacyPolicy,

      // Contact us
      ...enContactUs,

      // Support us
      ...enSupportUs,

      // LMIA Map
      ...enLMIAMap,


      // Under Development
      ...enUnderDevelopment,

    }
  },
  vi: {
    translation: {
      // Navigation
      ...viNavigation,
      // Footer translations
      ...viFooter,
      // Home Page Translations
      ...viHomePage,
      // Express Entry Page Translations
      ...viExpressEntry,

      /// Quebec Programs
      ...viQuebecPrograms,

      // Quebec Skilled Worker
      ...viQuebecSkilledWorker,

      // Quebec Business Immigration Worker
      ...viQuebecBusinessImmigration,

      // Quebec Family Sponsorship
      ...viQuebecFamilySponsorship,

      // Quebec Experience Program
      ...viQuebecExperienceProgram,

      // Federal Skilled Worker
      ...viFederalSkilledWorker,

      // Federal Skilled Trades
      ...viFederalSkilledTrades,

      // Canadian Experience Class 
      ...viCanadianExperienceClass,

      // Sponsorship
      ...viSponsorship,

      // Spouse Partner Children
      ...viSpousePartnerChildren,

      // Adopted Child
      ...viAdoptedChild,

      // Other Relatives
      ...viOtherRelatives,

      // Parents and Grandparents
      ...viParentsGrandparents,

      // StartUp Visa
      ...viStartUpVisa,

      // Work
      ...viWork,

      // Study
      ...viStudy,

      // Visitor
      ...viVisitor,


      // PNP, Quebec Programs, Sponsorship Page Translations
      ...viPNP,

      // Ontario
      ...viOntario,

      // British Columbia
      ...viBritishColumbia,

      // Alberta
      ...viAlberta,

      // Saskatchewan
      ...viSaskatchewan,

      // Manitoba
      ...viManitoba,

      // Nova Scotia
      ...viNovaScotia,

      // New Brunswick
      ...viNewBrunswick,

      // Prince Edward Island
      ...viPrinceEdwardIsland,

      // Newfoundland
      ...viNewfoundland,

      // Yukon
      ...viYukon,

      // Northwest Territories
      ...viNorthwestTerritories,


      // Temporary Resident Section Descriptions
      ...viTemporaryResident,

      // CRS Calculator
      ...viCRSCalculator,

      // Recommendation Tips
      ...viTips,

      // Canada Immigration Support
      ...viCanimmiSupport,

      // PGWP Eligibility
      ...viPGWPEligibility,

      // PGWP Ineligible Reasons
      ...viReasons,

      // PGWP Policy
      ...viPGWPPolicy,

      // PGWP Assessment
      ...viPGWPAssessment,

      // PGWP Application
      ...viPGWPApplication,

      // TRV Application
      ...viTRVApplication,

      // CLB Conversion
      ...viCLBConversion,

      // Terms And Conditions
      ...viTermsAndConditions,

      // Privacy Policy
      ...viPrivacyPolicy,

      // Contact us
      ...viContactUs,

      // Support us
      ...viSupportUs,

      // LMIA Map
      ...viLMIAMap,

      // Under Development
      ...viUnderDevelopment,
      

    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'vi', // Set Vietnamese as the default language
  fallbackLng: 'en', // Fallback to English if translation is missing
  interpolation: {
    escapeValue: false // React already handles escaping by default
  }
});

export default i18n;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 p-4 md:p-8">
      {/* Hero Section - Introduction */}
      <section className="bg-white p-6 md:p-8 rounded-md shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 items-center">
          <img
            src="/images/permanent-resident-banner.png"
            alt="Canada and Vietnam flags"
            className="w-full h-auto max-w-xs md:max-w-sm mx-auto"
          />
          <div>
            <h1 className="text-2xl md:text-3xl font-bold text-red-600">{t('welcome_message')}</h1>
            <p className="text-base md:text-lg mt-4">{t('introduction')}</p>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="bg-white p-6 md:p-8 rounded-md shadow-md mt-6 md:mt-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 items-center">
          <div>
            <h2 className="text-2xl md:text-3xl font-bold text-red-600">{t('about_us')}</h2>
            <p className="mt-4 text-base md:text-lg">{t('about_us_text')}</p>
          </div>
          <img
            src="/images/vietnamese-brothers.png"
            alt="Two brothers"
            className="w-full h-auto max-w-[120px] md:max-w-[180px] mx-auto"
          />
        </div>
      </section>

      {/* Guidance Section */}
      <section className="bg-white p-6 md:p-8 rounded-md shadow-md mt-6 md:mt-8">
        <h2 className="text-2xl md:text-3xl font-bold text-red-600">{t('guidance')}</h2>
        <p className="mt-4 text-base md:text-lg">{t('guidance_intro')}</p>

        {/* Cards for different guidance topics */}
        <div className="mt-4 flex flex-col md:flex-row flex-wrap gap-4 md:gap-6">
          {/* Permanent Resident Section */}
          <div className="flex-1 p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('permanent_resident')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('guidance_permanent_resident')}</p>
            </div>
            <div className="mt-6 md:mt-8">
              <Link
                to="/permanent-resident"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>

          {/* Temporary Resident Section */}
          <div className="flex-1 p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('temporary_resident')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('guidance_temporary_resident')}</p>
            </div>
            <div className="mt-6 md:mt-8">
              <Link
                to="/temporary-resident"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>

          {/* Canada Immigration Support Section */}
          <div className="flex-1 p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('canada_immigration_support')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('guidance_canada_immigration_support')}</p>
            </div>
            <div className="mt-6 md:mt-8">
              <Link
                to="/canada-immigration-support"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>

          {/* LMIA Tracker */}
          <div className="flex-1 p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('lmia_map')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('guidance_lmia_tracker')}</p>
            </div>
            <div className="mt-6 md:mt-8">
              <Link
                to="/lmia-tracker"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;

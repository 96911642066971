import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NorthwestTerritories = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('nt_program_title')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Programs */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('nt_overview_title')}
              </h3>
              <p className="text-gray-700">{t('nt_overview_intro')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('nt_employer_driven_stream_title')}</strong> {t('nt_employer_driven_stream_description')}
                  <ul className="list-disc list-inside ml-6">
                    <li>
                      <strong>{t('nt_skilled_worker_stream_title')}</strong> {t('nt_skilled_worker_stream_description')}
                    </li>
                    <li>
                      <strong>{t('nt_entry_level_stream_title')}</strong> {t('nt_entry_level_stream_description')}
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t('nt_business_stream_title')}</strong> {t('nt_business_stream_description')}
                </li>
                <li>
                  <strong>{t('nt_francophone_stream_title')}</strong> {t('nt_francophone_stream_description')}
                </li>
              </ul>
            </div>
          </div>


          {/* Step 2: Eligibility Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('nt_requirements_title')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('nt_requirements_general_title')}</strong>:
                  <ul className="list-disc list-inside ml-4">
                    <li>{t('nt_requirements_general_legal_status')}</li>
                    <li>{t('nt_requirements_general_intent_to_settle')}</li>
                    <li>{t('nt_requirements_general_language_ability')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('nt_requirements_skilled_worker_title')}</strong>:
                  <ul className="list-disc list-inside ml-4">
                    <li>{t('nt_requirements_skilled_worker_noc')}</li>
                    <li>{t('nt_requirements_skilled_worker_training')}</li>
                    <li>{t('nt_requirements_skilled_worker_experience')}</li>
                    <li>{t('nt_requirements_skilled_worker_language')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('nt_requirements_entry_level_title')}</strong>:
                  <ul className="list-disc list-inside ml-4">
                    <li>{t('nt_requirements_entry_level_noc')}</li>
                    <li>{t('nt_requirements_entry_level_experience')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('nt_requirements_business_title')}</strong>:
                  <ul className="list-disc list-inside ml-4">
                    <li>{t('nt_requirements_business_investment')}</li>
                    <li>{t('nt_requirements_business_economic_benefit')}</li>
                    <li>{t('nt_requirements_business_job_creation')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('nt_requirements_francophone_title')}</strong>:
                  <ul className="list-disc list-inside ml-4">
                    <li>{t('nt_requirements_francophone_bilingual')}</li>
                    <li>{t('nt_requirements_francophone_job_offer')}</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>


          {/* Step 3: Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-6">
                {t('nt_application_process_title')}
              </h3>

              {/* Step 3.1: Choose the Right Stream */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nt_choose_stream_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li><strong>{t('nt_choose_stream_employer_driven_title')}</strong> {t('nt_choose_stream_employer_driven_description')}</li>
                  <li><strong>{t('nt_choose_stream_francophone_title')}</strong> {t('nt_choose_stream_francophone_description')}</li>
                  <li><strong>{t('nt_choose_stream_business_title')}</strong> {t('nt_choose_stream_business_description')}</li>
                </ul>
              </div>

              {/* Step 3.2: Prepare Documentation */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nt_prepare_documentation_title')}
                </h4>
                <p className="text-gray-700">{t('nt_overview_intro')}</p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('nt_prepare_documentation_passport')}</li>
                  <li>{t('nt_prepare_documentation_language_proficiency')}</li>
                  <li>{t('nt_prepare_documentation_education')}</li>
                  <li>{t('nt_prepare_documentation_work_experience')}</li>
                  <li>{t('nt_prepare_documentation_financial')}</li>
                  <li>{t('nt_prepare_documentation_job_offer')}</li>
                  <li>{t('nt_prepare_documentation_business_plan')}</li>
                </ul>
              </div>

              {/* Step 3.3: Submit an Expression of Interest (EOI) or Application */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nt_submit_eoi_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('nt_submit_eoi_online_portal')}</li>
                  <li>{t('nt_submit_eoi_invitation')}</li>
                
                </ul>
                
              </div>

              {/* Step 3.4: Application Review */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nt_application_review_title')}
                </h4>
                <p className="text-gray-700">{t('nt_application_review_description')}</p>
              </div>

              {/* Step 3.5: Nomination */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nt_nomination_title')}
                </h4>
                <p className="text-gray-700">{t('nt_nomination_description')}</p>
              </div>

              {/* Step 3.6: Apply for Permanent Residence */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nt_apply_for_permanent_residence_title')}
                </h4>
                <p className="text-gray-700">{t('nt_apply_for_permanent_residence_description')}</p>
              </div>

              {/* Step 3.7: Medical, Security, and Other Checks */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nt_medical_security_checks_title')}
                </h4>
                <p className="text-gray-700">{t('nt_medical_security_checks_description')}</p>
              </div>

              {/* Step 3.8: Arrival and Settlement */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nt_arrival_settlement_title')}
                </h4>
                <p className="text-gray-700">{t('nt_arrival_settlement_description')}</p>
              </div>
            </div>
          </div>

          {/* Step 4: Pros & Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('nt_pros_cons_title')}
              </h3>

              {/* Pros Section */}
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('nt_pros_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('nt_pros_faster_path_title')}</strong> {t('nt_pros_faster_path_description')}</li>
                <li><strong>{t('nt_pros_job_security_title')}</strong> {t('nt_pros_job_security_description')}</li>
                <li><strong>{t('nt_pros_rural_living_benefits_title')}</strong> {t('nt_pros_rural_living_benefits_description')}</li>
              </ul>

              {/* Cons Section */}
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2 mt-4">
                {t('nt_cons_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('nt_cons_remote_location_title')}</strong> {t('nt_cons_remote_location_description')}</li>
                <li><strong>{t('nt_cons_limited_urban_amenities_title')}</strong> {t('nt_cons_limited_urban_amenities_description')}</li>
                <li><strong>{t('nt_cons_job_market_title')}</strong> {t('nt_cons_job_market_description')}</li>
                <li><strong>{t('nt_cons_climate_isolation_title')}</strong> {t('nt_cons_climate_isolation_description')}</li>
                <li><strong>{t('nt_cons_dependence_on_resource_industries_title')}</strong> {t('nt_cons_dependence_on_resource_industries_description')}</li>
              </ul>
            </div>
          </div>


          {/* Step 5: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('next_actions')}
                </h3>
                <p className="text-gray-700 mb-4">
                  {t('nt_next_actions_description')}
                </p>
                <Link
                  to="https://www.immigratenwt.ca/immigrate-here"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
                >
                  {t('nt_learn_more')} →
                </Link>
              </div>

              {/* Right side - Call-to-action button centered */}
              <div className="w-full md:w-1/4 flex justify-center"> {/* Center the button */}
                <Link
                  to="/canada-immigration-support/clb-conversion"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Smaller Image */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-2"  // Smaller image
                  />
                  {t('convert_your_clb_score')}
                </Link>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default NorthwestTerritories;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Work = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('work_permit')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Work Permits */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('overview_of_work_permit')}
              </h3>
              <p className="text-gray-700">{t('work_permit_overview')}</p>
            </div>
          </div>

          {/* Step 2: Types of Work Permits */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-4">
                {t('types_of_work_permits')}
              </h3>

              {/* Flex container for employer-specific and open work permits */}
              <div className="flex flex-col md:flex-row gap-6">
                {/* Left Side - Employer-Specific Work Permit */}
                <div className="w-full md:w-1/2 bg-gray-100 p-4 rounded-lg shadow-md">
                  {/* Styled Heading with Light Red Background */}
                  <h4 className="text-md sm:text-lg font-semibold text-white bg-red-600 py-2 px-4 rounded-md mb-4">
                    {t('employer_specific_permit')}
                  </h4>

                  <p className="text-gray-700">{t('employer_specific_permit_description')}</p>


                  {/* Additional Information */}
                  <h4 className="text-md sm:text-lg font-semibold text-red-600 mt-4">
                    {t('employer_specific_conditions_title')}
                  </h4>
                  <p className="text-gray-700 mb-4">{t('employer_specific_conditions_intro')}</p>
                  <ul className="list-disc list-inside text-gray-700 ml-6">
                    <li>{t('employer_specific_conditions_name')}</li>
                    <li>{t('employer_specific_conditions_duration')}</li>
                    <li>{t('employer_specific_conditions_location')}</li>
                  </ul>

                  <h4 className="text-md sm:text-lg font-semibold text-red-600 mt-4">
                    {t('employer_documents_title')}
                  </h4>
                  <p className="text-gray-700 mb-4">{t('employer_documents_intro')}</p>
                  <ul className="list-disc list-inside text-gray-700 ml-6">
                    <li>{t('employer_documents_contract')}</li>
                    <li>{t('employer_documents_options_title')}</li>
                    <ul className="list-inside ml-8">
                      <li>{t('employer_documents_lmia')}</li>
                      <li>{t('employer_documents_offer')}</li>
                    </ul>
                  </ul>

                  {/* Call-to-action button for Employer-Specific Work Permit */}
                  <div className="mt-4 text-center">
                    <Link
                      to="/lmia-tracker"
                      target="_blank" // Opens in a new window
                      rel="noopener noreferrer" // Security enhancement
                      className="inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-sm sm:text-lg font-semibold py-2 sm:py-3 px-6 sm:px-8 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                    >
                      {/* Image at the beginning of the button */}
                      <img
                        src="/images/click-here.png"
                        alt="Click Here"
                        className="w-6 h-6 sm:w-10 sm:h-10 mr-4"
                      />
                      {t('learn_more_about_lmia')}
                    </Link>
                  </div>
                </div>



                {/* Right Side - Open Work Permit */}
                <div className="w-full md:w-1/2 bg-gray-100 p-4 rounded-lg shadow-md">
                  {/* Styled Heading with Light Red Background */}
                  <h4 className="text-md sm:text-lg font-semibold text-white bg-red-600 py-2 px-4 rounded-md mb-4">
                    {t('open_work_permit')}
                  </h4>

                  <p className="text-gray-700">{t('open_work_permit_description')}</p>


                  {/* Additional Information */}
                  <h4 className="text-md sm:text-lg font-semibold text-red-600 mt-4">
                    {t('open_work_permit_eligibility_title')}
                  </h4>
                  <p className="text-gray-700 mb-4">
                    {t('open_work_permit_eligibility_intro')}
                  </p>

                  <ul className="list-disc list-inside text-gray-700 ml-6 ">
                    <li>{t('open_work_permit_eligibility_student')}</li>
                    <li>{t('open_work_permit_eligibility_destitute')}</li>
                    <li>{t('open_work_permit_eligibility_abuse')}</li>
                    <li>{t('open_work_permit_eligibility_pr_applicant')}</li>
                    <li>{t('open_work_permit_eligibility_family_member')}</li>
                    <li>{t('open_work_permit_eligibility_spouse_low_skilled')}</li>
                    <li>{t('open_work_permit_eligibility_spouse_student')}</li>
                    <li>{t('open_work_permit_eligibility_spouse_atlantic')}</li>
                    <li>{t('open_work_permit_eligibility_refugee')}</li>
                    <li>{t('open_work_permit_eligibility_removal_order')}</li>
                    <li>{t('open_work_permit_eligibility_temp_resident')}</li>
                    <li>{t('open_work_permit_eligibility_young_worker')}</li>
                  </ul>

                  {/* Call-to-action button for Open Work Permit */}
                  <div className="mt-4 text-center">
                    <Link
                      to="/canada-immigration-support/pgwp-eligibility"
                      target="_blank" // Opens in a new window
                      rel="noopener noreferrer" // Security enhancement
                      className="inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-sm sm:text-lg font-semibold py-2 sm:py-3 px-6 sm:px-8 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                    >
                      {/* Image at the beginning of the button */}
                      <img
                        src="/images/click-here.png"
                        alt="Click Here"
                        className="w-6 h-6 sm:w-10 sm:h-10 mr-4"
                      />
                      {t('learn_more_about_open_work_permit')}
                    </Link>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Work;

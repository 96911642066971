import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaRegSmileBeam, FaCheckCircle, FaLightbulb } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import TipsSlider from '../components/TipsSlider';
import tipsData from '../data/recommendation_tips.json';
import Select from 'react-select';

const CRSCalculator = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    marital_status: '',
    spouse_citizen_or_pr: '',
    spouse_coming_with: '',
    age: '',
    education: '',
    study_in_canada: '', // Default value for study_in_canada field
    canadian_degree: '',
    test_results_within_two_years: '',
    language_test: "",
    speaking: "",
    listening: "",
    reading: "",
    writing: "",
    second_language_test: "",
    second_speaking: "",
    second_listening: "",
    second_reading: "",
    second_writing: "",
    canadian_work_experience: "",
    foreign_work_experience: "",
    certificate_of_qualification: "",
    job_offer: "",
    job_offer_noc_teer: "",
    nomination_certificate: "",
    sibling_in_canada: "",
    spouse_education: "",
    spouse_canadian_work_experience: "",
    spouse_language_test: "",
    spouse_speaking: "",
    spouse_listening: "",
    spouse_reading: "",
    spouse_writing: ""
  });

  const [responseData, setResponseData] = useState({
    congrats_message: 0,
    crs_score: null,
    optimization_tips: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Convert age to an integer if the field is 'age'
    if (name === 'age' && !isNaN(value)) {
      setFormData({
        ...formData,
        [name]: parseInt(value),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData)
    try {
      // const response = await axios.post('http://127.0.0.1:5001/api/crs_calculator', formData); // Dev
      // const response = await axios.post('http://canadaentry.net:5001/api/crs_calculator', formData); // Test
      const response = await axios.post('/api/crs_calculator', formData, {headers: {'Content-Type': 'application/json'}}); // Prod
      setResponseData({
        congrats_message: response.data.congrats_message,
        crs_score: response.data.crs_score,
        optimization_tips: response.data.optimization_tips,
      });
    } catch (error) {
      console.error('Error submitting the form', error);
    }
  };

  const languageTestOptions = [
    { value: '', label: t('select_language_test') },
    { value: 'CELPIP-G', label: t('CELPIP-G') },
    { value: 'IELTS', label: t('IELTS') },
    { value: 'PTE Core', label: t('PTE Core') },
    { value: 'TEF Canada', label: t('TEF Canada') },
    { value: 'TCF Canada', label: t('TCF Canada') },
  ];

  // Define options for Marital Status
  const maritalStatusOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Never Married/Single', label: t('never_married_single') },
    { value: 'Common-Law', label: t('common_law') },
    { value: 'Married', label: t('married') },
    { value: 'Divorced/Separated', label: t('divorced_separated') },
    { value: 'Legally Separated', label: t('legally_separated') },
    { value: 'Annulled Marriage', label: t('annulled_marriage') },
    { value: 'Widowed', label: t('widowed') },
  ];

  // Define options for Spouse Citizen or PR
  const spouseCitizenOrPrOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Yes', label: t('yes') },
    { value: 'No', label: t('no') },
  ];

  // Define options for Spouse Coming With
  const spouseComingWithOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Yes', label: t('yes') },
    { value: 'No', label: t('no') },
  ];

  // Define options for Age
  const ageOptions = [
    { value: '', label: t('select_an_option') },
    { value: '17_or_less', label: t('17_years_or_less') },
    { value: '18', label: t('age_18') },
    { value: '19', label: t('age_19') },
    { value: '20-29', label: t('age_20_29') },
    { value: '30', label: t('age_30') },
    { value: '31', label: t('age_31') },
    { value: '32', label: t('age_32') },
    { value: '33', label: t('age_33') },
    { value: '34', label: t('age_34') },
    { value: '35', label: t('age_35') },
    { value: '36', label: t('age_36') },
    { value: '37', label: t('age_37') },
    { value: '38', label: t('age_38') },
    { value: '39', label: t('age_39') },
    { value: '40', label: t('age_40') },
    { value: '41', label: t('age_41') },
    { value: '42', label: t('age_42') },
    { value: '43', label: t('age_43') },
    { value: '44', label: t('age_44') },
    { value: '45_or_more', label: t('45_years_or_more') },
  ];


  // Define options for Education
  const educationOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'none', label: t('education_none') },
    { value: 'high_school', label: t('education_high_school') },
    { value: 'one_year', label: t('education_one_year') },
    { value: 'two_year', label: t('education_two_year') },
    { value: 'bachelors', label: t('education_bachelors') },
    { value: 'two_or_more', label: t('education_two_or_more') },
    { value: 'masters', label: t('education_masters') },
    { value: 'phd', label: t('education_phd') },
  ];

  // Define options for Study in Canada
  const studyInCanadaOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Yes', label: t('study_in_canada_yes') },
    { value: 'No', label: t('study_in_canada_no') },
  ];

  // Define options for Canadian Degree
  const canadianDegreeOptions = [
    { value: '', label: t('select_education') },
    { value: 'One- or two-year credential', label: t('canadian_degree_one_or_two_year') },
    { value: 'Three-year or longer credential', label: t('canadian_degree_three_year_or_longer') },
  ];


  // Define options for Test Results Within Two Years
  const testResultsWithinTwoYearsOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Yes', label: t('yes') },
    { value: 'No', label: t('no') },
  ];

  // Define options for Canadian Work Experience
  const canadianWorkExperienceOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'None', label: t('none') },
    { value: '1 year', label: t('1_year') },
    { value: '2 years', label: t('2_years') },
    { value: '3 years', label: t('3_years') },
    { value: '4 years', label: t('4_years') },
    { value: '5 years or more', label: t('5_years_or_more') },
  ];

  // Define options for Foreign Work Experience
  const foreignWorkExperienceOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'None', label: t('none') },
    { value: '1 year', label: t('1_year') },
    { value: '2 years', label: t('2_years') },
    { value: '3 years', label: t('3_years') },
    { value: '4 years', label: t('4_years') },
    { value: '5 years or more', label: t('5_years_or_more') },
  ];

  // Define options for Certificate of Qualification
  const certificateOfQualificationOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Yes', label: t('yes') },
    { value: 'No', label: t('no') },
  ];

  // Define options for Job Offer
  const jobOfferOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Yes', label: t('yes') },
    { value: 'No', label: t('no') },
  ];

  // Define options for Job Offer NOC TEER
  const jobOfferNocTeerOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'NOC TEER 0 Major group 00', label: t('noc_teer_0_major_group_00') },
    { value: 'NOC TEER 1, 2 or 3, or any TEER 0 other than Major group 00', label: t('noc_teer_1_2_3_or_teer_0') },
    { value: 'NOC TEER 4 or 5', label: t('noc_teer_4_5') },
  ];

  // Define options for Nomination Certificate
  const nominationCertificateOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Yes', label: t('yes') },
    { value: 'No', label: t('no') },
  ];

  // Define options for Sibling in Canada
  const siblingInCanadaOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Yes', label: t('yes') },
    { value: 'No', label: t('no') },
  ];

  // Define options for Spouse Education
  const spouseEducationOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'None, or less than secondary (high school)', label: t('spouse_education_none') },
    { value: 'One-year program at a university, college, trade or technical school, or other institute', label: t('spouse_education_one_year') },
    { value: 'Two-year program at a university, college, trade or technical school, or other institute', label: t('spouse_education_two_year') },
    { value: "Bachelor's degree (three or more year program)", label: t('spouse_education_bachelor') },
    { value: 'Two or more certificates, diplomas, or degrees', label: t('spouse_education_two_or_more') },
    { value: "Master's degree or professional degree", label: t('spouse_education_masters') },
    { value: 'Doctoral level university degree (PhD)', label: t('spouse_education_phd') },
  ];

  // Define options for Spouse Canadian Work Experience
  const spouseCanadianWorkExperienceOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'None', label: t('none') },
    { value: '1 year', label: t('1_year') },
    { value: '2 years', label: t('2_years') },
    { value: '3 years', label: t('3_years') },
    { value: '4 years', label: t('4_years') },
    { value: '5 years or more', label: t('5_years_or_more') },
  ];




  const renderLanguageFields = () => {
    // Define options with ielts_ prefix
    const ielts_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '7.5 - 9.0', label: t('7.5 - 9.0') },
      { value: '7.0', label: t('7.0') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '0 - 3.5', label: t('0 - 3.5') },
    ];

    const ielts_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '8.5 - 9.0', label: t('8.5 - 9.0') },
      { value: '8.0', label: t('8.0') },
      { value: '7.5', label: t('7.5') },
      { value: '6.0 - 7.0', label: t('6.0 - 7.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.5', label: t('4.5') },
      { value: '0 - 4.0', label: t('0 - 4.0') },
    ];

    const ielts_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '8.0 - 9.0', label: t('8.0 - 9.0') },
      { value: '7.0 - 7.5', label: t('7.0 - 7.5') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.0 - 5.5', label: t('5.0 - 5.5') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '3.5', label: t('3.5') },
      { value: '0 - 3.0', label: t('0 - 3.0') },
    ];

    const ielts_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '7.5 - 9.0', label: t('7.5 - 9.0') },
      { value: '7.0', label: t('7.0') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '0 - 3.5', label: t('0 - 3.5') },
    ];

    // Define options with celpip_ prefix
    const celpip_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    // Define options with pte_ prefix
    const pte_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '89 - 90', label: t('89 - 90') },
      { value: '84 - 88', label: t('84 - 88') },
      { value: '76 - 83', label: t('76 - 83') },
      { value: '68 - 75', label: t('68 - 75') },
      { value: '59 - 67', label: t('59 - 67') },
      { value: '51 - 58', label: t('51 - 58') },
      { value: '42 - 50', label: t('42 - 50') },
      { value: '0 - 41', label: t('0 - 41') },
    ];

    const pte_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '89 - 90', label: t('89 - 90') },
      { value: '82 - 88', label: t('82 - 88') },
      { value: '71 - 81', label: t('71 - 81') },
      { value: '60 - 70', label: t('60 - 70') },
      { value: '50 - 59', label: t('50 - 59') },
      { value: '39 - 49', label: t('39 - 49') },
      { value: '28 - 38', label: t('28 - 38') },
      { value: '0 - 27', label: t('0 - 27') },
    ];

    const pte_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '88 - 90', label: t('88 - 90') },
      { value: '78 - 87', label: t('78 - 87') },
      { value: '69 - 77', label: t('69 - 77') },
      { value: '60 - 68', label: t('60 - 68') },
      { value: '51 - 59', label: t('51 - 59') },
      { value: '42 - 50', label: t('42 - 50') },
      { value: '33 - 41', label: t('33 - 41') },
      { value: '0 - 32', label: t('0 - 32') },
    ];

    const pte_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '90', label: t('90') },
      { value: '88 - 89', label: t('88 - 89') },
      { value: '79 - 87', label: t('79 - 87') },
      { value: '69 - 78', label: t('69 - 78') },
      { value: '60 - 68', label: t('60 - 68') },
      { value: '51 - 59', label: t('51 - 59') },
      { value: '41 - 50', label: t('41 - 50') },
      { value: '0 - 40', label: t('0 - 40') },
    ];

    // Define options with tcf_ prefix
    const tcf_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '16 - 20', label: t('16 - 20') },
      { value: '14 - 15', label: t('14 - 15') },
      { value: '12 - 13', label: t('12 - 13') },
      { value: '10 - 11', label: t('10 - 11') },
      { value: '7 - 9', label: t('7 - 9') },
      { value: '6', label: t('6') },
      { value: '4 - 5', label: t('4 - 5') },
      { value: '0 - 3', label: t('0 - 3') },
    ];

    const tcf_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '549 - 699', label: t('549 - 699') },
      { value: '523 - 548', label: t('523 - 548') },
      { value: '503 - 522', label: t('503 - 522') },
      { value: '458 - 502', label: t('458 - 502') },
      { value: '398 - 457', label: t('398 - 457') },
      { value: '369 - 397', label: t('369 - 397') },
      { value: '331 - 368', label: t('331 - 368') },
      { value: '0 - 330', label: t('0 - 330') },
    ];

    const tcf_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '549 - 699', label: t('549 - 699') },
      { value: '524 - 548', label: t('524 - 548') },
      { value: '499 - 523', label: t('499 - 523') },
      { value: '453 - 498', label: t('453 - 498') },
      { value: '406 - 452', label: t('406 - 452') },
      { value: '375 - 405', label: t('375 - 405') },
      { value: '342 - 374', label: t('342 - 374') },
      { value: '0 - 341', label: t('0 - 341') },
    ];

    const tcf_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '16 - 20', label: t('16 - 20') },
      { value: '14 - 15', label: t('14 - 15') },
      { value: '12 - 13', label: t('12 - 13') },
      { value: '10 - 11', label: t('10 - 11') },
      { value: '7 - 9', label: t('7 - 9') },
      { value: '6', label: t('6') },
      { value: '4 - 5', label: t('4 - 5') },
      { value: '0 - 3', label: t('0 - 3') },
    ];

    // Define options with tef_ prefix
    const tef_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '393 - 450', label: t('393 - 450') },
      { value: '371 - 392', label: t('371 - 392') },
      { value: '349 - 370', label: t('349 - 370') },
      { value: '310 - 348', label: t('310 - 348') },
      { value: '271 - 309', label: t('271 - 309') },
      { value: '226 - 270', label: t('226 - 270') },
      { value: '181 - 225', label: t('181 - 225') },
      { value: '0 - 180', label: t('0 - 180') },
    ];

    const tef_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '316 - 360', label: t('316 - 360') },
      { value: '298 - 315', label: t('298 - 315') },
      { value: '280 - 297', label: t('280 - 297') },
      { value: '249 - 279', label: t('249 - 279') },
      { value: '217 - 248', label: t('217 - 248') },
      { value: '181 - 216', label: t('181 - 216') },
      { value: '145 - 180', label: t('145 - 180') },
      { value: '0 - 144', label: t('0 - 144') },
    ];

    const tef_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '263 - 300', label: t('263 - 300') },
      { value: '248 - 262', label: t('248 - 262') },
      { value: '233 - 247', label: t('233 - 247') },
      { value: '207 - 232', label: t('207 - 232') },
      { value: '181 - 206', label: t('181 - 206') },
      { value: '151 - 180', label: t('151 - 180') },
      { value: '121 - 150', label: t('121 - 150') },
      { value: '0 - 120', label: t('0 - 120') },
    ];

    const tef_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '393 - 450', label: t('393 - 450') },
      { value: '371 - 392', label: t('371 - 392') },
      { value: '349 - 370', label: t('349 - 370') },
      { value: '310 - 348', label: t('310 - 348') },
      { value: '271 - 309', label: t('271 - 309') },
      { value: '226 - 270', label: t('226 - 270') },
      { value: '181 - 225', label: t('181 - 225') },
      { value: '0 - 180', label: t('0 - 180') },
    ];



    if (formData.language_test === 'IELTS') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={ielts_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={ielts_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={ielts_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={ielts_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={ielts_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={ielts_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={ielts_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={ielts_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.language_test === 'CELPIP-G') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={celpip_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={celpip_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={celpip_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={celpip_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={celpip_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={celpip_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={celpip_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={celpip_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.language_test === 'PTE Core') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={pte_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={pte_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={pte_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={pte_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={pte_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={pte_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={pte_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={pte_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>

      );
    } else if (formData.language_test === 'TCF Canada') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={tcf_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={tcf_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={tcf_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={tcf_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={tcf_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={tcf_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={tcf_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={tcf_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>

      );
    } else if (formData.language_test === 'TEF Canada') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={tef_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={tef_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={tef_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={tef_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={tef_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={tef_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={tef_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={tef_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>

      );
    }

    return null;
  };

  const renderSecondLanguageOptions = () => {
    const firstGroup = ["CELPIP-G", "IELTS", "PTE Core"];
    const secondGroup = ["TEF Canada", "TCF Canada"];

    // Define all possible options with their labels
    const allOptions = [
      { value: 'CELPIP-G', label: t('CELPIP-G') },
      { value: 'IELTS', label: t('IELTS') },
      { value: 'PTE Core', label: t('PTE Core') },
      { value: 'TEF Canada', label: t('TEF Canada') },
      { value: 'TCF Canada', label: t('TCF Canada') },
      { value: 'not_applicable', label: t('not_applicable') }
    ];

    if (firstGroup.includes(formData.language_test)) {
      // Return options for the second language group if the first group is selected
      return allOptions.filter(option =>
        ["TEF Canada", "TCF Canada", "not_applicable"].includes(option.value)
      );
    } else if (secondGroup.includes(formData.language_test)) {
      // Return options for the first language group if the second group is selected
      return allOptions.filter(option =>
        ["CELPIP-G", "IELTS", "PTE Core", "not_applicable"].includes(option.value)
      );
    } else {
      // Return all options by default
      return allOptions;
    }
  };



  const renderSecondLanguageFields = () => {
    // Define options with ielts_ prefix
    const ielts_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '7.5 - 9.0', label: t('7.5 - 9.0') },
      { value: '7.0', label: t('7.0') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '0 - 3.5', label: t('0 - 3.5') },
    ];

    const ielts_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '8.5 - 9.0', label: t('8.5 - 9.0') },
      { value: '8.0', label: t('8.0') },
      { value: '7.5', label: t('7.5') },
      { value: '6.0 - 7.0', label: t('6.0 - 7.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.5', label: t('4.5') },
      { value: '0 - 4.0', label: t('0 - 4.0') },
    ];

    const ielts_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '8.0 - 9.0', label: t('8.0 - 9.0') },
      { value: '7.0 - 7.5', label: t('7.0 - 7.5') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.0 - 5.5', label: t('5.0 - 5.5') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '3.5', label: t('3.5') },
      { value: '0 - 3.0', label: t('0 - 3.0') },
    ];

    const ielts_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '7.5 - 9.0', label: t('7.5 - 9.0') },
      { value: '7.0', label: t('7.0') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '0 - 3.5', label: t('0 - 3.5') },
    ];

    // Define options with celpip_ prefix
    const celpip_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    // Define options with pte_ prefix
    const pte_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '89 - 90', label: t('89 - 90') },
      { value: '84 - 88', label: t('84 - 88') },
      { value: '76 - 83', label: t('76 - 83') },
      { value: '68 - 75', label: t('68 - 75') },
      { value: '59 - 67', label: t('59 - 67') },
      { value: '51 - 58', label: t('51 - 58') },
      { value: '42 - 50', label: t('42 - 50') },
      { value: '0 - 41', label: t('0 - 41') },
    ];

    const pte_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '89 - 90', label: t('89 - 90') },
      { value: '82 - 88', label: t('82 - 88') },
      { value: '71 - 81', label: t('71 - 81') },
      { value: '60 - 70', label: t('60 - 70') },
      { value: '50 - 59', label: t('50 - 59') },
      { value: '39 - 49', label: t('39 - 49') },
      { value: '28 - 38', label: t('28 - 38') },
      { value: '0 - 27', label: t('0 - 27') },
    ];

    const pte_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '88 - 90', label: t('88 - 90') },
      { value: '78 - 87', label: t('78 - 87') },
      { value: '69 - 77', label: t('69 - 77') },
      { value: '60 - 68', label: t('60 - 68') },
      { value: '51 - 59', label: t('51 - 59') },
      { value: '42 - 50', label: t('42 - 50') },
      { value: '33 - 41', label: t('33 - 41') },
      { value: '0 - 32', label: t('0 - 32') },
    ];

    const pte_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '90', label: t('90') },
      { value: '88 - 89', label: t('88 - 89') },
      { value: '79 - 87', label: t('79 - 87') },
      { value: '69 - 78', label: t('69 - 78') },
      { value: '60 - 68', label: t('60 - 68') },
      { value: '51 - 59', label: t('51 - 59') },
      { value: '41 - 50', label: t('41 - 50') },
      { value: '0 - 40', label: t('0 - 40') },
    ];

    // Define options with tcf_ prefix
    const tcf_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '16 - 20', label: t('16 - 20') },
      { value: '14 - 15', label: t('14 - 15') },
      { value: '12 - 13', label: t('12 - 13') },
      { value: '10 - 11', label: t('10 - 11') },
      { value: '7 - 9', label: t('7 - 9') },
      { value: '6', label: t('6') },
      { value: '4 - 5', label: t('4 - 5') },
      { value: '0 - 3', label: t('0 - 3') },
    ];

    const tcf_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '549 - 699', label: t('549 - 699') },
      { value: '523 - 548', label: t('523 - 548') },
      { value: '503 - 522', label: t('503 - 522') },
      { value: '458 - 502', label: t('458 - 502') },
      { value: '398 - 457', label: t('398 - 457') },
      { value: '369 - 397', label: t('369 - 397') },
      { value: '331 - 368', label: t('331 - 368') },
      { value: '0 - 330', label: t('0 - 330') },
    ];

    const tcf_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '549 - 699', label: t('549 - 699') },
      { value: '524 - 548', label: t('524 - 548') },
      { value: '499 - 523', label: t('499 - 523') },
      { value: '453 - 498', label: t('453 - 498') },
      { value: '406 - 452', label: t('406 - 452') },
      { value: '375 - 405', label: t('375 - 405') },
      { value: '342 - 374', label: t('342 - 374') },
      { value: '0 - 341', label: t('0 - 341') },
    ];

    const tcf_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '16 - 20', label: t('16 - 20') },
      { value: '14 - 15', label: t('14 - 15') },
      { value: '12 - 13', label: t('12 - 13') },
      { value: '10 - 11', label: t('10 - 11') },
      { value: '7 - 9', label: t('7 - 9') },
      { value: '6', label: t('6') },
      { value: '4 - 5', label: t('4 - 5') },
      { value: '0 - 3', label: t('0 - 3') },
    ];

    // Define options with tef_ prefix
    const tef_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '393 - 450', label: t('393 - 450') },
      { value: '371 - 392', label: t('371 - 392') },
      { value: '349 - 370', label: t('349 - 370') },
      { value: '310 - 348', label: t('310 - 348') },
      { value: '271 - 309', label: t('271 - 309') },
      { value: '226 - 270', label: t('226 - 270') },
      { value: '181 - 225', label: t('181 - 225') },
      { value: '0 - 180', label: t('0 - 180') },
    ];

    const tef_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '316 - 360', label: t('316 - 360') },
      { value: '298 - 315', label: t('298 - 315') },
      { value: '280 - 297', label: t('280 - 297') },
      { value: '249 - 279', label: t('249 - 279') },
      { value: '217 - 248', label: t('217 - 248') },
      { value: '181 - 216', label: t('181 - 216') },
      { value: '145 - 180', label: t('145 - 180') },
      { value: '0 - 144', label: t('0 - 144') },
    ];

    const tef_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '263 - 300', label: t('263 - 300') },
      { value: '248 - 262', label: t('248 - 262') },
      { value: '233 - 247', label: t('233 - 247') },
      { value: '207 - 232', label: t('207 - 232') },
      { value: '181 - 206', label: t('181 - 206') },
      { value: '151 - 180', label: t('151 - 180') },
      { value: '121 - 150', label: t('121 - 150') },
      { value: '0 - 120', label: t('0 - 120') },
    ];

    const tef_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '393 - 450', label: t('393 - 450') },
      { value: '371 - 392', label: t('371 - 392') },
      { value: '349 - 370', label: t('349 - 370') },
      { value: '310 - 348', label: t('310 - 348') },
      { value: '271 - 309', label: t('271 - 309') },
      { value: '226 - 270', label: t('226 - 270') },
      { value: '181 - 225', label: t('181 - 225') },
      { value: '0 - 180', label: t('0 - 180') },
    ];

    if (formData.second_language_test === 'IELTS') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="second_speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="second_speaking"
              name="second_speaking"
              value={ielts_speakingOptions.find(option => option.value === formData.second_speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_speaking', value: selectedOption.value } })}
              options={ielts_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="second_listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="second_listening"
              name="second_listening"
              value={ielts_listeningOptions.find(option => option.value === formData.second_listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_listening', value: selectedOption.value } })}
              options={ielts_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="second_reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="second_reading"
              name="second_reading"
              value={ielts_readingOptions.find(option => option.value === formData.second_reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_reading', value: selectedOption.value } })}
              options={ielts_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="second_writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="second_writing"
              name="second_writing"
              value={ielts_writingOptions.find(option => option.value === formData.second_writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_writing', value: selectedOption.value } })}
              options={ielts_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.second_language_test === 'CELPIP-G') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="second_speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="second_speaking"
              name="second_speaking"
              value={celpip_speakingOptions.find(option => option.value === formData.second_speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_speaking', value: selectedOption.value } })}
              options={celpip_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="second_listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="second_listening"
              name="second_listening"
              value={celpip_listeningOptions.find(option => option.value === formData.second_listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_listening', value: selectedOption.value } })}
              options={celpip_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="second_reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="second_reading"
              name="second_reading"
              value={celpip_readingOptions.find(option => option.value === formData.second_reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_reading', value: selectedOption.value } })}
              options={celpip_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="second_writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="second_writing"
              name="second_writing"
              value={celpip_writingOptions.find(option => option.value === formData.second_writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_writing', value: selectedOption.value } })}
              options={celpip_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.second_language_test === 'PTE Core') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="second_speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="second_speaking"
              name="second_speaking"
              value={pte_speakingOptions.find(option => option.value === formData.second_speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_speaking', value: selectedOption.value } })}
              options={pte_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="second_listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="second_listening"
              name="second_listening"
              value={pte_listeningOptions.find(option => option.value === formData.second_listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_listening', value: selectedOption.value } })}
              options={pte_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="second_reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="second_reading"
              name="second_reading"
              value={pte_readingOptions.find(option => option.value === formData.second_reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_reading', value: selectedOption.value } })}
              options={pte_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="second_writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="second_writing"
              name="second_writing"
              value={pte_writingOptions.find(option => option.value === formData.second_writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_writing', value: selectedOption.value } })}
              options={pte_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.second_language_test === 'TCF Canada') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="second_speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="second_speaking"
              name="second_speaking"
              value={tcf_speakingOptions.find(option => option.value === formData.second_speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_speaking', value: selectedOption.value } })}
              options={tcf_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="second_listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="second_listening"
              name="second_listening"
              value={tcf_listeningOptions.find(option => option.value === formData.second_listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_listening', value: selectedOption.value } })}
              options={tcf_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="second_reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="second_reading"
              name="second_reading"
              value={tcf_readingOptions.find(option => option.value === formData.second_reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_reading', value: selectedOption.value } })}
              options={tcf_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="second_writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="second_writing"
              name="second_writing"
              value={tcf_writingOptions.find(option => option.value === formData.second_writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_writing', value: selectedOption.value } })}
              options={tcf_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.second_language_test === 'TEF Canada') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="second_speaking" className="block text-base sm:text-lg font-medium">{t('speaking')}</label>
            <Select
              id="second_speaking"
              name="second_speaking"
              value={tef_speakingOptions.find(option => option.value === formData.second_speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_speaking', value: selectedOption.value } })}
              options={tef_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="second_listening" className="block text-base sm:text-lg font-medium">{t('listening')}</label>
            <Select
              id="second_listening"
              name="second_listening"
              value={tef_listeningOptions.find(option => option.value === formData.second_listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_listening', value: selectedOption.value } })}
              options={tef_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="second_reading" className="block text-base sm:text-lg font-medium">{t('reading')}</label>
            <Select
              id="second_reading"
              name="second_reading"
              value={tef_readingOptions.find(option => option.value === formData.second_reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_reading', value: selectedOption.value } })}
              options={tef_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="second_writing" className="block text-base sm:text-lg font-medium">{t('writing')}</label>
            <Select
              id="second_writing"
              name="second_writing"
              value={tef_writingOptions.find(option => option.value === formData.second_writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'second_writing', value: selectedOption.value } })}
              options={tef_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  const renderSpouseLanguageFields = () => {
    // Define options with ielts_ prefix
    const ielts_spouseSpeakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '7.5 - 9.0', label: t('7.5 - 9.0') },
      { value: '7.0', label: t('7.0') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '0 - 3.5', label: t('0 - 3.5') },
    ];

    const ielts_spouseListeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '8.5 - 9.0', label: t('8.5 - 9.0') },
      { value: '8.0', label: t('8.0') },
      { value: '7.5', label: t('7.5') },
      { value: '6.0 - 7.0', label: t('6.0 - 7.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.5', label: t('4.5') },
      { value: '0 - 4.0', label: t('0 - 4.0') },
    ];

    const ielts_spouseReadingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '8.0 - 9.0', label: t('8.0 - 9.0') },
      { value: '7.0 - 7.5', label: t('7.0 - 7.5') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.0 - 5.5', label: t('5.0 - 5.5') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '3.5', label: t('3.5') },
      { value: '0 - 3.0', label: t('0 - 3.0') },
    ];

    const ielts_spouseWritingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '7.5 - 9.0', label: t('7.5 - 9.0') },
      { value: '7.0', label: t('7.0') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '0 - 3.5', label: t('0 - 3.5') },
    ];

    // Define options with celpip_ prefix
    const celpip_spouseSpeakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_spouseListeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_spouseReadingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_spouseWritingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];


    // Define options with pte_ prefix
    const pte_spouseSpeakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '89 - 90', label: t('89 - 90') },
      { value: '84 - 88', label: t('84 - 88') },
      { value: '76 - 83', label: t('76 - 83') },
      { value: '68 - 75', label: t('68 - 75') },
      { value: '59 - 67', label: t('59 - 67') },
      { value: '51 - 58', label: t('51 - 58') },
      { value: '42 - 50', label: t('42 - 50') },
      { value: '0 - 41', label: t('0 - 41') },
    ];

    const pte_spouseListeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '89 - 90', label: t('89 - 90') },
      { value: '82 - 88', label: t('82 - 88') },
      { value: '71 - 81', label: t('71 - 81') },
      { value: '60 - 70', label: t('60 - 70') },
      { value: '50 - 59', label: t('50 - 59') },
      { value: '39 - 49', label: t('39 - 49') },
      { value: '28 - 38', label: t('28 - 38') },
      { value: '0 - 27', label: t('0 - 27') },
    ];

    const pte_spouseReadingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '88 - 90', label: t('88 - 90') },
      { value: '78 - 87', label: t('78 - 87') },
      { value: '69 - 77', label: t('69 - 77') },
      { value: '60 - 68', label: t('60 - 68') },
      { value: '51 - 59', label: t('51 - 59') },
      { value: '42 - 50', label: t('42 - 50') },
      { value: '33 - 41', label: t('33 - 41') },
      { value: '0 - 32', label: t('0 - 32') },
    ];

    const pte_spouseWritingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '90', label: t('90') },
      { value: '88 - 89', label: t('88 - 89') },
      { value: '79 - 87', label: t('79 - 87') },
      { value: '69 - 78', label: t('69 - 78') },
      { value: '60 - 68', label: t('60 - 68') },
      { value: '51 - 59', label: t('51 - 59') },
      { value: '41 - 50', label: t('41 - 50') },
      { value: '0 - 40', label: t('0 - 40') },
    ];

    // Define options with tcf_ prefix
    const tcf_spouseSpeakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '16 - 20', label: t('16 - 20') },
      { value: '14 - 15', label: t('14 - 15') },
      { value: '12 - 13', label: t('12 - 13') },
      { value: '10 - 11', label: t('10 - 11') },
      { value: '7 - 9', label: t('7 - 9') },
      { value: '6', label: t('6') },
      { value: '4 - 5', label: t('4 - 5') },
      { value: '0 - 3', label: t('0 - 3') },
    ];

    const tcf_spouseListeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '549 - 699', label: t('549 - 699') },
      { value: '523 - 548', label: t('523 - 548') },
      { value: '503 - 522', label: t('503 - 522') },
      { value: '458 - 502', label: t('458 - 502') },
      { value: '398 - 457', label: t('398 - 457') },
      { value: '369 - 397', label: t('369 - 397') },
      { value: '331 - 368', label: t('331 - 368') },
      { value: '0 - 330', label: t('0 - 330') },
    ];

    const tcf_spouseReadingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '549 - 699', label: t('549 - 699') },
      { value: '524 - 548', label: t('524 - 548') },
      { value: '499 - 523', label: t('499 - 523') },
      { value: '453 - 498', label: t('453 - 498') },
      { value: '406 - 452', label: t('406 - 452') },
      { value: '375 - 405', label: t('375 - 405') },
      { value: '342 - 374', label: t('342 - 374') },
      { value: '0 - 341', label: t('0 - 341') },
    ];

    const tcf_spouseWritingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '16 - 20', label: t('16 - 20') },
      { value: '14 - 15', label: t('14 - 15') },
      { value: '12 - 13', label: t('12 - 13') },
      { value: '10 - 11', label: t('10 - 11') },
      { value: '7 - 9', label: t('7 - 9') },
      { value: '6', label: t('6') },
      { value: '4 - 5', label: t('4 - 5') },
      { value: '0 - 3', label: t('0 - 3') },
    ];

    // Define options with tef_ prefix
    const tef_spouseSpeakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '393 - 450', label: t('393 - 450') },
      { value: '371 - 392', label: t('371 - 392') },
      { value: '349 - 370', label: t('349 - 370') },
      { value: '310 - 348', label: t('310 - 348') },
      { value: '271 - 309', label: t('271 - 309') },
      { value: '226 - 270', label: t('226 - 270') },
      { value: '181 - 225', label: t('181 - 225') },
      { value: '0 - 180', label: t('0 - 180') },
    ];

    const tef_spouseListeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '316 - 360', label: t('316 - 360') },
      { value: '298 - 315', label: t('298 - 315') },
      { value: '280 - 297', label: t('280 - 297') },
      { value: '249 - 279', label: t('249 - 279') },
      { value: '217 - 248', label: t('217 - 248') },
      { value: '181 - 216', label: t('181 - 216') },
      { value: '145 - 180', label: t('145 - 180') },
      { value: '0 - 144', label: t('0 - 144') },
    ];

    const tef_spouseReadingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '263 - 300', label: t('263 - 300') },
      { value: '248 - 262', label: t('248 - 262') },
      { value: '233 - 247', label: t('233 - 247') },
      { value: '207 - 232', label: t('207 - 232') },
      { value: '181 - 206', label: t('181 - 206') },
      { value: '151 - 180', label: t('151 - 180') },
      { value: '121 - 150', label: t('121 - 150') },
      { value: '0 - 120', label: t('0 - 120') },
    ];

    const tef_spouseWritingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '393 - 450', label: t('393 - 450') },
      { value: '371 - 392', label: t('371 - 392') },
      { value: '349 - 370', label: t('349 - 370') },
      { value: '310 - 348', label: t('310 - 348') },
      { value: '271 - 309', label: t('271 - 309') },
      { value: '226 - 270', label: t('226 - 270') },
      { value: '181 - 225', label: t('181 - 225') },
      { value: '0 - 180', label: t('0 - 180') },
    ];



    if (formData.spouse_language_test === 'IELTS') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Spouse Speaking Field */}
          <div>
            <label htmlFor="spouse_speaking" className="block text-base sm:text-lg font-medium">{t('spouse_speaking')}</label>
            <Select
              id="spouse_speaking"
              name="spouse_speaking"
              value={ielts_spouseSpeakingOptions.find(option => option.value === formData.spouse_speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_speaking', value: selectedOption.value } })}
              options={ielts_spouseSpeakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Listening Field */}
          <div>
            <label htmlFor="spouse_listening" className="block text-base sm:text-lg font-medium">{t('spouse_listening')}</label>
            <Select
              id="spouse_listening"
              name="spouse_listening"
              value={ielts_spouseListeningOptions.find(option => option.value === formData.spouse_listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_listening', value: selectedOption.value } })}
              options={ielts_spouseListeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Reading Field */}
          <div>
            <label htmlFor="spouse_reading" className="block text-base sm:text-lg font-medium">{t('spouse_reading')}</label>
            <Select
              id="spouse_reading"
              name="spouse_reading"
              value={ielts_spouseReadingOptions.find(option => option.value === formData.spouse_reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_reading', value: selectedOption.value } })}
              options={ielts_spouseReadingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Writing Field */}
          <div>
            <label htmlFor="spouse_writing" className="block text-base sm:text-lg font-medium">{t('spouse_writing')}</label>
            <Select
              id="spouse_writing"
              name="spouse_writing"
              value={ielts_spouseWritingOptions.find(option => option.value === formData.spouse_writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_writing', value: selectedOption.value } })}
              options={ielts_spouseWritingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.spouse_language_test === 'CELPIP-G') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Spouse Speaking Field */}
          <div>
            <label htmlFor="spouse_speaking" className="block text-base sm:text-lg font-medium">{t('spouse_speaking')}</label>
            <Select
              id="spouse_speaking"
              name="spouse_speaking"
              value={celpip_spouseSpeakingOptions.find(option => option.value === formData.spouse_speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_speaking', value: selectedOption.value } })}
              options={celpip_spouseSpeakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Listening Field */}
          <div>
            <label htmlFor="spouse_listening" className="block text-base sm:text-lg font-medium">{t('spouse_listening')}</label>
            <Select
              id="spouse_listening"
              name="spouse_listening"
              value={celpip_spouseListeningOptions.find(option => option.value === formData.spouse_listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_listening', value: selectedOption.value } })}
              options={celpip_spouseListeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Reading Field */}
          <div>
            <label htmlFor="spouse_reading" className="block text-base sm:text-lg font-medium">{t('spouse_reading')}</label>
            <Select
              id="spouse_reading"
              name="spouse_reading"
              value={celpip_spouseReadingOptions.find(option => option.value === formData.spouse_reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_reading', value: selectedOption.value } })}
              options={celpip_spouseReadingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Writing Field */}
          <div>
            <label htmlFor="spouse_writing" className="block text-base sm:text-lg font-medium">{t('spouse_writing')}</label>
            <Select
              id="spouse_writing"
              name="spouse_writing"
              value={celpip_spouseWritingOptions.find(option => option.value === formData.spouse_writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_writing', value: selectedOption.value } })}
              options={celpip_spouseWritingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.spouse_language_test === 'PTE Core') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Spouse Speaking Field */}
          <div>
            <label htmlFor="spouse_speaking" className="block text-base sm:text-lg font-medium">{t('spouse_speaking')}</label>
            <Select
              id="spouse_speaking"
              name="spouse_speaking"
              value={pte_spouseSpeakingOptions.find(option => option.value === formData.spouse_speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_speaking', value: selectedOption.value } })}
              options={pte_spouseSpeakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Listening Field */}
          <div>
            <label htmlFor="spouse_listening" className="block text-base sm:text-lg font-medium">{t('spouse_listening')}</label>
            <Select
              id="spouse_listening"
              name="spouse_listening"
              value={pte_spouseListeningOptions.find(option => option.value === formData.spouse_listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_listening', value: selectedOption.value } })}
              options={pte_spouseListeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Reading Field */}
          <div>
            <label htmlFor="spouse_reading" className="block text-base sm:text-lg font-medium">{t('spouse_reading')}</label>
            <Select
              id="spouse_reading"
              name="spouse_reading"
              value={pte_spouseReadingOptions.find(option => option.value === formData.spouse_reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_reading', value: selectedOption.value } })}
              options={pte_spouseReadingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Writing Field */}
          <div>
            <label htmlFor="spouse_writing" className="block text-base sm:text-lg font-medium">{t('spouse_writing')}</label>
            <Select
              id="spouse_writing"
              name="spouse_writing"
              value={pte_spouseWritingOptions.find(option => option.value === formData.spouse_writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_writing', value: selectedOption.value } })}
              options={pte_spouseWritingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.spouse_language_test === 'TCF Canada') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Spouse Speaking Field */}
          <div>
            <label htmlFor="spouse_speaking" className="block text-base sm:text-lg font-medium">{t('spouse_speaking')}</label>
            <Select
              id="spouse_speaking"
              name="spouse_speaking"
              value={tcf_spouseSpeakingOptions.find(option => option.value === formData.spouse_speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_speaking', value: selectedOption.value } })}
              options={tcf_spouseSpeakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Listening Field */}
          <div>
            <label htmlFor="spouse_listening" className="block text-base sm:text-lg font-medium">{t('spouse_listening')}</label>
            <Select
              id="spouse_listening"
              name="spouse_listening"
              value={tcf_spouseListeningOptions.find(option => option.value === formData.spouse_listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_listening', value: selectedOption.value } })}
              options={tcf_spouseListeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Reading Field */}
          <div>
            <label htmlFor="spouse_reading" className="block text-base sm:text-lg font-medium">{t('spouse_reading')}</label>
            <Select
              id="spouse_reading"
              name="spouse_reading"
              value={tcf_spouseReadingOptions.find(option => option.value === formData.spouse_reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_reading', value: selectedOption.value } })}
              options={tcf_spouseReadingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Writing Field */}
          <div>
            <label htmlFor="spouse_writing" className="block text-base sm:text-lg font-medium">{t('spouse_writing')}</label>
            <Select
              id="spouse_writing"
              name="spouse_writing"
              value={tcf_spouseWritingOptions.find(option => option.value === formData.spouse_writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_writing', value: selectedOption.value } })}
              options={tcf_spouseWritingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.spouse_language_test === 'TEF Canada') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Spouse Speaking Field */}
          <div>
            <label htmlFor="spouse_speaking" className="block text-base sm:text-lg font-medium">{t('spouse_speaking')}</label>
            <Select
              id="spouse_speaking"
              name="spouse_speaking"
              value={tef_spouseSpeakingOptions.find(option => option.value === formData.spouse_speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_speaking', value: selectedOption.value } })}
              options={tef_spouseSpeakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Listening Field */}
          <div>
            <label htmlFor="spouse_listening" className="block text-base sm:text-lg font-medium">{t('spouse_listening')}</label>
            <Select
              id="spouse_listening"
              name="spouse_listening"
              value={tef_spouseListeningOptions.find(option => option.value === formData.spouse_listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_listening', value: selectedOption.value } })}
              options={tef_spouseListeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Reading Field */}
          <div>
            <label htmlFor="spouse_reading" className="block text-base sm:text-lg font-medium">{t('spouse_reading')}</label>
            <Select
              id="spouse_reading"
              name="spouse_reading"
              value={tef_spouseReadingOptions.find(option => option.value === formData.spouse_reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_reading', value: selectedOption.value } })}
              options={tef_spouseReadingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Spouse Writing Field */}
          <div>
            <label htmlFor="spouse_writing" className="block text-base sm:text-lg font-medium">{t('spouse_writing')}</label>
            <Select
              id="spouse_writing"
              name="spouse_writing"
              value={tef_spouseWritingOptions.find(option => option.value === formData.spouse_writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'spouse_writing', value: selectedOption.value } })}
              options={tef_spouseWritingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    }
    return null;
  };





  return (
    <div className="p-8 bg-gray-100">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Form Section */}
        <section className="bg-white p-8 rounded-md shadow-md">
          
          <div className="bg-red-100 text-red-600 p-2 sm:p-4 rounded-md shadow-sm text-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold">{t('crs_calculation')}</h2>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4 mt-6">
            {/* Marital Status */}
            <div>
              <label htmlFor="marital_status" className="block text-base sm:text-lg font-medium">
                {t('marital_status')}
              </label>
              <Select
                id="marital_status"
                name="marital_status"
                value={maritalStatusOptions.find(option => option.value === formData.marital_status)}
                onChange={(selectedOption) => handleChange({ target: { name: 'marital_status', value: selectedOption.value } })}
                options={maritalStatusOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Spouse Citizen or PR - Conditional Field */}
            {(formData.marital_status === 'Married' || formData.marital_status === 'Common-Law') && (
              <div>
                <label htmlFor="spouse_citizen_or_pr" className="block text-base sm:text-lg font-medium">
                  {t('spouse_citizen_or_pr')}
                </label>
                <Select
                  id="spouse_citizen_or_pr"
                  name="spouse_citizen_or_pr"
                  value={spouseCitizenOrPrOptions.find(option => option.value === formData.spouse_citizen_or_pr)}
                  onChange={(selectedOption) => handleChange({ target: { name: 'spouse_citizen_or_pr', value: selectedOption.value } })}
                  options={spouseCitizenOrPrOptions}
                  className="mt-2"
                  classNamePrefix="react-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: '100%',
                      padding: '0.5rem',
                      borderColor: 'gray-300',
                      borderRadius: '0.375rem',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 50,
                    }),
                  }}
                  placeholder={t('select_an_option')}
                />
              </div>

            )}

            {/* Spouse Coming With - Conditional Field */}
            {(formData.marital_status === 'Married' || formData.marital_status === 'Common-Law') &&
              formData.spouse_citizen_or_pr === 'No' && (
                <div>
                  <label htmlFor="spouse_coming_with" className="block text-base sm:text-lg font-medium">
                    {t('spouse_coming_with')}
                  </label>
                  <Select
                    id="spouse_coming_with"
                    name="spouse_coming_with"
                    value={spouseComingWithOptions.find(option => option.value === formData.spouse_coming_with)}
                    onChange={(selectedOption) => handleChange({ target: { name: 'spouse_coming_with', value: selectedOption.value } })}
                    options={spouseComingWithOptions}
                    className="mt-2"
                    classNamePrefix="react-select"
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: '100%',
                        padding: '0.5rem',
                        borderColor: 'gray-300',
                        borderRadius: '0.375rem',
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 50,
                      }),
                    }}
                    placeholder={t('select_an_option')}
                  />
                </div>
              )}

            {/* Age Field */}
            <div>
              <label htmlFor="age" className="block text-base sm:text-lg font-medium">
                {t('age')}
              </label>
              <Select
                id="age"
                name="age"
                value={ageOptions.find(option => option.value === formData.age)}
                onChange={(selectedOption) => handleChange({ target: { name: 'age', value: selectedOption.value } })}
                options={ageOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Education Field */}

            <div>
              <label htmlFor="education" className="block text-base sm:text-lg font-medium">
                {t('education')}
              </label>
              <Select
                id="education"
                name="education"
                value={educationOptions.find(option => option.value === formData.education)}
                onChange={(selectedOption) => handleChange({ target: { name: 'education', value: selectedOption.value } })}
                options={educationOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Study in Canada Field */}
            <div>
              <label htmlFor="study_in_canada" className="block text-base sm:text-lg font-medium">
                {t('study_in_canada_label')}
              </label>
              <Select
                id="study_in_canada"
                name="study_in_canada"
                value={studyInCanadaOptions.find(option => option.value === formData.study_in_canada)}
                onChange={(selectedOption) => handleChange({ target: { name: 'study_in_canada', value: selectedOption.value } })}
                options={studyInCanadaOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Canadian Degree - Conditional Field */}
            {formData.study_in_canada === 'Yes' && (
              <div>
                <label htmlFor="canadian_degree" className="block text-base sm:text-lg font-medium">
                  {t('canadian_degree_label')}
                </label>
                <Select
                  id="canadian_degree"
                  name="canadian_degree"
                  value={canadianDegreeOptions.find(option => option.value === formData.canadian_degree)}
                  onChange={(selectedOption) => handleChange({ target: { name: 'canadian_degree', value: selectedOption.value } })}
                  options={canadianDegreeOptions}
                  className="mt-2"
                  classNamePrefix="react-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: '100%',
                      padding: '0.5rem',
                      borderColor: 'gray-300',
                      borderRadius: '0.375rem',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 50,
                    }),
                  }}
                  placeholder={t('select_education')}
                />
              </div>
            )}

            {/* Test Results within Two Years Field */}
            <div>
              <label htmlFor="test_results_within_two_years" className="block text-base sm:text-lg font-medium">
                {t('test_results_within_two_years')}
              </label>
              <Select
                id="test_results_within_two_years"
                name="test_results_within_two_years"
                value={testResultsWithinTwoYearsOptions.find(option => option.value === formData.test_results_within_two_years)}
                onChange={(selectedOption) => handleChange({ target: { name: 'test_results_within_two_years', value: selectedOption.value } })}
                options={testResultsWithinTwoYearsOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>
            {/* Language Test Field */}
            {formData.test_results_within_two_years === 'Yes' && (
              <div>
                <label htmlFor="language_test" className="block text-base sm:text-lg font-medium">
                  {t('language_test')}
                </label>
                <Select
                  id="language_test"
                  name="language_test"
                  value={languageTestOptions.find(option => option.value === formData.language_test)}
                  onChange={(selectedOption) => handleChange({ target: { name: 'language_test', value: selectedOption.value } })}
                  options={languageTestOptions}
                  className="mt-2"
                  classNamePrefix="react-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: '100%',
                      padding: '0.5rem',
                      borderColor: 'gray-300',
                      borderRadius: '0.375rem', // rounded-md equivalent
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 50, // Ensure it appears above other content
                    }),
                  }}
                  placeholder={t('select_language_test')}
                />
              </div>
            )}

            {/* Conditional fields for Language test */}
            {renderLanguageFields()}

            {/* Second Language Test Field */}
            {formData.test_results_within_two_years === 'Yes' && (
              <div>
                <label htmlFor="second_language_test" className="block text-base sm:text-lg font-medium">
                  {t('second_language_test')}
                </label>
                <Select
                  id="second_language_test"
                  name="second_language_test"
                  value={renderSecondLanguageOptions().find(option => option.value === formData.second_language_test)}
                  onChange={(selectedOption) => handleChange({
                    target: {
                      name: "second_language_test",
                      value: selectedOption ? selectedOption.value : ""
                    }
                  })}
                  options={renderSecondLanguageOptions()}
                  className="mt-2"
                  classNamePrefix="react-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: '100%',
                      padding: '0.5rem',
                      borderColor: 'gray-300',
                      borderRadius: '0.375rem', // rounded-md equivalent
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 50, // Ensure it appears above other content
                    }),
                  }}
                  placeholder={t('select_language_test')}
                  isClearable
                />
              </div>
            )}


            {/* Conditional fields for Second language test */}
            {renderSecondLanguageFields()}

            {/* Canadian Work Experience Field */}
            <div>
              <label htmlFor="canadian_work_experience" className="block text-base sm:text-lg font-medium">
                {t('canadian_work_experience')}
              </label>
              <Select
                id="canadian_work_experience"
                name="canadian_work_experience"
                value={canadianWorkExperienceOptions.find(option => option.value === formData.canadian_work_experience)}
                onChange={(selectedOption) => handleChange({ target: { name: 'canadian_work_experience', value: selectedOption.value } })}
                options={canadianWorkExperienceOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Foreign Work Experience Field */}
            <div>
              <label htmlFor="foreign_work_experience" className="block text-base sm:text-lg font-medium">
                {t('foreign_work_experience')}
              </label>
              <Select
                id="foreign_work_experience"
                name="foreign_work_experience"
                value={foreignWorkExperienceOptions.find(option => option.value === formData.foreign_work_experience)}
                onChange={(selectedOption) => handleChange({ target: { name: 'foreign_work_experience', value: selectedOption.value } })}
                options={foreignWorkExperienceOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Certification of Qualification */}
            <div>
              <label htmlFor="certificate_of_qualification" className="block text-base sm:text-lg font-medium">
                {t('certificate_of_qualification')}
              </label>
              <Select
                id="certificate_of_qualification"
                name="certificate_of_qualification"
                value={certificateOfQualificationOptions.find(option => option.value === formData.certificate_of_qualification)}
                onChange={(selectedOption) => handleChange({ target: { name: 'certificate_of_qualification', value: selectedOption.value } })}
                options={certificateOfQualificationOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Job offer with LMIA */}
            <div>
              <label htmlFor="job_offer" className="block text-base sm:text-lg font-medium">
                {t('job_offer')}
              </label>
              <Select
                id="job_offer"
                name="job_offer"
                value={jobOfferOptions.find(option => option.value === formData.job_offer)}
                onChange={(selectedOption) => handleChange({ target: { name: 'job_offer', value: selectedOption.value } })}
                options={jobOfferOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>


            {/* Job offer NOC TEER */}
            {formData.job_offer === 'Yes' && (
              <div>
                <label htmlFor="job_offer_noc_teer" className="block text-base sm:text-lg font-medium">
                  {t('job_offer_noc_teer')}
                </label>
                <Select
                  id="job_offer_noc_teer"
                  name="job_offer_noc_teer"
                  value={jobOfferNocTeerOptions.find(option => option.value === formData.job_offer_noc_teer)}
                  onChange={(selectedOption) => handleChange({ target: { name: 'job_offer_noc_teer', value: selectedOption.value } })}
                  options={jobOfferNocTeerOptions}
                  className="mt-2"
                  classNamePrefix="react-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: '100%',
                      padding: '0.5rem',
                      borderColor: 'gray-300',
                      borderRadius: '0.375rem',
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 50,
                    }),
                  }}
                  placeholder={t('select_an_option')}
                />
              </div>
            )}

            {/* PNP Nomination */}
            <div>
              <label htmlFor="nomination_certificate" className="block text-base sm:text-lg font-medium">
                {t('nomination_certificate')}
              </label>
              <Select
                id="nomination_certificate"
                name="nomination_certificate"
                value={nominationCertificateOptions.find(option => option.value === formData.nomination_certificate)}
                onChange={(selectedOption) => handleChange({ target: { name: 'nomination_certificate', value: selectedOption.value } })}
                options={nominationCertificateOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Sibling in Canada */}
            <div>
              <label htmlFor="sibling_in_canada" className="block text-base sm:text-lg font-medium">
                {t('sibling_in_canada')}
              </label>
              <Select
                id="sibling_in_canada"
                name="sibling_in_canada"
                value={siblingInCanadaOptions.find(option => option.value === formData.sibling_in_canada)}
                onChange={(selectedOption) => handleChange({ target: { name: 'sibling_in_canada', value: selectedOption.value } })}
                options={siblingInCanadaOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Spouse Education Field */}
            {(formData.marital_status === 'Married' || formData.marital_status === 'Common-Law') &&
              formData.spouse_citizen_or_pr === 'No' && formData.spouse_coming_with === 'Yes' && (
                <div>
                  <label htmlFor="spouse_education" className="block text-base sm:text-lg font-medium">
                    {t('spouse_education_question')}
                  </label>
                  <Select
                    id="spouse_education"
                    name="spouse_education"
                    value={spouseEducationOptions.find(option => option.value === formData.spouse_education)}
                    onChange={(selectedOption) => handleChange({ target: { name: 'spouse_education', value: selectedOption.value } })}
                    options={spouseEducationOptions}
                    className="mt-2"
                    classNamePrefix="react-select"
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: '100%',
                        padding: '0.5rem',
                        borderColor: 'gray-300',
                        borderRadius: '0.375rem',
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 50,
                      }),
                    }}
                    placeholder={t('select_an_option')}
                  />
                </div>
              )}

            {/* Spouse Canadian Work Experience Field */}
            {(formData.marital_status === 'Married' || formData.marital_status === 'Common-Law') &&
              formData.spouse_citizen_or_pr === 'No' && formData.spouse_coming_with === 'Yes' && (
                <div>
                  <label htmlFor="spouse_canadian_work_experience" className="block text-base sm:text-lg font-medium">
                    {t('spouse_canadian_work_experience_question')}
                  </label>
                  <Select
                    id="spouse_canadian_work_experience"
                    name="spouse_canadian_work_experience"
                    value={spouseCanadianWorkExperienceOptions.find(option => option.value === formData.spouse_canadian_work_experience)}
                    onChange={(selectedOption) => handleChange({ target: { name: 'spouse_canadian_work_experience', value: selectedOption.value } })}
                    options={spouseCanadianWorkExperienceOptions}
                    className="mt-2"
                    classNamePrefix="react-select"
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: '100%',
                        padding: '0.5rem',
                        borderColor: 'gray-300',
                        borderRadius: '0.375rem',
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 50,
                      }),
                    }}
                    placeholder={t('select_an_option')}
                  />
                </div>
              )}

            {/* Spouse Language Test Field */}
            {(formData.marital_status === 'Married' || formData.marital_status === 'Common-Law') &&
              formData.spouse_citizen_or_pr === 'No' && formData.spouse_coming_with === 'Yes' && (
                <div>
                  <label htmlFor="spouse_language_test" className="block text-base sm:text-lg font-medium">
                    {t('spouse_language_test')}
                  </label>
                  <Select
                    id="spouse_language_test"
                    name="spouse_language_test"
                    value={languageTestOptions.find(option => option.value === formData.spouse_language_test)}
                    onChange={(selectedOption) => handleChange({
                      target: {
                        name: "spouse_language_test",
                        value: selectedOption ? selectedOption.value : ""
                      }
                    })}
                    options={languageTestOptions}
                    className="mt-2"
                    classNamePrefix="react-select"
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: '100%',
                        padding: '0.5rem',
                        borderColor: 'gray-300',
                        borderRadius: '0.375rem', // rounded-md equivalent
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 50, // Ensure it appears above other content
                      }),
                    }}
                    placeholder={t('select_language_test')}
                    isClearable
                  />
                </div>

              )}

            {/* Conditional fields for Spouse Language test */}
            {renderSpouseLanguageFields()}




            {/* Submit button */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-red-600 text-white px-6 py-3 sm:px-8 sm:py-4 md:px-10 md:py-5 rounded-md text-base sm:text-lg md:text-xl hover:bg-red-700"
              >
                {t('submit')}
              </button>
            </div>
          </form>
        </section>

        {/* Results Section */}
        <section className="bg-white p-8 rounded-md shadow-md">
          <div className="bg-red-100 text-red-600 p-2 sm:p-4 rounded-md shadow-sm text-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold">{t('crs_score')}</h2>
          </div>


          {responseData.crs_score !== null && (
            <div className="mt-6 flex flex-col justify-center items-center">
              <div className="flex items-center justify-center bg-red-50 text-green-600 font-bold text-8xl px-6 py-4 rounded-full border-4 border-green-600 shadow-md">
                <FaCheckCircle className="text-green-600 text-4xl mr-4" />
                {responseData.crs_score}
              </div>

              {/* Congrats Message */}
              {responseData.congrats_message == 1 && (
                <div className="bg-red-50 text-black p-4 rounded-md shadow-md text-center mt-4 flex items-center">
                  <img
                    src="/images/firework.png"
                    alt="Fireworks"
                    className="w-10 h-10 mr-2"
                  />
                  <p className="text-lg leading-relaxed">{t('congrats_message')}</p>
                </div>
              )}
            </div>
          )}

          {responseData.crs_score !== null && (
            <div className="mt-8">
              {/* Tips Section */}
              <div className="mt-8">
                <h4 className="text-2xl font-semibold">{t('optimization_tips')}</h4>
                {/* Use the TipsSlider component and pass the necessary props */}
                <TipsSlider responseData={responseData} tipsData={tipsData} t={t} />
              </div>

            </div>
          )}

          {responseData.crs_score === null && (
            <p className="mt-4 text-center text-lg text-gray-600">{t('guidance_crs')}</p>
          )}
        </section>






      </div>
    </div>
  );
};

export default CRSCalculator;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp, FaFacebookMessenger, FaEnvelope, FaPhone } from 'react-icons/fa';
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    from_name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_51qt705', 'template_1abqvru', e.target, 'xon4wL9Y__jg9yfOG')
      .then((result) => {
        console.log(result.text);
        alert(t('message_sent_successfully'));
      }, (error) => {
        console.log(error.text);
        alert(t('failed_to_send_message'));
      });
    e.target.reset();
  };

  return (
    <div className="p-4 sm:p-6 bg-gray-50">
      {/* Heading */}
      <h1 className="text-xl sm:text-2xl md:text-3xl font-extrabold text-center text-red-600 tracking-wide">{t('contact_us_title')}</h1>
      <div className="w-16 sm:w-20 h-1 bg-red-600 mx-auto my-4 sm:my-6 rounded-full"></div>

      {/* Flexbox container for form and contact methods */}
      <div className="flex flex-col lg:flex-row justify-center gap-8 sm:gap-12 mt-6">

        {/* Contact Form */}
        <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg max-w-full lg:max-w-lg w-full">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-700 mb-4 text-center">{t('contact_form_title')}</h2>

          {/* Image Below Title */}
          <div className="flex justify-center mb-4">
            <img
              src="/images/collaboration.png"
              alt="Collaboration"
              className="w-20 sm:w-28 h-20 sm:h-28 rounded-lg"
            />
          </div>

          <form onSubmit={sendEmail} className="space-y-4 sm:space-y-6">
            <div>
              <label htmlFor="from_name" className="block text-gray-600 font-semibold mb-1">{t('contact_form_name')}</label>
              <input
                type="text"
                id="from_name"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-200"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-gray-600 font-semibold mb-1">{t('contact_form_email')}</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-200"
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-gray-600 font-semibold mb-1">{t('contact_form_message')}</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-200"
                rows="4"
              ></textarea>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-red-600 text-white font-semibold py-2 px-6 rounded-lg hover:bg-red-700 transition duration-300 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                {t('contact_form_submit')}
              </button>
            </div>
          </form>
        </div>

        {/* Contact Methods Section */}
        <div className="grid grid-cols-1 gap-6 lg:max-w-sm w-full">
          {/* WhatsApp */}
          <a
            href="https://wa.me/+17059054026"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white p-4 sm:p-6 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:bg-red-100 transition-transform duration-300 transform hover:scale-105"
          >
            <FaWhatsapp className="text-green-600 text-4xl sm:text-6xl mb-3" />
            <h3 className="text-base sm:text-lg font-semibold text-gray-700">{t('contact_whatsapp')}</h3>
            <p className="text-gray-500 mt-1 sm:mt-2 text-sm">{t('contact_whatsapp_text')}</p>
          </a>

          {/* Facebook Messenger */}
          <a
            href="https://m.me/fb.canada.entry"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white p-4 sm:p-6 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:bg-red-100 transition-transform duration-300 transform hover:scale-105"
          >
            <FaFacebookMessenger className="text-blue-600 text-4xl sm:text-6xl mb-3" />
            <h3 className="text-base sm:text-lg font-semibold text-gray-700">{t('contact_facebook')}</h3>
            <p className="text-gray-500 mt-1 sm:mt-2 text-sm">{t('contact_facebook_text')}</p>
          </a>

          {/* Email */}
          <a
            href="mailto:contact.canadaentry@gmail.com"
            className="bg-white p-4 sm:p-6 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:bg-red-100 transition-transform duration-300 transform hover:scale-105"
          >
            <FaEnvelope className="text-red-600 text-4xl sm:text-6xl mb-3" />
            <h3 className="text-base sm:text-lg font-semibold text-gray-700">{t('contact_email')}</h3>
            <p className="text-gray-500 mt-1 sm:mt-2 text-sm">
              {t('contact_email_text')}
              <strong className="block font-bold text-gray-800 mt-1">contact.canadaentry@gmail.com</strong>
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PermanentResident = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 p-4 md:p-8">
      {/* Hero Section - Introduction */}
      <section className="bg-white p-6 md:p-8 rounded-md shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 items-center">
          <img
            src="/images/canada-vietnam-flag.png" 
            alt={t('permanent_resident')}
            className="w-full h-auto max-w-[180px] md:max-w-xs mx-auto"
          />
          <div>
            <h1 className="text-2xl md:text-3xl font-bold text-red-600">{t('permanent_resident')}</h1>
            <p className="text-base md:text-lg mt-4">{t('guidance_permanent_resident')}</p>
          </div>
        </div>
      </section>

      {/* Express Entry, PNP, Quebec Programs, Sponsorship Sections with Guidance */}
      <section className="bg-white p-6 md:p-8 rounded-md shadow-md mt-6 md:mt-8">
        <h2 className="text-2xl md:text-3xl font-semibold text-red-600">{t('guidance')}</h2>
        <p className="mt-4 text-base md:text-lg">{t('guidance_intro')}</p>

        {/* Express Entry, PNP, Quebec Programs, Sponsorship Cards */}
        <div className="mt-4 flex flex-col md:flex-row flex-wrap gap-4 md:gap-6">
          {/* Express Entry Section */}
          <div className="flex-1 p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('express_entry')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('express_entry_description')}</p>
            </div>
            <div className="mt-6 md:mt-8">
              <Link
                to="/permanent-resident/express-entry"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>

          {/* PNP Section */}
          <div className="flex-1 p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('pnp')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('pnp_description')}</p>
            </div>
            <div className="mt-6 md:mt-8">
              <Link
                to="/permanent-resident/pnp"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>

          {/* Quebec Programs Section */}
          <div className="flex-1 p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('quebec_programs')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('quebec_programs_description')}</p>
            </div>
            <div className="mt-6 md:mt-8">
              <Link
                to="/permanent-resident/quebec"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>

          {/* Sponsorship Section */}
          <div className="flex-1 p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('sponsorship')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('sponsorship_description')}</p>
            </div>
            <div className="mt-6 md:mt-8">
              <Link
                to="/permanent-resident/sponsorship"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>

          {/* Start-up Section */}
          <div className="flex-1 p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('startup_visa')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('startup_visa_description')}</p>
            </div>
            <div className="mt-6 md:mt-8">
              <Link
                to="/permanent-resident/startup-visa"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PermanentResident;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const QuebecSkilledWorker = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('qswp_heading')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('overview_of_program')}
              </h3>
              <p className="text-gray-700">{t('qswp_overview_description')}</p>
              <strong><p className="text-blue-500 mt-2">⛔️ {t('qswp_suspension_notice')}</p></strong>
            </div>
          </div>

          {/* Step 2: Eligibility */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('eligibility')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('qswp_age_title')}</strong>: {t('qswp_age_description')}</li>
                <li><strong>{t('qswp_education_title')}</strong>: {t('qswp_education_description')}</li>
                <li><strong>{t('qswp_experience_title')}</strong>: {t('qswp_experience_description')}</li>
                <li><strong>{t('qswp_language_proficiency_title')}</strong>: {t('qswp_language_proficiency_description')}</li>
                <li><strong>{t('qswp_financial_title')}</strong>: {t('qswp_financial_description')}</li>
                <li><strong>{t('qswp_other_factors_title')}</strong>: {t('qswp_other_factors_description')}</li>
              </ul>
            </div>
          </div>

          {/* Step 3: Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-6">
                {t('qswp_application_process_title')}
              </h3>

              {/* Step 3.1: Eligibility Check */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('qswp_eligibility_check_title')}
                </h4>
                <p className="text-gray-700">{t('qswp_eligibility_check_assess')}</p>
              </div>

              {/* Step 3.2: Submit an EOI */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('qswp_submit_eoi_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('qswp_submit_eoi_create_account')}</li>
                  <li>{t('qswp_submit_eoi_interest')}</li>
                  <li>
                    {t('qswp_submit_eoi_information')}
                    <ul className="list-disc list-inside ml-4">
                      <li>{t('qswp_submit_eoi_personal_info')}</li>
                      <li>{t('qswp_submit_eoi_contact_info')}</li>
                      <li>{t('qswp_submit_eoi_family_situation')}</li>
                      <li>{t('qswp_submit_eoi_status_in_quebec')}</li>
                      <li>{t('qswp_submit_eoi_education')}</li>
                      <li>{t('qswp_submit_eoi_work_experience')}</li>
                      <li>{t('qswp_submit_eoi_job_offer')}</li>
                      <li>{t('qswp_submit_eoi_language_skills')}</li>
                      <li>{t('qswp_submit_eoi_other_info')}</li>
                    </ul>
                  </li>
                </ul>
              </div>

              {/* Step 3.3: Applying for Permanent Selection */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('qswp_apply_permanent_selection_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('qswp_apply_permanent_selection_invitation')}</li>
                  <li>{t('qswp_apply_permanent_selection_application')}</li>
                </ul>
              </div>

              {/* Step 3.4: Pay Fees */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('qswp_pay_fees_title')}
                </h4>
                <p className="text-gray-700">{t('qswp_pay_fees_processing')}</p>
              </div>

              {/* Step 3.5: Application Review */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('qswp_application_review_title')}
                </h4>
                <p className="text-gray-700">{t('qswp_application_review_description')}</p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('qswp_application_review_certificate')}</li>
                  <li>{t('qswp_application_review_next')}</li>
                </ul>
              </div>

              {/* Step 3.6: Federal Application */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('qswp_federal_application_title')}
                </h4>
                <p className="text-gray-700">{t('qswp_federal_application_apply')}</p>
                
              </div>

              {/* Step 3.7: Biometrics and Additional Checks */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('qswp_biometrics_checks_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('qswp_biometrics_checks_submit')}</li>
                  <li>{t('qswp_biometrics_checks_medical')}</li>
                </ul>
              </div>

              {/* Step 3.8: Final Decision */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('qswp_final_decision_title')}
                </h4>
                <p className="text-gray-700">{t('qswp_final_decision_residence')}</p>
                
              </div>
            </div>
          </div>


          {/* Step 4: Pros & Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('pros_cons')}</h3>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">{t('pros')}</h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('pro_1')}</li>
                <li>{t('pro_2')}</li>
                <li>{t('pro_3')}</li>
              </ul>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">{t('cons')}</h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('con_1')}</li>
                <li>{t('con_2')}</li>
                <li>{t('con_3')}</li>
              </ul>
            </div>
          </div>
          {/* Step 5: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('next_actions')}</h3>
              <p className="text-gray-700 mb-4">{t('next_actions_quebec_description')}</p>
              <Link
                to="https://www.quebec.ca/en/immigration/permanent"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
              >
                {t('learn_more_about_quebec')} →
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default QuebecSkilledWorker;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Saskatchewan = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('sk_program_title')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Programs */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('sk_overview_title')}
              </h3>
              <p className="text-gray-700">{t('sk_overview_intro')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('sk_overview_international_skilled_worker')}</strong> {t('sk_overview_international_skilled_worker_description')}
                </li>
                <li>
                  <strong>{t('sk_overview_saskatchewan_experience')}</strong> {t('sk_overview_saskatchewan_experience_description')}
                </li>
                <li>
                  <strong>{t('sk_overview_entrepreneur')}</strong> {t('sk_overview_entrepreneur_description')}
                </li>
                <li>
                  <strong>{t('sk_overview_farm_owner_operator')}</strong> {t('sk_overview_farm_owner_operator_description')}
                </li>
              </ul>
            </div>
          </div>

          {/* Step 2: Eligibility Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('sk_requirements_title')}
              </h3>
              {/* Saskatchewan International Skilled Worker */}
              <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm border-l-4 border-green-500">
                <h4 className="text-xl font-bold text-green-600 mb-4 flex items-center">
                  <span className="mr-2">🌍</span>{t('sk_saskatchewan_skilled_worker_title')}
                </h4>
                <ul className="list-inside ml-6 text-gray-700 space-y-4">

                  {/* Employment Offer Sub-Category */}
                  <li>
                    <h5 className="text-lg font-semibold text-green-600 flex items-center mb-2">
                      <span className="mr-2">📄</span>{t('sk_employment_offer_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_employment_offer_job_offer_title')}</strong>: {t('sk_employment_offer_job_offer_description')}</li>
                      <li><strong>{t('sk_employment_offer_language_title')}</strong>: {t('sk_employment_offer_language_description')}</li>
                      <li><strong>{t('sk_employment_offer_education_title')}</strong>: {t('sk_employment_offer_education_description')}</li>
                    </ul>
                  </li>

                  {/* Agriculture Talent Pathway */}
                  <li>
                    <h5 className="text-lg font-semibold text-green-600 flex items-center mb-2">
                      <span className="mr-2">🚜</span>{t('sk_agriculture_talent_pathway_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_agriculture_job_offer_title')}</strong>: {t('sk_agriculture_job_offer_description')}</li>

                      {/* Work Experience */}
                      <li>
                        <strong>{t('sk_agriculture_work_experience_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_agriculture_work_experience_current_description')}</li>
                          <li>{t('sk_agriculture_work_experience_non_current_description')}</li>
                        </ul>
                      </li>

                      <li><strong>{t('sk_agriculture_language_title')}</strong>: {t('sk_agriculture_language_description')}</li>
                      <li><strong>{t('sk_agriculture_legal_status_title')}</strong>: {t('sk_agriculture_legal_status_description')}</li>
                      <li><strong>{t('sk_agriculture_intent_to_reside_title')}</strong>: {t('sk_agriculture_intent_to_reside_description')}</li>

                      {/* Occupation List */}
                      <li>
                        <strong>{t('sk_agriculture_occupation_list_title')}</strong>: {t('sk_agriculture_occupation_list_description')}
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_agriculture_occupation_material_handlers')}</li>
                          <li>{t('sk_agriculture_occupation_specialized_livestock')}</li>
                          <li>{t('sk_agriculture_occupation_livestock_labourers')}</li>
                          <li>{t('sk_agriculture_occupation_harvesting_labourers')}</li>
                          <li>{t('sk_agriculture_occupation_nursery_greenhouse')}</li>
                          <li>{t('sk_agriculture_occupation_process_control')}</li>
                          <li>{t('sk_agriculture_occupation_industrial_butchers')}</li>
                          <li>{t('sk_agriculture_occupation_testers_graders')}</li>
                          <li>{t('sk_agriculture_occupation_assemblers_inspectors')}</li>
                          <li>{t('sk_agriculture_occupation_labourers_food_beverage')}</li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  {/* Health Talent Pathway */}
                  <li>
                    <h5 className="text-lg font-semibold text-green-600 flex items-center mb-2">
                      <span className="mr-2">💊</span>{t('sk_health_talent_pathway_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_health_job_offer_title')}</strong>: {t('sk_health_job_offer_description')}</li>
                      <li><strong>{t('sk_health_language_title')}</strong>: {t('sk_health_language_description')}</li>

                      {/* Work Experience */}
                      <li>
                        <strong>{t('sk_health_work_experience_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_health_work_experience_saskatchewan')}</li>
                          <li>{t('sk_health_work_experience_elsewhere')}</li>
                        </ul>
                      </li>

                      <li><strong>{t('sk_health_education_title')}</strong>: {t('sk_health_education_description')}</li>
                    </ul>
                  </li>

                  {/* Tech Talent Pathway */}
                  <li>
                    <h5 className="text-lg font-semibold text-green-600 flex items-center mb-2">
                      <span className="mr-2">💻</span>{t('sk_tech_talent_pathway_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_tech_job_offer_title')}</strong>: {t('sk_tech_job_offer_description')}</li>
                      <li><strong>{t('sk_tech_language_title')}</strong>: {t('sk_tech_language_description')}</li>

                      {/* Work Experience */}
                      <li>
                        <strong>{t('sk_tech_work_experience_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_tech_work_experience_outside')}</li>
                          <li>{t('sk_tech_work_experience_current')}</li>
                        </ul>
                      </li>

                      <li><strong>{t('sk_tech_education_title')}</strong>: {t('sk_tech_education_description')}</li>
                      <li><strong>{t('sk_tech_licensing_title')}</strong>: {t('sk_tech_licensing_description')}</li>
                    </ul>
                  </li>

                  {/* Occupations In-Demand Sub-Category */}
                  <li>
                    <h5 className="text-lg font-semibold text-green-600 flex items-center mb-2">
                      <span className="mr-2">📈</span>{t('sk_occupations_in_demand_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_occupations_in_demand_work_experience_title')}</strong>: {t('sk_occupations_in_demand_work_experience_description')}</li>
                      <li><strong>{t('sk_occupations_in_demand_language_title')}</strong>: {t('sk_occupations_in_demand_language_description')}</li>
                      <li><strong>{t('sk_occupations_in_demand_education_title')}</strong>: {t('sk_occupations_in_demand_education_description')}</li>
                      <li><strong>{t('sk_occupations_in_demand_legal_status_title')}</strong>: {t('sk_occupations_in_demand_legal_status_description')}</li>
                      <li><strong>{t('sk_occupations_in_demand_settlement_funds_title')}</strong>: {t('sk_occupations_in_demand_settlement_funds_description')}</li>
                    </ul>
                  </li>

                  {/* Express Entry Sub-Category */}
                  <li>
                    <h5 className="text-lg font-semibold text-green-600 flex items-center mb-2">
                      <span className="mr-2">🚀</span>{t('sk_express_entry_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_express_entry_profile_title')}</strong>: {t('sk_express_entry_profile_description')}</li>
                      <li><strong>{t('sk_express_entry_work_experience_title')}</strong>: {t('sk_express_entry_work_experience_description')}</li>
                      <li><strong>{t('sk_express_entry_language_title')}</strong>: {t('sk_express_entry_language_description')}</li>
                      <li><strong>{t('sk_express_entry_education_title')}</strong>: {t('sk_express_entry_education_description')}</li>
                      <li><strong>{t('sk_express_entry_settlement_funds_title')}</strong>: {t('sk_express_entry_settlement_funds_description')}</li>
                    </ul>
                  </li>
                </ul>
              </div>

              {/* Saskatchewan Experience */}
              <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm border-l-4 border-purple-500">
                <h4 className="text-xl font-bold text-purple-600 mb-4 flex items-center">
                  <span className="mr-2">🛠️</span>{t('sk_exp_saskatchewan_experience_title')}
                </h4>
                <ul className="list-inside ml-6 text-gray-700 space-y-4">

                  {/* Skilled Worker With Existing Work Permit */}
                  <li>
                    <h5 className="text-lg font-semibold text-purple-600 flex items-center mb-2">
                      <span className="mr-2">💼</span>{t('sk_exp_existing_work_permit_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_exp_existing_work_permit_validity_title')}</strong>: {t('sk_exp_existing_work_permit_validity_description')}</li>
                      <li><strong>{t('sk_exp_existing_work_permit_experience_title')}</strong>: {t('sk_exp_existing_work_permit_experience_description')}</li>
                      <li><strong>{t('sk_exp_existing_work_permit_language_title')}</strong>: {t('sk_exp_existing_work_permit_language_description')}</li>
                    </ul>
                  </li>

                  {/* Agriculture Talent Pathway */}
                  <li>
                    <h5 className="text-lg font-semibold text-purple-600 flex items-center mb-2">
                      <span className="mr-2">🚜</span>{t('sk_agriculture_talent_pathway_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_exp_existing_work_permit_occupation_title')}</strong>: {t('sk_exp_existing_work_permit_occupation_description')}</li>
                      <li><strong>{t('sk_exp_existing_work_permit_six_months_experience_title')}</strong>: {t('sk_exp_existing_work_permit_six_months_experience_description')}</li>
                      <li><strong>{t('sk_exp_existing_work_permit_language_score_title')}</strong>: {t('sk_exp_existing_work_permit_language_score_description')}</li>
                      <li><strong>{t('sk_exp_existing_work_permit_education_title')}</strong>: {t('sk_exp_existing_work_permit_education_description')}</li>
                    </ul>
                  </li>

                  {/* Health Talent Pathway */}
                  <li>
                    <h5 className="text-lg font-semibold text-purple-600 flex items-center mb-2">
                      <span className="mr-2">💊</span>{t('sk_exp_health_talent_pathway_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_exp_health_job_offer_title')}</strong>: {t('sk_exp_health_job_offer_description')}</li>
                      <li><strong>{t('sk_exp_health_language_title')}</strong>: {t('sk_exp_health_language_description')}</li>
                      <li><strong>{t('sk_exp_health_education_title')}</strong>: {t('sk_exp_health_education_description')}</li>
                    </ul>
                  </li>

                  {/* Students who have graduated from a Saskatchewan institute */}
                  <li>
                    <h5 className="text-lg font-semibold text-purple-600 flex items-center mb-2">
                      <span className="mr-2">🎓</span>{t('sk_exp_saskatchewan_graduate_title')}
                    </h5>

                    {/* Educational Requirement */}
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>
                        <strong>{t('sk_exp_graduate_education_requirement_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_exp_graduate_education_requirement_description')}</li>
                        </ul>
                      </li>

                      {/* Work Experience */}
                      <li>
                        <strong>{t('sk_exp_graduate_work_experience_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_exp_graduate_work_experience_description')}</li>
                          <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                            <li>{t('sk_exp_graduate_work_experience_on_campus')}</li>
                            <li>{t('sk_exp_graduate_work_experience_coop_terms')}</li>
                            <li>{t('sk_exp_graduate_work_experience_fellowships')}</li>
                            <li>{t('sk_exp_graduate_work_experience_post_graduation')}</li>
                          </ul>
                        </ul>
                      </li>

                      {/* Work Permit */}
                      <li>
                        <strong>{t('sk_exp_graduate_work_permit_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_exp_graduate_work_permit_description')}</li>
                        </ul>
                      </li>

                      {/* Job Offer */}
                      <li>
                        <strong>{t('sk_exp_graduate_job_offer_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_exp_graduate_job_offer_description')}</li>
                          <li>{t('sk_exp_graduate_job_offer_noc_description')}</li>
                          <li>{t('sk_exp_graduate_job_offer_field_related')}</li>
                          <li>{t('sk_exp_graduate_job_offer_note')}</li>
                        </ul>
                      </li>

                      {/* SINP Job Approval Letter */}
                      <li><strong>{t('sk_exp_sinp_approval_letter_title')}</strong>: {t('sk_exp_sinp_approval_letter_description')}</li>
                      <li><strong>{t('sk_exp_sinp_language_proficiency_title')}</strong>: {t('sk_exp_sinp_language_proficiency_description')}</li>
                    </ul>
                  </li>
                  {/* Students who have graduated from an institute in Canada (outside of Saskatchewan) */}
                  <li>
                    <h5 className="text-lg font-semibold text-purple-600 flex items-center mb-2">
                      <span className="mr-2">🎓</span>{t('sk_exp_outside_graduate_title')}
                    </h5>

                    {/* Educational Requirement */}
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>
                        <strong>{t('sk_exp_outside_graduate_education_requirement_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_exp_outside_graduate_education_requirement_description')}</li>
                        </ul>
                      </li>

                      {/* Work Permit */}
                      <li>
                        <strong>{t('sk_exp_outside_graduate_work_permit_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_exp_outside_graduate_work_permit_description')}</li>
                        </ul>
                      </li>

                      {/* Post-Graduation Work Experience in Saskatchewan */}
                      <li>
                        <strong>{t('sk_exp_outside_graduate_post_graduation_work_experience_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_exp_outside_graduate_post_graduation_work_experience_description')}</li>
                        </ul>
                      </li>

                      {/* Job Offer */}
                      <li>
                        <strong>{t('sk_exp_outside_graduate_job_offer_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_exp_outside_graduate_job_offer_description')}</li>
                          <li>{t('sk_exp_outside_graduate_job_offer_noc_description')}</li>
                          <li>{t('sk_exp_outside_graduate_job_offer_exceptions')}</li>
                        </ul>
                      </li>

                      {/* SINP Job Approval Letter */}
                      <li><strong>{t('sk_exp_sinp_job_approval_letter_title')}</strong>: {t('sk_exp_sinp_job_approval_letter_description')}</li>
                      <li><strong>{t('sk_exp_language_proficiency_title')}</strong>: {t('sk_exp_language_proficiency_description')}</li>
                    </ul>
                  </li>

                  {/* Entrepreneur */}
                  <li>
                    <h5 className="text-lg font-semibold text-purple-600 flex items-center mb-2">
                      <span className="mr-2">🏢</span>{t('sk_exp_entrepreneur_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_exp_entrepreneur_net_worth_title')}</strong>: {t('sk_exp_entrepreneur_net_worth_description')}</li>
                      <li><strong>{t('sk_exp_entrepreneur_investment_title')}</strong>:
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('sk_exp_entrepreneur_investment_regina')}</li>
                          <li>{t('sk_exp_entrepreneur_investment_other')}</li>
                        </ul>
                      </li>
                      <li><strong>{t('sk_exp_entrepreneur_business_plan_title')}</strong>: {t('sk_exp_entrepreneur_business_plan_description')}</li>
                      <li><strong>{t('sk_exp_entrepreneur_residency_title')}</strong>: {t('sk_exp_entrepreneur_residency_description')}</li>
                      <li><strong>{t('sk_exp_entrepreneur_points_assessment_title')}</strong>: {t('sk_exp_entrepreneur_points_assessment_description')}</li>
                    </ul>
                  </li>

                  {/* Farm Owner and Operator */}
                  <li>
                    <h5 className="text-lg font-semibold text-purple-600 flex items-center mb-2">
                      <span className="mr-2">🚜</span>{t('sk_exp_farm_owner_operator_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('sk_exp_farm_owner_net_worth_title')}</strong>: {t('sk_exp_farm_owner_net_worth_description')}</li>
                      <li><strong>{t('sk_exp_farm_owner_farming_experience_title')}</strong>: {t('sk_exp_farm_owner_farming_experience_description')}</li>
                      <li><strong>{t('sk_exp_farm_owner_points_assessment_title')}</strong>: {t('sk_exp_farm_owner_points_assessment_description')}</li>
                      <li><strong>{t('sk_exp_farm_owner_business_plan_title')}</strong>: {t('sk_exp_farm_owner_business_plan_description')}</li>
                      <li><strong>{t('sk_exp_farm_owner_good_faith_deposit_title')}</strong>: {t('sk_exp_farm_owner_good_faith_deposit_description')}</li>
                      <li><strong>{t('sk_exp_farm_owner_language_proficiency_title')}</strong>: {t('sk_exp_farm_owner_language_proficiency_description')}</li>
                      <li><strong>{t('sk_exp_farm_owner_residency_title')}</strong>: {t('sk_exp_farm_owner_residency_description')}</li>
                    </ul>
                  </li>
                </ul>
              </div>



            </div>
          </div>

          {/* Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-6">
                {t('sk_app_application_process_title')}
              </h3>

              {/* Step 1: Choose a Stream */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2 mt-6">
                  {t('sk_app_step1_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('sk_app_step1_decide_stream_title')}</strong> {t('sk_app_step1_decide_stream_description')}
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('sk_app_step1_international_skilled_worker')}</li>
                  <li>{t('sk_app_step1_saskatchewan_experience')}</li>
                  <li>{t('sk_app_step1_entrepreneur_farm')}</li>
                </ul>
              </div>

              {/* Step 2: Register and Submit Expression of Interest (EOI) */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('sk_app_step2_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li><strong>{t('sk_app_step2_create_profile_title')}</strong> {t('sk_app_step2_create_profile_description')}</li>
                  <li>{t('sk_app_step2_candidates_high_eoi')}</li>
                </ul>
              </div>

              {/* Step 3: Submit Full Application */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('sk_app_step3_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('sk_app_step3_after_invitation_description')}</li>
                  <li>{t('sk_app_step3_entrepreneur_stream')}</li>
                </ul>
              </div>

              {/* Step 4: Await Nomination */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('sk_app_step4_title')}
                </h4>
                <p className="text-gray-700">
                  {t('sk_app_step4_after_sinp_approval')}
                </p>
              </div>


            </div>
          </div>


          {/* Step 4: Pros & Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('sk_pros_cons_title')}
              </h3>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('sk_pros_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('sk_pros_description_1')}</li>
                <li>{t('sk_pros_description_2')}</li>
              </ul>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('sk_cons_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('sk_cons_description_1')}</li>
                <li>{t('sk_cons_description_2')}</li>
              </ul>
            </div>
          </div>

          {/* Step 5: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('next_actions')}
                </h3>
                <p className="text-gray-700 mb-4">
                  {t('sk_next_actions_description')}
                </p>
                <Link
                  to="https://www.saskatchewan.ca/residents/moving-to-saskatchewan/live-in-saskatchewan/by-immigrating/saskatchewan-immigrant-nominee-program"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
                >
                  {t('sk_learn_more')} →
                </Link>
              </div>

              {/* Right side - Call-to-action button centered */}
              <div className="w-full md:w-1/4 flex justify-center"> {/* Center the button */}
                <Link
                  to="/canada-immigration-support/clb-conversion"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Smaller Image */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-2"  // Smaller image
                  />
                  {t('convert_your_clb_score')}
                </Link>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Saskatchewan;

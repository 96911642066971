import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const StartUpVisa = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('startup_visa_heading')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('startup_overview_title')}
              </h3>
              <p className="text-gray-700">{t('startup_overview_description')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('startup_overview_criterion_1')}</strong>{t('startup_overview_criterion_1_description')}</li>
                <li><strong>{t('startup_overview_criterion_2')}</strong>{t('startup_overview_criterion_2_description')}</li>
                <li><strong>{t('startup_overview_criterion_3')}</strong>{t('startup_overview_criterion_3_description')}</li>
              </ul>
            </div>
          </div>

          {/* Step 2: Eligibility Criteria */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">

              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('startup_eligibility_title')}
              </h3>
              <p className="text-gray-700">{t('startup_eligibility_description')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('startup_qualifying_business_title')}</strong>{t('startup_qualifying_business_description')}</li>
                <li><strong>{t('startup_letter_of_support_title')}</strong>{t('startup_letter_of_support_description')}</li>
                <li><strong>{t('startup_language_requirements_title')}</strong>{t('startup_language_requirements_description')}</li>
                <li><strong>{t('startup_financial_proof_title')}</strong>{t('startup_financial_proof_description')}</li>
              </ul>
            </div>
          </div>

          {/* Step 3: How to Apply */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('how_to_apply')}
              </h3>
              <p className="text-gray-700">{t('how_to_apply_description')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('online_forms_title')}</strong>{t('online_forms_description')}</li>
                <li><strong>{t('language_results_title')}</strong>{t('language_results_description')}</li>
                <li><strong>{t('letter_support_title')}</strong>{t('letter_support_description')}</li>
                <li><strong>{t('financial_proof_title')}</strong>{t('financial_proof_description')}</li>
              </ul>
            </div>
          </div>

          {/* Step 4: Work in Canada */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('work_in_canada_title')}
              </h3>
              <p className="text-gray-700">{t('work_in_canada_description')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('startup_work_in_canada_benefits')}</li>
                <li>{t('startup_settle_proof')}</li>
              </ul>
            </div>
          </div>

          {/* Step 5: Supporting a start-up business */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('startup_supporting_business')}
              </h3>
              <p className="text-gray-700">{t('startup_supporting_business_description')}</p>
            </div>
          </div>

          {/* Step 6: Pros and Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('pros_cons')}
              </h3>

              {/* Pros Section */}
              <h4 className="text-md sm:text-lg font-semibold text-gray-800 mb-2">
                {t('pros')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('startup_pros_residency')}</li>
                <li>{t('startup_pros_business_ecosystem')}</li>
                <li>{t('startup_pros_global_opportunities')}</li>
              </ul>

              {/* Cons Section */}
              <h4 className="text-md sm:text-lg font-semibold text-gray-800 mt-4 mb-2">
                {t('cons')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('startup_cons_long_processing')}</li>
                <li>{t('startup_cons_strict_requirements')}</li>
                <li>{t('startup_cons_business_in_canada')}</li>
              </ul>
            </div>
          </div>


          {/* Step 7: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('next_actions')}</h3>
                <p className="text-gray-700 mb-4">{t('next_actions_startup_description')}</p>
                <Link
                  to="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/start-visa.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
                >
                  {t('learn_more_about_startup')} →
                </Link>
              </div>

              {/* Right side - Button aligned to the left */}
              <div className="w-full md:w-1/4 text-center">
                <Link
                  to="/canada-immigration-support/clb-conversion"
                  target="_blank" // Opens in a new window
                  rel="noopener noreferrer" // Security enhancement
                  className="inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Image at the beginning of the button */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-4"
                  />
                  {t('convert_your_clb_score')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartUpVisa;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Newfoundland = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('nfl_program_title')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Programs */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('nfl_overview_title')}
              </h3>
              <p className="text-gray-700">{t('nfl_overview_intro')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('nfl_overview_skilled_worker')}</strong> {t('nfl_overview_skilled_worker_description')}
                </li>
                <li>
                  <strong>{t('nfl_overview_express_entry')}</strong> {t('nfl_overview_express_entry_description')}
                </li>
                <li>
                  <strong>{t('nfl_overview_international_graduate')}</strong> {t('nfl_overview_international_graduate_description')}
                </li>
                <li>
                  <strong>{t('nfl_overview_international_entrepreneur')}</strong> {t('nfl_overview_international_entrepreneur_description')}
                </li>
                <li>
                  <strong>{t('nfl_overview_international_graduate_entrepreneur')}</strong> {t('nfl_overview_international_graduate_entrepreneur_description')}
                </li>
              </ul>
            </div>
          </div>

          {/* Step 2: Eligibility Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('nfl_requirements_title')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('nfl_requirements_skilled_worker_title')}</strong> {t('nfl_requirements_skilled_worker_description')}
                </li>
                <li>
                  <strong>{t('nfl_requirements_express_entry_title')}</strong> {t('nfl_requirements_express_entry_description')}
                </li>
                <li>
                  <strong>{t('nfl_requirements_international_graduate_title')}</strong> {t('nfl_requirements_international_graduate_description')}
                </li>
                <li>
                  <strong>{t('nfl_requirements_international_entrepreneur_title')}</strong> {t('nfl_requirements_international_entrepreneur_description')}
                </li>
                <li>
                  <strong>{t('nfl_requirements_international_graduate_entrepreneur_title')}</strong> {t('nfl_requirements_international_graduate_entrepreneur_description')}
                </li>
              </ul>
            </div>
          </div>

          {/* Step 3: Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-6">
                {t('nfl_application_process_title')}
              </h3>

              {/* Step 3.1: Choose a Stream */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nfl_choose_stream_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('nfl_choose_stream_skilled_worker')}</li>
                  <li>{t('nfl_choose_stream_express_entry')}</li>
                  <li>{t('nfl_choose_stream_international_graduate')}</li>
                  <li>{t('nfl_choose_stream_international_entrepreneur')}</li>
                  <li>{t('nfl_choose_stream_international_graduate_entrepreneur')}</li>
                </ul>
              </div>

              {/* Step 3.2: Submit Expression of Interest (EOI) */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nfl_submit_eoi_title')}
                </h4>
                <p className="text-gray-700">{t('nfl_submit_eoi_description')}</p>
              </div>

              {/* Step 3.3: Submit Full Application */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nfl_submit_application_title')}
                </h4>
                <p className="text-gray-700">{t('nfl_submit_application_description')}</p>
              </div>

              {/* Step 3.4: Await Nomination */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('nfl_await_nomination_title')}
                </h4>
                <p className="text-gray-700">{t('nfl_await_nomination_description')}</p>
              </div>
            </div>
          </div>

          {/* Step 4: Pros & Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('nfl_pros_cons_title')}
              </h3>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('nfl_pros_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('nfl_pros_description_1')}</li>
                <li>{t('nfl_pros_description_2')}</li>
                <li>{t('nfl_pros_description_3')}</li>
              </ul>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('nfl_cons_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('nfl_cons_description_1')}</li>
                <li>{t('nfl_cons_description_2')}</li>
              </ul>
            </div>
          </div>
          {/* Step 5: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('next_actions')}
                </h3>
                <p className="text-gray-700 mb-4">
                  {t('nfl_next_actions_description')}
                </p>
              </div>

              {/* Right side - Button aligned to the left */}
              <div className="w-full md:w-1/4 text-center">
                <Link
                  to="https://www.gov.nl.ca/immigration/immigrating-to-newfoundland-and-labrador/provincial-nominee-program/overview/"
                  target="_blank" // Opens in a new window
                  rel="noopener noreferrer" // Security enhancement
                  className="inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Image at the beginning of the button */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-4"
                  />
                  {t('nfl_learn_more')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newfoundland;

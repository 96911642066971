import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const TipsSlider = ({ responseData, tipsData, t }) => {
  const [currentSlide, setCurrentSlide] = useState(0); // Keeps track of the current slide
  const tipsPerPage = 6; // Number of tips per slide

  const totalSlides = Math.ceil(responseData.optimization_tips.length / tipsPerPage);

  // Function to navigate to the next slide
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide < totalSlides - 1 ? prevSlide + 1 : prevSlide));
  };

  // Function to navigate to the previous slide
  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide > 0 ? prevSlide - 1 : prevSlide));
  };

  // Determine which tips to show based on the current slide
  const startIndex = currentSlide * tipsPerPage;
  const endIndex = startIndex + tipsPerPage;
  const tipsToShow = responseData.optimization_tips.slice(startIndex, endIndex);

  return (
    <div className="relative p-4 md:p-8 bg-white rounded-lg shadow-lg">
      {/* Tips Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
        {tipsToShow.map((tipIndex, index) => (
          <div
            key={index}
            className="bg-green-50 p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 flex flex-col justify-between"
          >
            {/* Top Content with Quality Icon and Text */}
            <div className="flex items-start mb-4">
              <img
                src="/images/quality.png"
                alt="Quality Icon"
                className="w-8 h-8 md:w-10 md:h-10 mr-4"
              />
              <p className="text-base md:text-lg leading-relaxed">{t(tipsData[tipIndex].summary)}</p>
            </div>

            {/* Button with Placeholder/Icon */}
            <div className="flex items-center justify-start mt-4">
              {/* Placeholder to align with quality icon */}
              <div className="w-8 h-8 md:w-10 md:h-10 mr-4"></div> {/* You can replace this with another image/icon if needed */}

              {/* Conditionally render the Learn More button */}
              {tipsData[tipIndex].ref_link && tipsData[tipIndex].ref_link.trim() !== "" && (
                <Link
                  to={tipsData[tipIndex].ref_link}
                  target="_blank" // Open in a new window
                  rel="noopener noreferrer" // Security enhancement
                  className="bg-green-600 text-white px-3 py-1 md:py-2 rounded-md shadow-md hover:bg-green-400 transition-all duration-300 ease-in-out transform hover:scale-105"
                >
                  {t('learn_more')} →
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Previous and Next buttons with text */}
      <div className="flex justify-between mt-6 items-center space-x-4">
        <button
          onClick={handlePrevSlide}
          disabled={currentSlide === 0}
          className={`${
            currentSlide === 0 ? 'cursor-not-allowed opacity-30' : 'hover:bg-red-700 hover:text-white'
          } p-2 bg-red-600 text-white px-4 md:px-5 rounded-full shadow-md transition-all duration-200 flex items-center`}
        >
          <FaArrowLeft size={20} className="md:text-lg" />
          <span className="ml-2 text-sm md:text-base">{t('previous_tips')}</span>
        </button>

        <button
          onClick={handleNextSlide}
          disabled={currentSlide === totalSlides - 1}
          className={`${
            currentSlide === totalSlides - 1 ? 'cursor-not-allowed opacity-30' : 'hover:bg-red-700 hover:text-white'
          } p-2 bg-red-600 text-white px-4 md:px-5 rounded-full shadow-md transition-all duration-200 flex items-center`}
        >
          <span className="mr-2 text-sm md:text-base">{t('next_tips')}</span>
          <FaArrowRight size={20} className="md:text-lg" />
        </button>
      </div>
    </div>
  );
};

export default TipsSlider;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'; // Import NavLink

const Footer = () => {
  const { t } = useTranslation(); // Access translations using t

  return (
    <footer className="bg-black text-white p-4 text-center">
      <p>&copy; 2024 {t('app_name')}</p> {/* App name dynamically updated */}
      <nav className="flex justify-center space-x-4 mt-2">
        <NavLink 
          to="/privacy" 
          className="hover:text-red-600"
        >
          {t('footer_privacy')}
        </NavLink>
        <NavLink 
          to="/terms-and-conditions" 
          className="hover:text-red-600"
        >
          {t('footer_terms')}
        </NavLink>
        <NavLink 
          to="/contact-us" 
          className="hover:text-red-600"
        >
          {t('contact_us')}
        </NavLink>
        <NavLink 
          to="/support-us" 
          className="hover:text-red-600"
        >
          {t('support_us')}
        </NavLink>
      </nav>
    </footer>
  );
};

export default Footer;

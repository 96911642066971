import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Alberta = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {
          `
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
          `
        }
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('ab_program_title')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Programs */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('ab_overview_title')}
              </h3>
              <p className="text-gray-700">{t('ab_overview_intro')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('ab_overview_tourism_hospitality')}</strong> {t('ab_overview_tourism_hospitality_description')}
                </li>
                <li>
                  <strong>{t('ab_overview_opportunity')}</strong> {t('ab_overview_opportunity_description')}
                </li>
                <li>
                  <strong>{t('ab_overview_express_entry')}</strong> {t('ab_overview_express_entry_description')}
                </li>
                <li>
                  <strong>{t('ab_overview_rural_renewal')}</strong> {t('ab_overview_rural_renewal_description')}
                </li>
                <li>
                  <strong>{t('ab_overview_entrepreneur')}</strong> {t('ab_overview_entrepreneur_description')}
                </li>
              </ul>
            </div>
          </div>

          {/* Step 2: Eligibility Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('ab_requirements_title')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('ab_requirements_tourism_hospitality_title')}</strong>
                  <ul className="list-disc list-inside ml-4">
                    <li>{t('ab_requirements_tourism_hospitality_description_1')}</li>
                    <li>{t('ab_requirements_tourism_hospitality_description_2')}</li>
                    <li>{t('ab_requirements_tourism_hospitality_description_3')}</li>
                    <li>{t('ab_requirements_tourism_hospitality_description_4')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('ab_requirements_opportunity_title')}</strong>
                  <ul className="list-disc list-inside ml-4">
                    <li>{t('ab_requirements_opportunity_description_1')}</li>
                    <li>{t('ab_requirements_opportunity_description_2')}</li>
                    <li>{t('ab_requirements_opportunity_description_3')}</li>
                    <li>
                      <strong>{t('ab_requirements_opportunity_work_experience_title')}</strong>
                      <ul className="list-disc list-inside ml-4">
                        <li>{t('ab_requirements_opportunity_work_experience_general')}</li>
                        <li>{t('ab_requirements_opportunity_work_experience_pgwp')}</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t('ab_requirements_express_entry_title')}</strong> {t('ab_requirements_express_entry_description')}
                </li>
                <li>
                  <strong>{t('ab_requirements_rural_renewal_title')}</strong> {t('ab_requirements_rural_renewal_description')}
                </li>
                <li>
                  <strong>{t('ab_requirements_entrepreneur_title')}</strong>
                  <ul className="list-disc list-inside ml-4">
                    <li>
                      <strong>{t('ab_requirements_rural_entrepreneur_title')}</strong> {t('ab_requirements_rural_entrepreneur_description')}
                    </li>
                    <li>
                      <strong>{t('ab_requirements_graduate_entrepreneur_title')}</strong> {t('ab_requirements_graduate_entrepreneur_description')}
                    </li>
                    <li>
                      <strong>{t('ab_requirements_farm_stream_title')}</strong> {t('ab_requirements_farm_stream_description')}
                    </li>
                    <li>
                      <strong>{t('ab_requirements_foreign_graduate_entrepreneur_title')}</strong> {t('ab_requirements_foreign_graduate_entrepreneur_description')}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>


          {/* Step 3: Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-6">
                {t('ab_application_process_title')}
              </h3>

              {/* Step 1: Determine Eligibility */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2 mt-6">
                  {t('ab_step1_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('ab_step1_review_title')}</strong> {t('ab_step1_description')}
                </p>
                
              </div>

              {/* Step 2: Prepare Documentation */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ab_step2_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li><strong>{t('ab_step2_gather_documents_title')}</strong> {t('ab_step2_gather_documents_description')}</li>
                  <li><strong>{t('ab_step2_eoi_title')}</strong> {t('ab_step2_eoi_description')}</li>
                </ul>
              </div>

              {/* Step 3: Submit Application */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ab_step3_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li><strong>{t('ab_step3_online_application_title')}</strong> {t('ab_step3_online_application_description')}</li>
                  <li><strong>{t('ab_step3_entrepreneur_streams_title')}</strong> {t('ab_step3_entrepreneur_streams_description')}</li>
                </ul>
              </div>

              {/* Step 4: Application Assessment */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ab_step4_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li><strong>{t('ab_step4_review_process_title')}</strong> {t('ab_step4_review_process_description')}</li>
                  <li><strong>{t('ab_step4_processing_time_title')}</strong> {t('ab_step4_processing_time_description')}</li>
                </ul>
              </div>

              {/* Step 5: Nomination */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ab_step5_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('ab_step5_approval_title')}</strong> {t('ab_step5_approval_description')}
                </p>
              </div>

              {/* Step 6: Apply for Permanent Residency */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ab_step6_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li><strong>{t('ab_step6_federal_application_title')}</strong> {t('ab_step6_federal_application_description')}</li>
                  <li><strong>{t('ab_step6_medical_security_checks_title')}</strong> {t('ab_step6_medical_security_checks_description')}</li>
                </ul>
              </div>

            </div>
          </div>


          {/* Step 4: Pros & Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('ab_pros_cons_title')}
              </h3>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('ab_pros_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('ab_pros_description_1')}</li>
                <li>{t('ab_pros_description_2')}</li>
              </ul>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('ab_cons_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('ab_cons_description_1')}</li>
                <li>{t('ab_cons_description_2')}</li>
              </ul>
            </div>
          </div>
          {/* Step 5: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('next_actions')}
                </h3>
                <p className="text-gray-700 mb-4">
                  {t('ontario_next_actions_description')}
                </p>
                <Link
                to="https://www.alberta.ca/alberta-advantage-immigration-program"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
              >
                {t('ab_learn_more')} →
              </Link>
              </div>

              {/* Right side - Call-to-action button centered */}
              <div className="w-full md:w-1/4 flex justify-center"> {/* Center the button */}
                <Link
                  to="/canada-immigration-support/clb-conversion"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-flex items-center justify-center bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Smaller Image */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-2"  // Smaller image
                  />
                  {t('convert_your_clb_score')}
                </Link>
              </div>
            </div>
          </div>

          

        </div>
      </div>
    </div>
  );
};

export default Alberta;

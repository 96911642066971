import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CanadaImmigrationSupport = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-white p-4 md:p-8 rounded-md shadow-md mx-4 md:mx-12 my-8">
      <div className="text-center mb-6">
        {/* Heading */}
        <h1 className="text-xl sm:text-2xl md:text-3xl font-extrabold text-center text-red-600 tracking-wide">{t('canada_immigration_support')}</h1>
        {/* Subtle line */}
        <div className="w-16 sm:w-20 h-1 bg-red-600 mx-auto my-4 sm:my-6 rounded-full"></div>

        {/* Image below the heading */}
        <img
          src="/images/canadaentry_support.png"
          alt="Canada Immigration Support"
          className="w-full h-auto max-w-[180px] md:max-w-xs mx-auto"
          style={{ maxHeight: '250px', objectFit: 'contain' }}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8">
        {/* CRS Score Calculator */}
        <NavLink
          to="/canada-immigration-support/crs-score-calculator"
          className="relative block bg-red-100 p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-transform duration-200 hover:bg-red-200 text-center transform hover:scale-105"
        >
          <h2 className="text-lg md:text-xl font-semibold text-red-600">{t('crs_score_calculator')}</h2>
          <p className="mt-2 md:mt-4 text-gray-700 text-sm md:text-base">{t('crs_description')}</p>
        </NavLink>

        {/* PGWP Eligibility */}
        <NavLink
          to="/canada-immigration-support/pgwp-eligibility"
          className="relative block bg-red-100 p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-transform duration-200 hover:bg-red-200 text-center transform hover:scale-105"
        >
          <h2 className="text-lg md:text-xl font-semibold text-red-600">{t('pgwp_eligibility')}</h2>
          <p className="mt-2 md:mt-4 text-gray-700 text-sm md:text-base">{t('pgwp_description')}</p>
        </NavLink>

        {/* CLB Conversion */}
        <NavLink
          to="/canada-immigration-support/clb-conversion"
          className="relative block bg-red-100 p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-transform duration-200 hover:bg-red-200 text-center transform hover:scale-105"
        >
          <h2 className="text-lg md:text-xl font-semibold text-red-600">{t('clb_conversion')}</h2>
          <p className="mt-2 md:mt-4 text-gray-700 text-sm md:text-base">{t('clb_description')}</p>
        </NavLink>

        {/* PGWP Application */}
        <NavLink
          to="/canada-immigration-support/pgwp-application"
          className="relative block bg-red-100 p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-transform duration-200 hover:bg-red-200 text-center transform hover:scale-105"
        >
          <h2 className="text-lg md:text-xl font-semibold text-red-600">{t('pgwp_application')}</h2>
          <p className="mt-2 md:mt-4 text-gray-700 text-sm md:text-base">{t('pgwpapp_description')}</p>
        </NavLink>
      </div>
    </section>
  );
};

export default CanadaImmigrationSupport;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const QuebecBusinessImmigration = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('qbi_heading')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('overview_title')}
              </h3>
              <p className="text-gray-700">{t('qbi_overview_description')}</p>
            </div>
          </div>

          {/* Step 2: Eligibility */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('eligibility')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('qbi_investor_program_title')}</strong>: {t('qbi_investor_program_description')}</li>
                <li><strong>{t('qbi_entrepreneur_program_title')}</strong>: {t('qbi_entrepreneur_program_description')}</li>
                <li><strong>{t('qbi_self_employed_program_title')}</strong>: {t('qbi_self_employed_program_description')}</li>
                <li><strong>{t('qbi_language_proficiency_title')}</strong>: {t('qbi_language_proficiency_description')}</li>
                <li><strong>{t('qbi_financial_capacity_title')}</strong>: {t('qbi_financial_capacity_description')}</li>
              </ul>
            </div>
          </div>

          {/* Step 3: Required Documents */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('required_documents')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('proof_identity_title')}</strong>: {t('proof_identity_description')}</li>
                <li><strong>{t('proof_business_plan_title')}</strong>: {t('proof_business_plan_description')}</li>
                <li><strong>{t('proof_funds_title')}</strong>: {t('proof_funds_description')}</li>
                <li><strong>{t('proof_credentials_title')}</strong>: {t('proof_credentials_description')}</li>
                <li><strong>{t('proof_investment_title')}</strong>: {t('proof_investment_description')}</li>
              </ul>
            </div>
          </div>

          {/* Step 4: Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('application_process')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('step_1_title')}</strong>: {t('step_1_description')}</li>
                <li><strong>{t('step_2_title')}</strong>: {t('step_2_description')}</li>
                <li><strong>{t('step_3_title')}</strong>: {t('step_3_description')}</li>
                <li><strong>{t('step_4_title')}</strong>: {t('step_4_description')}</li>
                <li><strong>{t('step_5_title')}</strong>: {t('step_5_description')}</li>
                <li><strong>{t('step_6_title')}</strong>: {t('step_6_description')}</li>
              </ul>
            </div>
          </div>

          {/* Step 5: Pros & Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('pros_cons')}</h3>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">{t('pros')}</h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('qbi_pro_1')}</li>
                <li>{t('qbi_pro_2')}</li>
                <li>{t('qbi_pro_3')}</li>
              </ul>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">{t('cons')}</h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('qbi_con_1')}</li>
                <li>{t('qbi_con_2')}</li>
                <li>{t('qbi_con_3')}</li>
              </ul>
            </div>
          </div>
          {/* Step 6: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('next_actions')}</h3>
              <p className="text-gray-700 mb-4">{t('next_actions_quebec_description')}</p>
              <Link
                to="https://www.quebec.ca/en/immigration/permanent"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
              >
                {t('learn_more_about_quebec')} →
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default QuebecBusinessImmigration;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaExclamationCircle } from 'react-icons/fa';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="p-8 bg-gray-100">
      {/* Page Heading */}
      <h1 className="text-4xl font-bold text-center text-red-600 mb-8">{t('privacy_policy_title')}</h1>

      {/* Content Section */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        {/* Introduction */}
        <p className="text-gray-700 mb-6">{t('privacy_policy_intro')}</p>

        {/* Section 1 - Data Collection */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-red-600 mb-2">{t('privacy_policy_section1_title')}</h2>
          <p className="text-gray-700">{t('privacy_policy_section1_body')}</p>
        </div>

        {/* Section 2 - Data Usage */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-red-600 mb-2">{t('privacy_policy_section2_title')}</h2>
          <p className="text-gray-700">{t('privacy_policy_section2_body')}</p>
        </div>

        {/* Section 3 - Data Sharing */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-red-600 mb-2">{t('privacy_policy_section3_title')}</h2>
          <p className="text-gray-700">{t('privacy_policy_section3_body')}</p>
        </div>

        {/* Section 4 - Security */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold text-red-600 mb-2">{t('privacy_policy_section4_title')}</h2>
          <p className="text-gray-700">{t('privacy_policy_section4_body')}</p>
        </div>

        {/* Final Note */}
        <div className="bg-gray-50 p-6 rounded-lg shadow-md mt-8 flex flex-col justify-center items-center">
          <div className="flex items-center">
            <FaExclamationCircle className="text-red-600 text-3xl sm:text-2xl mr-2" /> {/* Larger for mobile */}
            <p className="text-gray-700 italic text-center text-lg font-medium">
              {t('privacy_policy_final_note')}
            </p>
          </div>
          <div className="w-40 sm:w-20 h-1 bg-red-600 mt-4 rounded-full"></div> {/* Much longer line */}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

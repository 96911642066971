import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SpousePartnerChildren = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('sponsoring_spouse_partner_children')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('family_sponsorship_overview')}
              </h3>
              <p className="text-gray-700">{t('sponsoring_spouse_partner_children_overview')}</p>
            </div>
          </div>

          {/* Step 2: Eligibility */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('eligibility')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('sponsor_must_be_18')}</li>
                <li>{t('prove_relationship_genuine')}</li>
                <li>{t('financial_undertaking_3_years')}</li>
                <li>{t('no_bankrupt_disability')}</li>
                <li>{t('no_criminal_record_sponsor')}</li>
              </ul>
            </div>
          </div>

          {/* Step 3: Required Documents */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('required_documents')}
              </h3>
              <ul className="list-decimal list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('proof_of_marriage_relationship')}</strong>
                  <ul className="list-disc list-inside ml-6">
                    <li>{t('marriage_certificate')}</li>
                    <li>{t('proof_of_cohabitation')}</li>
                    <li>{t('children_birth_certificates')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('identity_documents')}</strong>
                  <ul className="list-disc list-inside ml-6">
                    <li>{t('copies_of_passports')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('financial_proof')}</strong>
                  <ul className="list-disc list-inside ml-6">
                    <li>{t('tax_returns')}</li>
                    <li>{t('bank_statements')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('proof_of_relationship')}</strong>
                  <ul className="list-disc list-inside ml-6">
                    <li>{t('joint_photos')}</li>
                    <li>{t('letters_from_friends')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('medical_and_police_clearance')}</strong>
                  <ul className="list-disc list-inside ml-6">
                    <li>{t('medical_exam')}</li>
                    <li>{t('police_certificate')}</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          {/* Step 4: How to Apply */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('how_to_apply')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('step')} 1:</strong>{t('step_1_download_forms')}</li>
                <li><strong>{t('step')} 2:</strong>{t('step_2_complete_forms')}</li>
                <li><strong>{t('step')} 3:</strong>{t('step_3_gather_documents')}</li>
                <li><strong>{t('step')} 4:</strong>{t('step_4_pay_fees')}</li>
                <li><strong>{t('step')} 5:</strong>{t('step_5_submit_application')}</li>
                <li><strong>{t('step')} 6:</strong>{t('step_6_biometrics')}</li>
                <li><strong>{t('step')} 7:</strong>{t('step_7_monitor_application')}</li>
              </ul>
            </div>
          </div>

          {/* Step 5: Pros and Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('pros_cons')}
              </h3>

              {/* Pros Section */}
              <h4 className="text-md sm:text-lg font-semibold text-gray-800 mb-2">
                {t('pros')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('pros_reunite_with_family')}</li>
                <li>{t('pros_work_study_canada')}</li>
                <li>{t('pros_access_healthcare')}</li>
              </ul>

              {/* Cons Section */}
              <h4 className="text-md sm:text-lg font-semibold text-gray-800 mt-4 mb-2">
                {t('cons')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('cons_processing_time')}</li>
                <li>{t('cons_sponsorship_fees')}</li>
                <li>{t('cons_rejection_risk')}</li>
              </ul>
            </div>
          </div>
          {/* Step 6: Next actions */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('next_actions')}</h3>
              <p className="text-gray-700 mb-4">{t('next_actions_sponsor_description')}</p>
              <Link
                to="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/family-sponsorship.html"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
              >
                {t('apply_sponsor_visa')} →
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpousePartnerChildren;

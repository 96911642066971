import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PGWPApplication = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% { opacity: 1; transform: scale(1); }
            50% { opacity: 0.7; transform: scale(1.05); }
          }
          .animate-twinkle { animation: twinkle 1s infinite ease-in-out; }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('pgwpapp_program_title')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Program Overview */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('pgwpapp_overview_title')}
              </h3>
              <p className="text-gray-700">{t('pgwpapp_overview_description')}</p>
            </div>
          </div>

          {/* Step 2: Eligibility Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide content into two columns */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Title and Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('pgwpapp_eligibility_title')}
                </h3>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>
                    {t('pgwpapp_eligibility_graduation')}{' '}
                    <a
                      href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/prepare/designated-learning-institutions-list.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                    >
                      {t('learn_more')}
                      <svg
                        className="w-4 h-4 ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>{t('pgwpapp_eligibility_program_duration')}</li>
                  <li>{t('pgwpapp_eligibility_fulltime')}</li>
                  <li>{t('pgwpapp_eligibility_inperson')}</li>
                  <li>{t('pgwpapp_eligibility_studypermit')}</li>
                  <li>{t('pgwpapp_eligibility_ineligible')}</li>
                  <li>{t('pgwpapp_eligibility_language')}</li>
                  <li>{t('pgwpapp_eligibility_study_field')}</li>
                </ul>
              </div>

              {/* Right side - Button aligned to the left */}
              <div className="w-full md:w-1/4 text-center">
                <Link
                  to="/canada-immigration-support/pgwp-eligibility"
                  target="_blank" // Opens in a new window
                  rel="noopener noreferrer" // Security enhancement
                  className="mt-6 inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Image at the beginning of the button */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-4"
                  />
                  {t('pgwpapp_pgwp_eligibility_learn_more')}
                </Link>
                <Link
                  to="/canada-immigration-support/clb-conversion"
                  target="_blank" // Opens in a new window
                  rel="noopener noreferrer" // Security enhancement
                  className="mt-6 inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Image at the beginning of the button */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-4"
                  />
                  {t('pgwpapp_pgwp_clb_conversion_learn_more')}
                </Link>
              </div>
            </div>
          </div>


          {/* Step 3: Detailed Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('pgwpapp_application_process_title')}
              </h3>

              {/* Prepare an IRCC Account */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('pgwpapp_account_prep_title')}
                </h4>
                <p className="text-gray-700">{t('pgwpapp_account_prep_description')}</p>
              </div>

              {/* Log into IRCC and Select PGWP Application */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('pgwpapp_login_ircc_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('pgwpapp_login_ircc_description')}</li>
                  <ul className="list-disc list-inside ml-4 text-gray-700">
                    <li>{t('pgwpapp_login_form_imm5710')}</li>
                    <li>{t('pgwpapp_login_form_imm5645')}</li>
                  </ul>
                </ul>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('pgwpapp_upload_docs_title')}</li>
                  <ul className="list-disc list-inside ml-4 text-gray-700">
                    <li>{t('pgwpapp_upload_photo')}</li>
                    <li>{t('pgwpapp_upload_passport')}</li>
                    <li>{t('pgwpapp_upload_medical')}</li>
                    <li>{t('pgwpapp_upload_language')}</li>
                    <li>{t('pgwpapp_upload_letter')}</li>
                    <li>{t('pgwpapp_upload_graduation')}</li>
                  </ul>
                </ul>
              </div>

              {/* Pay Fees and Complete Submission */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('pgwpapp_pay_fees_title')}
                </h4>
                <p className="text-gray-700">{t('pgwpapp_pay_fees_description')}</p>
              </div>

              {/* Monitor Application */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('pgwpapp_monitor_title')}
                </h4>
                <p className="text-gray-700">{t('pgwpapp_monitor_description')}</p>
              </div>

              {/* Await IRCC Decision */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('pgwpapp_await_decision_title')}
                </h4>
                <p className="text-gray-700">{t('pgwpapp_await_decision_description')}</p>
              </div>
            </div>
          </div>

          {/* Essential Rules */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('pgwpapp_rules_title')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('pgwpapp_rules_letter_completion')}</li>
                {/* Styled Table */}
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white border border-gray-300 text-left my-6">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="px-6 py-3 border-b-2 font-semibold text-gray-700">
                          {t('pgwpapp_table_event')}
                        </th>
                        <th className="px-6 py-3 border-b-2 font-semibold text-gray-700">
                          {t('pgwpapp_table_working_eligibility')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">
                          <strong>{t('pgwpapp_table_row1_event')}</strong><br />
                          <span>{t('pgwpapp_table_row1_description')}</span>
                        </td>
                        <td className="px-6 py-4 border-b border-gray-200 font-semibold text-green-700">
                          {t('pgwpapp_table_row1_eligibility')}
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">
                          <strong>{t('pgwpapp_table_row2_event')}</strong><br />
                          <span>{t('pgwpapp_table_row2_description')}</span>
                        </td>
                        <td className="px-6 py-4 border-b border-gray-200 text-red-700">
                          {t('pgwpapp_table_row2_eligibility')}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">
                          <strong>{t('pgwpapp_table_row3_event')}</strong>
                        </td>
                        <td className="px-6 py-4 border-b border-gray-200 font-semibold text-green-700">
                          {t('pgwpapp_table_row3_eligibility')}
                        </td>
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 border-b border-gray-200 text-gray-800">
                          <strong>{t('pgwpapp_table_row4_event')}</strong>
                        </td>
                        <td className="px-6 py-4 border-b border-gray-200 font-semibold text-green-700">
                          {t('pgwpapp_table_row4_eligibility')}
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 text-gray-800">
                          {t('pgwpapp_table_row5_event')}
                        </td>
                        <td className="px-6 py-4 text-red-700">
                          {t('pgwpapp_table_row5_eligibility')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <li>{t('pgwpapp_rules_port_entry')}</li>
                <li>{t('pgwpapp_rules_180days')}</li>
                <li>{t('pgwpapp_rules_study_permit')}</li>
                <li>{t('pgwpapp_rules_90days')}</li>
                <li>{t('pgwpapp_rules_multiple_program')}</li>
                <li>{t('pgwpapp_rules_single_issue')}</li>
              </ul>
            </div>
          </div>

          {/* Pros and Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('pgwpapp_pros_cons_title')}
              </h3>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('pgwpapp_pros_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('pgwpapp_pros_description_1')}</li>
              </ul>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('pgwpapp_cons_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('pgwpapp_cons_description_1')}</li>
              </ul>
            </div>
          </div>
          {/* Next actions */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('next_actions')}</h3>
              <p className="text-gray-700 mb-4">{t('pgwpapp_next_actions_description')}</p>
              <Link
                to="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/work/after-graduation/apply.html"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
              >
                {t('pgwpapp_learn_more')} →
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PGWPApplication;

import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt, FaCheckCircle, FaLightbulb } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { NavLink } from 'react-router-dom';
import reasonsData from '../data/pgwp_reason.json';
import Select from 'react-select';
import fields from '../data/field_of_study_lookup.json';
import { format } from 'date-fns';

const PGWPEligibility = () => {
  const { t } = useTranslation();

  const [selectedField, setSelectedField] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    program_level: "",
    institution_type: "",
    study_length: "",
    language_proficiency: {
      speaking: "",
      reading: "",
      listening: "",
      writing: ""
    },
    language_test: "",
    language_test_date: null,
    study_permit_application_date: null,
    pgwp_application_date: null,
    field_of_study: ""
  });

  const [responseData, setResponseData] = useState({
    eligible: undefined,
    reason_indices: undefined,
    max_duration_months: undefined
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if changing a nested field in language_proficiency
    if (['speaking', 'reading', 'listening', 'writing'].includes(name)) {
      setFormData({
        ...formData,
        language_proficiency: {
          ...formData.language_proficiency,
          [name]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    try {
      // const response = await axios.post('http://localhost:5001/api/pgwp_assessment', formData); // Dev
      // const response = await axios.post('http://canadaentry.net:5001/api/pgwp_assessment', formData); // Test
      const response = await axios.post('/api/pgwp_assessment', formData, {headers: {'Content-Type': 'application/json'}}); // Prod
      setResponseData({
        eligible: response.data.eligible,
        reason_indices: response.data.reason_indices,
        max_duration_months: response.data.max_duration_months
      });
    } catch (error) {
      console.error('Error submitting the form', error);
    }
  };

  const fieldsOfStudy = fields.map(field => ({
    value: field.cip_2021_title,
    label: field.cip_2021_title
  }));

  const languageTestOptions = [
    { value: '', label: t('select_language_test') },
    { value: 'CELPIP-G', label: t('CELPIP-G') },
    { value: 'IELTS', label: t('IELTS') },
    { value: 'PTE Core', label: t('PTE Core') },
    { value: 'TEF Canada', label: t('TEF Canada') },
    { value: 'TCF Canada', label: t('TCF Canada') },
  ];

  // Define options for Program Level
  const programLevelOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'College', label: t('education_college') },
    { value: 'Bachelor', label: t('education_bachelor') },
    { value: 'Master', label: t('education_master') },
    { value: 'Doctoral', label: t('education_doctoral') },
  ];

  // Define options for Institution Type
  const institutionTypeOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'University', label: t('university') },
    { value: 'College', label: t('college') },
    { value: 'Flight School (PGWP Eligible)', label: t('flight_school_pgwp_eligible') },
  ];

  // Define options for Study Length
  const studyLengthOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Less than 8 months', label: t('Less_than_8_months') },
    { value: '8 to 12 months', label: t('8_to_12_months') },
    { value: '1 to 2 years', label: t('1_to_2_years') },
    { value: 'More than 2 years', label: t('More_than_2_years') },
  ];

  // Define options for Test Results Within Two Years
  const testResultsWithinTwoYearsOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Yes', label: t('yes') },
    { value: 'No', label: t('no') },
  ];

  // Define options for Field of Study
  const fieldOfStudyOptions = [
    { value: '', label: t('select_an_option') },
    { value: 'Agriculture and Agri-food', label: t('agriculture_and_agri_food') },
    { value: 'Healthcare', label: t('healthcare') },
    { value: 'Science, Technology, Engineering and Mathematics (STEM)', label: t('stem') },
    { value: 'Trade', label: t('trade') },
    { value: 'Transport', label: t('transport') },
    { value: 'Other', label: t('other') },
  ];




  const handleSearch = async () => {
    if (!selectedField) {
      setError(t('please_select_a_field'));
      setSearchResult(null);
      return;
    }

    try {
      // const response = await fetch('http://127.0.0.1:5001/api/field_of_study_lookup', { // Dev
      // const response = await fetch('http://127.0.0.1:5001/api/field_of_study_lookup', { // Test
      const response = await fetch('/api/field_of_study_lookup', { // Prod
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cip_2021_title: selectedField.value }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setSearchResult(data); // Handle the response and set it in state
      setError(null); // Clear any errors
    } catch (err) {
      setError(t('error_fetching_data'));
      setSearchResult(null); // Clear previous results
    }
  };


  const renderLanguageFields = () => {

    // Define options with ielts_ prefix
    const ielts_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '7.5 - 9.0', label: t('7.5 - 9.0') },
      { value: '7.0', label: t('7.0') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '0 - 3.5', label: t('0 - 3.5') },
    ];

    const ielts_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '8.5 - 9.0', label: t('8.5 - 9.0') },
      { value: '8.0', label: t('8.0') },
      { value: '7.5', label: t('7.5') },
      { value: '6.0 - 7.0', label: t('6.0 - 7.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.5', label: t('4.5') },
      { value: '0 - 4.0', label: t('0 - 4.0') },
    ];

    const ielts_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '8.0 - 9.0', label: t('8.0 - 9.0') },
      { value: '7.0 - 7.5', label: t('7.0 - 7.5') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.0 - 5.5', label: t('5.0 - 5.5') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '3.5', label: t('3.5') },
      { value: '0 - 3.0', label: t('0 - 3.0') },
    ];

    const ielts_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '7.5 - 9.0', label: t('7.5 - 9.0') },
      { value: '7.0', label: t('7.0') },
      { value: '6.5', label: t('6.5') },
      { value: '6.0', label: t('6.0') },
      { value: '5.5', label: t('5.5') },
      { value: '5.0', label: t('5.0') },
      { value: '4.0 - 4.5', label: t('4.0 - 4.5') },
      { value: '0 - 3.5', label: t('0 - 3.5') },
    ];

    // Define options with celpip_ prefix
    const celpip_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    const celpip_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '10 - 12', label: t('10 - 12') },
      { value: '9', label: t('9') },
      { value: '8', label: t('8') },
      { value: '7', label: t('7') },
      { value: '6', label: t('6') },
      { value: '5', label: t('5') },
      { value: '4', label: t('4') },
      { value: 'M, 0 - 3', label: t('M, 0 - 3') },
    ];

    // Define options with pte_ prefix
    const pte_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '89 - 90', label: t('89 - 90') },
      { value: '84 - 88', label: t('84 - 88') },
      { value: '76 - 83', label: t('76 - 83') },
      { value: '68 - 75', label: t('68 - 75') },
      { value: '59 - 67', label: t('59 - 67') },
      { value: '51 - 58', label: t('51 - 58') },
      { value: '42 - 50', label: t('42 - 50') },
      { value: '0 - 41', label: t('0 - 41') },
    ];

    const pte_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '89 - 90', label: t('89 - 90') },
      { value: '82 - 88', label: t('82 - 88') },
      { value: '71 - 81', label: t('71 - 81') },
      { value: '60 - 70', label: t('60 - 70') },
      { value: '50 - 59', label: t('50 - 59') },
      { value: '39 - 49', label: t('39 - 49') },
      { value: '28 - 38', label: t('28 - 38') },
      { value: '0 - 27', label: t('0 - 27') },
    ];

    const pte_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '88 - 90', label: t('88 - 90') },
      { value: '78 - 87', label: t('78 - 87') },
      { value: '69 - 77', label: t('69 - 77') },
      { value: '60 - 68', label: t('60 - 68') },
      { value: '51 - 59', label: t('51 - 59') },
      { value: '42 - 50', label: t('42 - 50') },
      { value: '33 - 41', label: t('33 - 41') },
      { value: '0 - 32', label: t('0 - 32') },
    ];

    const pte_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '90', label: t('90') },
      { value: '88 - 89', label: t('88 - 89') },
      { value: '79 - 87', label: t('79 - 87') },
      { value: '69 - 78', label: t('69 - 78') },
      { value: '60 - 68', label: t('60 - 68') },
      { value: '51 - 59', label: t('51 - 59') },
      { value: '41 - 50', label: t('41 - 50') },
      { value: '0 - 40', label: t('0 - 40') },
    ];

    // Define options with tcf_ prefix
    const tcf_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '16 - 20', label: t('16 - 20') },
      { value: '14 - 15', label: t('14 - 15') },
      { value: '12 - 13', label: t('12 - 13') },
      { value: '10 - 11', label: t('10 - 11') },
      { value: '7 - 9', label: t('7 - 9') },
      { value: '6', label: t('6') },
      { value: '4 - 5', label: t('4 - 5') },
      { value: '0 - 3', label: t('0 - 3') },
    ];

    const tcf_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '549 - 699', label: t('549 - 699') },
      { value: '523 - 548', label: t('523 - 548') },
      { value: '503 - 522', label: t('503 - 522') },
      { value: '458 - 502', label: t('458 - 502') },
      { value: '398 - 457', label: t('398 - 457') },
      { value: '369 - 397', label: t('369 - 397') },
      { value: '331 - 368', label: t('331 - 368') },
      { value: '0 - 330', label: t('0 - 330') },
    ];

    const tcf_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '549 - 699', label: t('549 - 699') },
      { value: '524 - 548', label: t('524 - 548') },
      { value: '499 - 523', label: t('499 - 523') },
      { value: '453 - 498', label: t('453 - 498') },
      { value: '406 - 452', label: t('406 - 452') },
      { value: '375 - 405', label: t('375 - 405') },
      { value: '342 - 374', label: t('342 - 374') },
      { value: '0 - 341', label: t('0 - 341') },
    ];

    const tcf_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '16 - 20', label: t('16 - 20') },
      { value: '14 - 15', label: t('14 - 15') },
      { value: '12 - 13', label: t('12 - 13') },
      { value: '10 - 11', label: t('10 - 11') },
      { value: '7 - 9', label: t('7 - 9') },
      { value: '6', label: t('6') },
      { value: '4 - 5', label: t('4 - 5') },
      { value: '0 - 3', label: t('0 - 3') },
    ];

    // Define options with tef_ prefix
    const tef_speakingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '393 - 450', label: t('393 - 450') },
      { value: '371 - 392', label: t('371 - 392') },
      { value: '349 - 370', label: t('349 - 370') },
      { value: '310 - 348', label: t('310 - 348') },
      { value: '271 - 309', label: t('271 - 309') },
      { value: '226 - 270', label: t('226 - 270') },
      { value: '181 - 225', label: t('181 - 225') },
      { value: '0 - 180', label: t('0 - 180') },
    ];

    const tef_listeningOptions = [
      { value: '', label: t('select_an_option') },
      { value: '316 - 360', label: t('316 - 360') },
      { value: '298 - 315', label: t('298 - 315') },
      { value: '280 - 297', label: t('280 - 297') },
      { value: '249 - 279', label: t('249 - 279') },
      { value: '217 - 248', label: t('217 - 248') },
      { value: '181 - 216', label: t('181 - 216') },
      { value: '145 - 180', label: t('145 - 180') },
      { value: '0 - 144', label: t('0 - 144') },
    ];

    const tef_readingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '263 - 300', label: t('263 - 300') },
      { value: '248 - 262', label: t('248 - 262') },
      { value: '233 - 247', label: t('233 - 247') },
      { value: '207 - 232', label: t('207 - 232') },
      { value: '181 - 206', label: t('181 - 206') },
      { value: '151 - 180', label: t('151 - 180') },
      { value: '121 - 150', label: t('121 - 150') },
      { value: '0 - 120', label: t('0 - 120') },
    ];

    const tef_writingOptions = [
      { value: '', label: t('select_an_option') },
      { value: '393 - 450', label: t('393 - 450') },
      { value: '371 - 392', label: t('371 - 392') },
      { value: '349 - 370', label: t('349 - 370') },
      { value: '310 - 348', label: t('310 - 348') },
      { value: '271 - 309', label: t('271 - 309') },
      { value: '226 - 270', label: t('226 - 270') },
      { value: '181 - 225', label: t('181 - 225') },
      { value: '0 - 180', label: t('0 - 180') },
    ];


    if (formData.language_test === 'IELTS') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <style>
            {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
          </style>
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={ielts_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={ielts_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={ielts_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={ielts_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={ielts_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={ielts_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={ielts_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={ielts_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

        </div>
      );
    } else if (formData.language_test === 'CELPIP-G') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={celpip_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={celpip_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={celpip_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={celpip_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={celpip_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={celpip_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={celpip_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={celpip_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>
      );
    } else if (formData.language_test === 'PTE Core') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={pte_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={pte_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={pte_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={pte_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={pte_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={pte_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={pte_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={pte_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>

      );
    } else if (formData.language_test === 'TCF Canada') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={tcf_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={tcf_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={tcf_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={tcf_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={tcf_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={tcf_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={tcf_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={tcf_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>

      );
    } else if (formData.language_test === 'TEF Canada') {
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Speaking Field */}
          <div>
            <label htmlFor="speaking" className="block text-lg font-medium">{t('speaking')}</label>
            <Select
              id="speaking"
              name="speaking"
              value={tef_speakingOptions.find(option => option.value === formData.speaking)}
              onChange={(selectedOption) => handleChange({ target: { name: 'speaking', value: selectedOption.value } })}
              options={tef_speakingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Listening Field */}
          <div>
            <label htmlFor="listening" className="block text-lg font-medium">{t('listening')}</label>
            <Select
              id="listening"
              name="listening"
              value={tef_listeningOptions.find(option => option.value === formData.listening)}
              onChange={(selectedOption) => handleChange({ target: { name: 'listening', value: selectedOption.value } })}
              options={tef_listeningOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Reading Field */}
          <div>
            <label htmlFor="reading" className="block text-lg font-medium">{t('reading')}</label>
            <Select
              id="reading"
              name="reading"
              value={tef_readingOptions.find(option => option.value === formData.reading)}
              onChange={(selectedOption) => handleChange({ target: { name: 'reading', value: selectedOption.value } })}
              options={tef_readingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>

          {/* Writing Field */}
          <div>
            <label htmlFor="writing" className="block text-lg font-medium">{t('writing')}</label>
            <Select
              id="writing"
              name="writing"
              value={tef_writingOptions.find(option => option.value === formData.writing)}
              onChange={(selectedOption) => handleChange({ target: { name: 'writing', value: selectedOption.value } })}
              options={tef_writingOptions}
              className="mt-2"
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  width: '100%',
                  padding: '0.5rem',
                  borderColor: 'gray-300',
                  borderRadius: '0.375rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 50,
                }),
              }}
              placeholder={t('select_an_option')}
            />
          </div>
        </div>

      );
    }

    return null;
  };

  return (
    <div className="p-8 bg-gray-100">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Form Section */}
        <section className="bg-white p-8 rounded-md shadow-md">

          <div className="bg-red-100 text-red-600 p-2 sm:p-4 rounded-md shadow-sm text-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold">{t('pgwp_eligibility')}</h2>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4 mt-6">

            {/* Program Level Field */}
            <div className="w-full">
              {/* Label */}
              <label htmlFor="program_level" className="block text-base sm:text-lg font-medium">
                {t('program_level')}
              </label>

              {/* Select Component */}
              <Select
                id="program_level"
                name="program_level"
                value={programLevelOptions.find(option => option.value === formData.program_level)}
                onChange={(selectedOption) => handleChange({ target: { name: 'program_level', value: selectedOption.value } })}
                options={programLevelOptions}
                className="mt-2 w-full"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%', // Ensures full width for mobile responsiveness
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Institution Type Field */}
            <div className="w-full">
              {/* Label */}
              <label htmlFor="institution_type" className="block text-base sm:text-lg font-medium">
                {t('institution_type')}
              </label>

              {/* Select Component */}
              <Select
                id="institution_type"
                name="institution_type"
                value={institutionTypeOptions.find(option => option.value === formData.institution_type)}
                onChange={(selectedOption) => handleChange({ target: { name: 'institution_type', value: selectedOption.value } })}
                options={institutionTypeOptions}
                className="mt-2 w-full"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%', // Ensures full width for mobile responsiveness
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Study Length Field */}
            <div className="w-full">
              {/* Label */}
              <label htmlFor="study_length" className="block text-base sm:text-lg font-medium">
                {t('study_length')}
              </label>

              {/* Select Component */}
              <Select
                id="study_length"
                name="study_length"
                value={studyLengthOptions.find(option => option.value === formData.study_length)}
                onChange={(selectedOption) => handleChange({ target: { name: 'study_length', value: selectedOption.value } })}
                options={studyLengthOptions}
                className="mt-2 w-full"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%', // Ensures full width for mobile responsiveness
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>


            {/* Test Results within Two Years Field */}
            <div>
              <label htmlFor="test_results_within_two_years" className="block text-base sm:text-lg font-medium">
                {t('test_results_within_two_years')}
              </label>
              <Select
                id="test_results_within_two_years"
                name="test_results_within_two_years"
                value={testResultsWithinTwoYearsOptions.find(option => option.value === formData.test_results_within_two_years)}
                onChange={(selectedOption) => handleChange({ target: { name: 'test_results_within_two_years', value: selectedOption.value } })}
                options={testResultsWithinTwoYearsOptions}
                className="mt-2"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%',
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>

            {/* Language Test Field */}
            {formData.test_results_within_two_years === 'Yes' && (
              <div>
                <label htmlFor="language_test" className="block text-base sm:text-lg font-medium">
                  {t('language_test')}
                </label>
                <Select
                  id="language_test"
                  name="language_test"
                  value={languageTestOptions.find(option => option.value === formData.language_test)}
                  onChange={(selectedOption) => handleChange({ target: { name: 'language_test', value: selectedOption.value } })}
                  options={languageTestOptions}
                  className="mt-2"
                  classNamePrefix="react-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: '100%',
                      padding: '0.5rem',
                      borderColor: 'gray-300',
                      borderRadius: '0.375rem', // rounded-md equivalent
                    }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 50, // Ensure it appears above other content
                    }),
                  }}
                  placeholder={t('select_language_test')}
                />
              </div>
            )}

            {/* Conditional fields for Language test */}
            {renderLanguageFields()}

            {/* Language Test Date Field */}
            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 space-y-2 sm:space-y-0">
              {/* Label */}
              <label htmlFor="language_test_date" className="text-base sm:text-lg font-medium">
                {t('language_test_date')}
              </label>

              {/* Calendar Icon */}
              <FaCalendarAlt className="text-gray-500 hidden sm:inline-block" />

              {/* DatePicker */}
              <DatePicker
                selected={formData.language_test_date ? new Date(`${formData.language_test_date}T00:00:00`) : null}
                onChange={(date) => {
                  const formattedDate = format(date, 'yyyy-MM-dd'); // Format the date to "yyyy-MM-dd"
                  setFormData({ ...formData, language_test_date: formattedDate });
                }}
                dateFormat="yyyy/MM/dd"
                className="p-2 border border-gray-300 rounded w-full sm:w-auto"
                placeholderText={`${t('select_date')}`}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={15}
              />
            </div>


            {/* Study Permit Application Date Field */}
            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 space-y-2 sm:space-y-0 mt-4">
              {/* Label */}
              <label htmlFor="study_permit_application_date" className="text-base sm:text-lg font-medium">
                {t('study_permit_application_date')}
              </label>

              {/* Calendar Icon */}
              <FaCalendarAlt className="text-gray-500 hidden sm:inline-block" />

              {/* DatePicker */}
              <DatePicker
                selected={formData.study_permit_application_date ? new Date(`${formData.study_permit_application_date}T00:00:00`) : null}
                onChange={(date) => {
                  const formattedDate = format(date, 'yyyy-MM-dd'); // Format the date to "yyyy-MM-dd"
                  setFormData({ ...formData, study_permit_application_date: formattedDate });
                }}
                dateFormat="yyyy/MM/dd"
                className="p-2 border border-gray-300 rounded w-full sm:w-auto"
                placeholderText={`${t('select_date')}`}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={15}
              />
            </div>


            {/* PGWP Application Date Field */}
            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 space-y-2 sm:space-y-0 mt-4">
              {/* Label */}
              <label htmlFor="pgwp_application_date" className="text-base sm:text-lg font-medium">
                {t('pgwp_application_date')}
              </label>

              {/* Calendar Icon */}
              <FaCalendarAlt className="text-gray-500 hidden sm:inline-block" />

              {/* DatePicker */}
              <DatePicker
                selected={formData.pgwp_application_date ? new Date(`${formData.pgwp_application_date}T00:00:00`) : null}
                onChange={(date) => {
                  const formattedDate = format(date, 'yyyy-MM-dd'); // Format the date to "yyyy-MM-dd"
                  setFormData({ ...formData, pgwp_application_date: formattedDate });
                }}
                dateFormat="yyyy/MM/dd"
                className="p-2 border border-gray-300 rounded w-full sm:w-auto"
                placeholderText={`${t('select_date')}`}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={15}
              />
            </div>

            {/* Field of Study Field */}
            <div className="w-full">
              {/* Label */}
              <label htmlFor="field_of_study" className="block text-base sm:text-lg font-medium">
                {t('field_of_study')}
              </label>

              {/* Select Component */}
              <Select
                id="field_of_study"
                name="field_of_study"
                value={fieldOfStudyOptions.find(option => option.value === formData.field_of_study)}
                onChange={(selectedOption) => handleChange({ target: { name: 'field_of_study', value: selectedOption.value } })}
                options={fieldOfStudyOptions}
                className="mt-2 w-full"
                classNamePrefix="react-select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: '100%', // Ensures full width for mobile responsiveness
                    padding: '0.5rem',
                    borderColor: 'gray-300',
                    borderRadius: '0.375rem',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 50,
                  }),
                }}
                placeholder={t('select_an_option')}
              />
            </div>


            {/* Submit button */}
            <div className="text-center">
              <button
                type="submit"
                className="mt-2 md:mt-6 bg-red-600 text-white px-6 py-3 sm:px-8 sm:py-4 md:px-10 md:py-5 rounded-md text-base sm:text-lg md:text-xl hover:bg-red-700"
              >
                {t('submit')}
              </button>
            </div>
          </form>
        </section>

        {/* Results Section */}
        <section className="bg-white p-8 rounded-md shadow-md">

          {/* PGWP Result Header */}

          <div className="bg-red-100 text-red-600 p-2 sm:p-4 rounded-md shadow-sm text-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold">{t('pgwp_result')}</h2>
          </div>

          {/* If eligibility information is available */}
          {responseData.eligible !== undefined && (
            <div className="mt-6 flex flex-col justify-center items-center">
              {/* Eligibility Status */}
              {responseData.eligible ? (
                <div className="bg-green-100 text-green-700 p-4 rounded-md shadow-md text-center mb-4 flex items-center justify-center">
                  <img src="/images/firework.png" alt="firework-icon" className="h-8 w-8 mr-3" />
                  <p className="text-xl font-medium">{t('pgwp_eligible')}</p>
                </div>
              ) : (
                <div className="bg-gray-100 text-green-700 p-4 rounded-md shadow-md text-center mb-4 flex items-center justify-center">
                  <img src="/images/sad.png" alt="sad-icon" className="h-8 w-8 mr-3" />
                  <p className="text-xl font-medium text-red-600">{t('pgwp_not_eligible')}</p>
                </div>

              )}

              {/* If not eligible, show the reasons for ineligibility */}
              {responseData.eligible === false && (
                <div className="mt-6 flex flex-col justify-center items-center w-full max-w-md">
                  {responseData.reason_indices && responseData.reason_indices.length > 0 && (
                    responseData.reason_indices.map((reasonIndex, i) => (
                      <div
                        key={i}
                        className="bg-white p-4 rounded-lg shadow-md border-l-4 border-red-500 mb-4 w-full flex items-start"
                      >
                        {/* Image of the idea bulb */}
                        <img
                          src="/images/idea-bulb.png"
                          alt="Idea Bulb"
                          className="h-12 w-12 mr-3" // Adjust size and spacing
                        />
                        {/* Reason Text */}
                        <p className="text-base md:text-lg leading-relaxed text-gray-700">
                          {t(reasonsData[reasonIndex].summary)}
                        </p>
                      </div>
                    ))
                  )}
                </div>
              )}

              {/* If eligible, show the maximum duration of PGWP */}
              {responseData.eligible === true && (
                <div className="mt-6 flex flex-col justify-center items-center w-full max-w-md">
                  <div className="bg-white rounded-lg shadow-md w-full relative">

                    {/* Top Section with Light Gray Background */}
                    <div className="bg-gray-100 p-4 text-center relative">
                      <p className="text-lg font-bold text-gray-800 tracking-wider">{t('max_pgwp_duration')}</p>
                    </div>

                    {/* Circular Duration Badge - centered with months below */}
                    <div className="flex flex-col items-center justify-center mt-6 mb-6">
                      <div className="bg-green-600 w-48 h-48 rounded-full flex items-center justify-center shadow-lg">
                        <p className="text-5xl font-extrabold text-white">{responseData.max_duration_months}</p>
                      </div>
                      <span className="text-2xl font-semibold text-gray-700 mt-4 tracking-wide uppercase">
                        {t('months')}
                      </span>
                    </div>
                  </div>
                </div>
              )}


            </div>
          )}

          {/* If no eligibility information is available */}
          {responseData.eligible === undefined && (
            // <p className="mt-4 text-center text-lg text-gray-600">{t('guidance_pgwp')}</p>
            <div className="mt-6 flex flex-col justify-center items-center">
              <div className="bg-gray-100 text-green-700 p-4 rounded-md shadow-md text-center mb-4 flex items-center justify-center">
                <img src="/images/right-arrow.png" alt="firework-icon" className="h-8 w-8 mr-3" />
                <p className="text-xl font-medium">{t('guidance_pgwp')}</p>
              </div>
            </div>
          )}

          {/* Add a margin to create space between the two sections */}
          <div className="mt-10"></div>

          {/* PGWP Assessment Policy Header */}
          <div className="bg-red-100 text-red-600 p-2 sm:p-4 rounded-md shadow-sm text-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold">{t('pgwp_assessment_policy')}</h2>
          </div>

          <div className="mt-8 w-full flex flex-col lg:flex-row lg:space-x-6 items-stretch">
            {/* Left Column: Links to Policy and Assessment Criteria */}
            <div className="w-full lg:w-1/3 flex flex-col space-y-6 lg:h-auto">
              {/* PGWP Application Link */}
              <NavLink
                to="/canada-immigration-support/pgwp-application"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full bg-gradient-to-r from-gray-100 to-red-200 p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105 text-center h-full flex items-center justify-center"
              >
                <div>
                  <h2 className="text-lg font-semibold text-red-600">{t('pgwp_application_title')}</h2>
                  <p className="mt-2 text-gray-700">{t('pgwp_application_description')}</p>
                </div>
              </NavLink>
              {/* Policy Link */}
              <NavLink
                to="/canada-immigration-support/pgwp-eligibility/policy"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full bg-gradient-to-r from-gray-100 to-gray-200 p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105 text-center h-full flex items-center justify-center"
              >
                <div>
                  <h2 className="text-lg font-semibold text-red-600">{t('policy')}</h2>
                  <p className="mt-2 text-gray-700">{t('policy_description')}</p>
                </div>
              </NavLink>

              {/* Assessment Criteria Link */}
              <NavLink
                to="/canada-immigration-support/pgwp-eligibility/assessment"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full bg-gradient-to-r from-gray-100 to-gray-200 p-6 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105 text-center h-full flex items-center justify-center"
              >
                <div>
                  <h2 className="text-lg font-semibold text-red-600">{t('assessment_criteria')}</h2>
                  <p className="mt-2 text-gray-700">{t('assessment_criteria_description')}</p>
                </div>
              </NavLink>


            </div>

            {/* Right Column: Search Section */}
            <div className="w-full lg:w-2/3 flex flex-col space-y-6 lg:h-auto bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-lg">
              {/* Heading */}
              <div className="text-center">
                <h2 className="text-2xl sm:text-3xl font-bold text-red-600 mb-4 sm:mb-6">
                  {t('check_field_of_study')}
                </h2>
                <p className="text-gray-600 text-sm sm:text-base mb-3 sm:mb-4">{t('check_field_of_study_description')}</p>
              </div>

              {/* Search Bar */}
              <div className="flex flex-col sm:flex-row items-center w-full space-y-4 sm:space-y-0 sm:space-x-4">
                <div className="w-full">
                  <Select
                    options={fieldsOfStudy}
                    value={selectedField}
                    onChange={setSelectedField}
                    placeholder={t('select_field_of_study')}
                    className="w-full shadow-sm rounded-lg"
                  />
                </div>
                <button
                  onClick={handleSearch}
                  className="w-full sm:w-48 bg-red-500 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md hover:bg-red-600 transition-all focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-md"
                >
                  {t('search')}
                </button>
              </div>

              {/* Search Result Container */}
              <div className="mt-8 flex-grow bg-gradient-to-b from-gray-50 to-gray-100 p-4 sm:p-6 rounded-lg shadow-md border-t-4 border-green-500 flex items-center justify-center min-h-[200px]">
                {searchResult ? (
                  <div className="w-full">
                    <h4 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3 sm:mb-4">{t('search_result')}</h4>
                    <div className="text-gray-700 space-y-3 sm:space-y-4">
                      <p className="text-sm sm:text-base">
                        <strong>{t('cip_code')}:</strong> {searchResult.cip_2021_code}
                      </p>
                      <p className="text-sm sm:text-base">
                        <strong>{t('cip_title')}:</strong> {searchResult.cip_2021_title}
                      </p>
                      <p className="text-sm sm:text-base">
                        <strong>{t('field_of_study')}:</strong> {searchResult.field_of_study === "Agriculture and agri-food" ? t('agriculture_and_agri_food') :
                          searchResult.field_of_study === "Healthcare" ? t('healthcare') :
                            searchResult.field_of_study === "Science, technology, engineering and math (STEM)" ? t('stem') :
                              searchResult.field_of_study === "Trade" ? t('trade') :
                                searchResult.field_of_study === "Transport" ? t('transport') :
                                  searchResult.field_of_study}
                      </p>
                    </div>
                  </div>
                ) : error ? (
                  <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 h-full text-center sm:text-left">
                    <img src="/images/delete.png" alt="Error Icon" className="h-16 w-16 sm:h-20 sm:w-20" />
                    <p className="text-red-500 text-lg sm:text-xl font-semibold">{t('error_message')}</p>
                  </div>
                ) : (
                  <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 h-full text-center sm:text-left">
                    <img src="/images/start.png" alt="Start Icon" className="h-16 w-16 sm:h-20 sm:w-20" />
                    <p className="text-gray-500 text-lg sm:text-xl font-semibold">{t('placeholder_text')}</p>
                  </div>
                )}
              </div>
            </div>

          </div>



        </section>

      </div>
    </div>
  );
};

export default PGWPEligibility;
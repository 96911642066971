import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Ontario = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {
          `
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
          `
        }
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('ontario_program_title')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Programs */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('ontario_overview_title')}
              </h3>
              <p className="text-gray-700">{t('ontario_overview_intro')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('ontario_expression_interest_title')}</strong>
                  <ul className="list-disc list-inside ml-4">
                    <li>
                      <strong>{t('ontario_expression_interest_employer_job_offer_title')}</strong> {t('ontario_expression_interest_employer_job_offer_description')}
                    </li>
                    <li>
                      <strong>{t('ontario_expression_interest_master_phd_graduate_title')}</strong> {t('ontario_expression_interest_master_phd_graduate_description')}
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t('ontario_express_entry_streams_title')}</strong>
                  <ul className="list-disc list-inside ml-4">
                    <li>
                      <strong>{t('ontario_express_entry_human_capital_priorities_title')}</strong> {t('ontario_express_entry_human_capital_priorities_description')}
                    </li>
                    <li>
                      <strong>{t('ontario_express_entry_skilled_trades_title')}</strong> {t('ontario_express_entry_skilled_trades_description')}
                    </li>
                    <li>
                      <strong>{t('ontario_express_entry_french_speaking_worker_title')}</strong> {t('ontario_express_entry_french_speaking_worker_description')}
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>{t('ontario_business_category_title')}</strong> {t('ontario_business_category_description')}
                  <ul className="list-disc list-inside ml-4">
                    <li>{t('ontario_business_category_note')}</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>


          {/* Step 2: Eligibility Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-4">
                {t('ontario_requirements_title')}
              </h3>

              {/* Ontario's Expression of Interest Streams */}
              <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm border-l-4 border-blue-500">
                <h4 className="text-xl font-bold text-blue-600 mb-4 flex items-center">
                  <span className="mr-2">🔹</span>{t('ontario_expression_interest_title')}
                </h4>
                <ul className="list-inside ml-6 text-gray-700 space-y-4">

                  {/* Employer Job Offer Category */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🏢</span>{t('ontario_requirements_employer_job_offer_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('ontario_requirements_employer_job_offer_permanent_full_time')}</li>
                      <li>{t('ontario_requirements_employer_job_offer_median_wage')}</li>
                      <li>{t('ontario_requirements_employer_job_offer_intention')}</li>
                    </ul>
                  </li>

                  {/* Master/PhD Graduate Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🎓</span>{t('ontario_requirements_master_phd_graduate_stream_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('ontario_requirements_eoi_ita_title')}</strong>: {t('ontario_requirements_eoi_ita_description')}</li>

                      {/* Education */}
                      <li>
                        <strong>{t('ontario_requirements_education_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('ontario_requirements_education_masters')}</li>
                          <li>{t('ontario_requirements_education_phd')}</li>
                          <li>{t('ontario_requirements_education_no_license')}</li>
                          <li>{t('ontario_requirements_education_no_obligation')}</li>
                        </ul>
                      </li>

                      {/* Language */}
                      <li>
                        <strong>{t('ontario_requirements_language_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('ontario_requirements_language_masters')}</li>
                          <li>{t('ontario_requirements_language_phd')}</li>
                        </ul>
                      </li>

                      <li><strong>{t('ontario_requirements_residency_title')}</strong>: {t('ontario_requirements_residency_description')}</li>
                      <li><strong>{t('ontario_requirements_settlement_funds_title')}</strong>: {t('ontario_requirements_settlement_funds_description')}
                      <a
                      href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/proof-funds.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                    >
                      {t('ontario_fund_learn_more')}
                      <svg
                        className="w-4 h-4 ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    </a>
                      </li>
                      <li><strong>{t('ontario_requirements_intent_settle_title')}</strong>: {t('ontario_requirements_intent_settle_description')}</li>
                      <li><strong>{t('ontario_requirements_legal_status_title')}</strong>: {t('ontario_requirements_legal_status_description')}</li>

                      {/* Application Period */}
                      <li>
                        <strong>{t('ontario_requirements_application_period_title')}</strong>
                        <ul className="list-disc list-inside ml-6 mt-1 space-y-1">
                          <li>{t('ontario_requirements_application_period_masters')}</li>
                          <li>{t('ontario_requirements_application_period_phd')}</li>
                        </ul>
                      </li>
                      {/* Note */}
                      <li><strong>{t('ontario_requirements_note_title')}</strong>: {t('ontario_requirements_note_description')}</li>
                    </ul>
                  </li>
                </ul>
              </div>

              {/* Ontario's Express Entry Streams */}
              <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm border-l-4 border-green-500">
                <h4 className="text-xl font-bold text-green-600 mb-4 flex items-center">
                  <span className="mr-2">🌐</span>{t('ontario_requirements_express_entry_streams_title')}
                </h4>
                <ul className="list-inside ml-6 text-gray-700 space-y-4">

                  {/* Human Capital Priorities Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-green-600 flex items-center mb-2">
                      <span className="mr-2">💼</span>{t('ontario_requirements_human_capital_priorities_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('ontario_requirements_human_capital_crs')}</li>
                      <li>{t('ontario_requirements_human_capital_education')}</li>
                      <li>{t('ontario_requirements_human_capital_experience')}</li>
                      <li>{t('ontario_requirements_human_capital_french_proficiency')}</li>
                      <li>{t('ontario_requirements_human_capital_funds')}
                      <a
                      href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/proof-funds.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                    >
                      {t('ontario_fund_learn_more')}
                      <svg
                        className="w-4 h-4 ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    </a>
                      </li>
                      <li>{t('ontario_requirements_human_capital_intent_to_live')}</li>
                      <li>{t('ontario_requirements_human_capital_legal_status')}</li>
                    </ul>
                  </li>

                  {/* Skilled Trades Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-green-600 flex items-center mb-2">
                      <span className="mr-2">🔧</span>{t('ontario_requirements_skilled_trades_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('ontario_requirements_skilled_trades_experience')}</li>
                      <li>{t('ontario_requirements_skilled_trades_work_permit')}</li>
                      <li>{t('ontario_requirements_skilled_trades_language_proficiency')}</li>
                      <li>{t('ontario_requirements_skilled_trades_funds')}
                      <a
                      href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/proof-funds.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                    >
                      {t('ontario_fund_learn_more')}
                      <svg
                        className="w-4 h-4 ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    </a>
                      </li>
                      <li>{t('ontario_requirements_skilled_trades_certificate')}</li>
                      <li>{t('ontario_requirements_skilled_trades_intent_to_live')}</li>
                    </ul>
                  </li>

                  {/* French-Speaking Skilled Worker Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-green-600 flex items-center mb-2">
                      <span className="mr-2">🇫🇷</span>{t('ontario_requirements_french_speaking_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('ontario_requirements_french_speaking_experience')}</li>
                      <li>{t('ontario_requirements_french_speaking_education')}</li>
                      <li>{t('ontario_requirements_french_speaking_language_proficiency')}</li>
                      <li>{t('ontario_requirements_french_speaking_funds')}
                      <a
                      href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/proof-funds.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                    >
                      {t('ontario_fund_learn_more')}
                      <svg
                        className="w-4 h-4 ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    </a>
                      </li>
                      <li>{t('ontario_requirements_french_speaking_intent_to_live')}</li>
                      <li>{t('ontario_requirements_french_speaking_legal_status')}</li>
                    </ul>
                  </li>

                </ul>
              </div>
            </div>
          </div>




          {/* Step 3: Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-6">
                {t('ontario_application_process_title')}
              </h3>

              {/* Ontario's Expression of Interest Streams */}
              <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm border-l-4 border-blue-500">
                <h4 className="text-xl font-bold text-blue-600 mb-4 flex items-center">
                  <span className="mr-2">🔹</span>{t('ontario_eoi_streams_title')}
                </h4>
                <ul className="list-inside ml-6 text-gray-700 space-y-4">
                  {/* Employer Job Offer Category */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🏢</span>{t('ontario_requirements_employer_job_offer_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>
                        <strong>{t('ontario_eoi_step_1_title')}</strong>: {t('ontario_eoi_step_1_description')}
                      </li>
                      <li>
                        <strong>{t('ontario_eoi_step_2_title')}</strong>: {t('ontario_eoi_step_2_description')}
                      </li>
                      <li>
                        <strong>{t('ontario_eoi_step_3_title')}</strong>: {t('ontario_eoi_step_3_description')}
                      </li>
                      <li>
                        <strong>{t('ontario_eoi_step_4_title')}</strong>: {t('ontario_eoi_step_4_description')}
                      </li>
                      <li>
                        <strong>{t('ontario_eoi_step_5_title')}</strong>: {t('ontario_eoi_step_5_description')}
                      </li>
                      <li>
                        <strong>{t('ontario_eoi_step_6_title')}</strong>: {t('ontario_eoi_step_6_description')}
                      </li>
                      <li>
                        <strong>{t('ontario_eoi_step_7_title')}</strong>: {t('ontario_eoi_step_7_description')}
                      </li>
                    </ul>
                  </li>

                  {/* Master/PhD Graduate Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🎓</span>{t('ontario_master_phd_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('ontario_master_phd_step_1_title')}</strong>: {t('ontario_master_phd_step_1_description')}</li>
                      <li><strong>{t('ontario_master_phd_step_2_title')}</strong>: {t('ontario_master_phd_step_2_description')}</li>
                      <li><strong>{t('ontario_master_phd_step_3_title')}</strong>: {t('ontario_master_phd_step_3_description')}</li>
                      <li><strong>{t('ontario_master_phd_step_4_title')}</strong>: {t('ontario_master_phd_step_4_description')}</li>
                      <li><strong>{t('ontario_master_phd_step_5_title')}</strong>: {t('ontario_master_phd_step_5_description')}</li>
                      <li><strong>{t('ontario_master_phd_step_6_title')}</strong>: {t('ontario_master_phd_step_6_description')}</li>
                      <li><strong>{t('ontario_master_phd_step_7_title')}</strong>: {t('ontario_master_phd_step_7_description')}</li>
                    </ul>
                  </li>
                </ul>
              </div>
              {/* Ontario's Express Entry Streams */}
              <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm border-l-4 border-green-500">
                <h4 className="text-xl font-bold text-green-600 mb-4 flex items-center">
                  <span className="mr-2">🌐</span>{t('ontario_express_entry_title')}
                </h4>

                {/* Human Capital Priorities Stream & Skilled Trades Stream */}
                <div className="pl-4 mb-4">
                  <h5 className="text-lg font-semibold text-green-600 mb-2">
                    {t('ontario_human_capital_and_skilled_trades_title')}
                  </h5>
                  <ul className="list-disc ml-6 text-gray-700 space-y-1">
                    <li><strong>{t('ontario_human_capital_step_1_title')}</strong>: {t('ontario_human_capital_step_1_description')}</li>
                    <li><strong>{t('ontario_human_capital_step_2_title')}</strong>: {t('ontario_human_capital_step_2_description')}</li>
                    <li><strong>{t('ontario_human_capital_step_3_title')}</strong>: {t('ontario_human_capital_step_3_description')}
                      <ul className="list-disc ml-6 space-y-1">
                        <li>{t('ontario_human_capital_step_3_language')}</li>
                        <li>{t('ontario_human_capital_step_3_education')}</li>
                        <li>{t('ontario_human_capital_step_3_profile_number')}</li>
                        <li>{t('ontario_human_capital_step_3_requirements')}</li>
                      </ul>
                    </li>
                    <li><strong>{t('ontario_human_capital_step_4_title')}</strong>: {t('ontario_human_capital_step_4_description')}</li>
                    <li><strong>{t('ontario_human_capital_step_5_title')}</strong>: {t('ontario_human_capital_step_5_description')}</li>
                    <li><strong>{t('ontario_human_capital_step_6_title')}</strong>: {t('ontario_human_capital_step_6_description')}</li>
                    <li><strong>{t('ontario_human_capital_step_7_title')}</strong>: {t('ontario_human_capital_step_7_description')}</li>
                  </ul>
                </div>


                {/* French-Speaking Skilled Worker Stream */}
                <div className="pl-4">
                  <h5 className="text-lg font-semibold text-green-600 mb-2">
                    {t('toronto_french_speaking_title')}
                  </h5>
                  <ul className="list-disc ml-6 text-gray-700 space-y-1">
                    <li><strong>{t('toronto_french_speaking_step_1_title')}</strong>: {t('toronto_french_speaking_step_1_description')}</li>
                    <li><strong>{t('toronto_french_speaking_step_2_title')}</strong>: {t('toronto_french_speaking_step_2_description')}</li>
                    <li><strong>{t('toronto_french_speaking_step_3_title')}</strong>: {t('toronto_french_speaking_step_3_description')}</li>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('toronto_french_speaking_step_3_requirement_french')}</li>
                      <li>{t('toronto_french_speaking_step_3_requirement_english')}</li>
                      <li>{t('toronto_french_speaking_step_3_requirement_education')}</li>
                      <li>{t('toronto_french_speaking_step_3_requirement_profile_number')}</li>
                    </ul>
                    <li><strong>{t('toronto_french_speaking_step_4_title')}</strong>: {t('toronto_french_speaking_step_4_description')}</li>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('toronto_french_speaking_step_4_note')}</li>
                    </ul>
                    <li><strong>{t('toronto_french_speaking_step_5_title')}</strong>: {t('toronto_french_speaking_step_5_description')}</li>
                    <li><strong>{t('toronto_french_speaking_step_6_title')}</strong>: {t('toronto_french_speaking_step_6_description')}</li>
                    <li><strong>{t('toronto_french_speaking_step_7_title')}</strong>: {t('toronto_french_speaking_step_7_description')}</li>
                  </ul>
                </div>

              </div>
            </div>
          </div>



          {/* Step 4: Pros & Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('ontario_pros_cons_title')}
              </h3>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('ontario_pros_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('ontario_pros_description_1')}</li>
                <li>{t('ontario_pros_description_2')}</li>
              </ul>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('ontario_cons_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('ontario_cons_description_1')}</li>
                <li>{t('ontario_cons_description_2')}</li>
              </ul>
            </div>
          </div>
          {/* Step 5: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('next_actions')}
                </h3>
                <p className="text-gray-700 mb-4">
                  {t('ontario_next_actions_description')}
                </p>
                <Link
                to="https://www.ontario.ca/page/ontario-immigrant-nominee-program-oinp"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
              >
                {t('ontario_learn_more')} →
              </Link>
              </div>

              {/* Right side - Call-to-action button centered */}
              <div className="w-full md:w-1/4 flex justify-center"> {/* Center the button */}
                <Link
                  to="/canada-immigration-support/clb-conversion"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-flex items-center justify-center bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Smaller Image */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-2"  // Smaller image
                  />
                  {t('convert_your_clb_score')}
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Ontario;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Sponsorship = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('sponsorship_program')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Sponsorship */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('overview_of_sponsorship')}
              </h3>
              <p className="text-gray-700">{t('sponsorship_overview')}</p>
            </div>
          </div>

          {/* Step 2: Sponsorship Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('sponsorship_requirements')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('sponsor')}</strong>: {t('sponsor_description')}</li>
                <li><strong>{t('sponsored_person')}</strong>: {t('sponsored_person_description')}</li>
              </ul>
            </div>
          </div>

          {/* Step 3: Sponsorship Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('sponsorship_process')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('submit_sponsorship_application')}</li>
                <li>{t('get_approval')}</li>
                <li>{t('apply_for_pr')}</li>
              </ul>
            </div>
          </div>

          {/* Step 4: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and buttons (4 tiles) */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-4">
                {t('next_actions')}
              </h3>
              <p className="text-gray-700 mb-4">
                  {t('next_actions_sponsorship_description')}
                </p>

              <div className="flex flex-col md:flex-row gap-6">
                
                {/* Sponsoring your Spouse, Partner, or Dependent Children */}
                <Link
                  to="/permanent-resident/sponsorship/spouse-partner-children"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-1/4 bg-red-100 p-4 rounded-lg shadow-md hover:bg-red-200 transform transition-transform duration-300 hover:scale-105 flex items-center justify-center"
                >
                  <div>
                    <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                      {t('sponsor_spouse_partner_children')}
                    </h4>
                  </div>
                </Link>

                {/* Sponsoring an Adopted Child */}
                <Link
                  to="/permanent-resident/sponsorship/adopted-child"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-1/4 bg-red-100 p-4 rounded-lg shadow-md hover:bg-red-200 transform transition-transform duration-300 hover:scale-105 flex items-center justify-center"
                >
                  <div>
                    <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                      {t('sponsor_adopted_child')}
                    </h4>
                  </div>
                </Link>

                {/* Sponsoring Other Relatives */}
                <Link
                  to="/permanent-resident/sponsorship/other-relatives"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-1/4 bg-red-100 p-4 rounded-lg shadow-md hover:bg-red-200 transform transition-transform duration-300 hover:scale-105 flex items-center justify-center"
                >
                  <div>
                    <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                      {t('sponsor_other_relatives')}
                    </h4>
                  </div>
                </Link>

                {/* Sponsoring Parents and Grandparents */}
                <Link
                  to="/permanent-resident/sponsorship/parents-grandparents"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-1/4 bg-red-100 p-4 rounded-lg shadow-md hover:bg-red-200 transform transition-transform duration-300 hover:scale-105 flex items-center justify-center"
                >
                  <div>
                    <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                      {t('sponsor_parents_grandparents')}
                    </h4>
                  </div>
                </Link>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Sponsorship;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const TemporaryResident = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 p-4 md:p-8">
      {/* Hero Section - Introduction */}
      <section className="bg-white p-6 md:p-8 rounded-md shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 items-center">
          <img
            src="/images/temporary-resident-banner.png"
            alt={t('temporary_resident')}
            className="w-full h-auto max-w-[180px] md:max-w-xs mx-auto"
          />
          <div>
            <h1 className="text-2xl md:text-3xl font-bold text-red-600">{t('temporary_resident')}</h1>
            <p className="text-base md:text-lg mt-4">{t('guidance_temporary_resident')}</p>
          </div>
        </div>
      </section>

      {/* Work, Study, Visitor, TRV Sections with Guidance */}
      <section className="bg-white p-6 md:p-8 rounded-md shadow-md mt-6 md:mt-8">
        <h2 className="text-2xl md:text-3xl font-semibold text-red-600">{t('guidance')}</h2>
        <p className="mt-4 text-base md:text-lg">{t('guidance_intro')}</p>

        {/* Work, Study, Visitor, TRV Cards */}
        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
          {/* Work Section */}
          <div className="p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('work')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('work_description')}</p>
            </div>
            <div className="mt-4">
              <Link
                to="/temporary-resident/work"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>

          {/* Study Section */}
          <div className="p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('study')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('study_description')}</p>
            </div>
            <div className="mt-4">
              <Link
                to="/temporary-resident/study"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>

          {/* Visitor Section */}
          <div className="p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('visitor')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('visitor_description')}</p>
            </div>
            <div className="mt-4">
              <Link
                to="/temporary-resident/visitor"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>

          {/* TRV Section */}
          <div className="p-4 bg-red-100 rounded-md shadow-md flex flex-col justify-between hover:bg-red-200 transition-transform duration-300 transform hover:scale-105">
            <div>
              <h3 className="text-lg md:text-xl font-bold text-red-600">{t('trv_application')}</h3>
              <p className="mt-2 text-sm md:text-base">{t('trv_description')}</p>
            </div>
            <div className="mt-4">
              <Link
                to="/temporary-resident/trv"
                className="inline-block bg-red-600 text-white px-3 py-2 md:px-4 md:py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 text-sm md:text-base"
              >
                {t('learn_more')} →
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TemporaryResident;

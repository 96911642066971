import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NovaScotia = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('ns_program_title')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Programs */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('ns_overview_title')}
              </h3>
              <p className="text-gray-700">{t('ns_overview_intro')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('ns_overview_nova_scotia_experience')}</strong> {t('ns_overview_nova_scotia_experience_description')}
                </li>
                <li>
                  <strong>{t('ns_overview_labour_market_priorities')}</strong> {t('ns_overview_labour_market_priorities_description')}
                </li>
                <li>
                  <strong>{t('ns_overview_labour_market_priorities_physicians')}</strong> {t('ns_overview_labour_market_priorities_physicians_description')}
                </li>
                <li>
                  <strong>{t('ns_overview_skilled_worker')}</strong> {t('ns_overview_skilled_worker_description')}
                </li>
                <li>
                  <strong>{t('ns_overview_occupation_in_demand')}</strong> {t('ns_overview_occupation_in_demand_description')}
                </li>
                <li>
                  <strong>{t('ns_overview_international_graduates_in_demand')}</strong> {t('ns_overview_international_graduates_in_demand_description')}
                </li>
                <li>
                  <strong>{t('ns_overview_entrepreneur')}</strong> {t('ns_overview_entrepreneur_description')}
                </li>
                <li>
                  <strong>{t('ns_overview_international_graduate_entrepreneur')}</strong> {t('ns_overview_international_graduate_entrepreneur_description')}
                </li>
              </ul>
            </div>
          </div>

          {/* Step 2: Eligibility Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('ns_requirements_title')}
              </h3>

              {/* Skilled Worker Stream */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_requirements_skilled_worker_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('ns_requirements_skilled_worker_eligibility_requirements')}</strong>
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('ns_skilled_worker_job_offer')}</li>
                  <li>{t('ns_skilled_worker_experience')}</li>
                  <li>{t('ns_skilled_worker_age')}</li>
                  <li>{t('ns_skilled_worker_education')}</li>
                  <li>{t('ns_skilled_worker_skills')}</li>
                  <li>{t('ns_skilled_worker_language')}</li>
                  <li>{t('ns_skilled_worker_financial_resources')}</li>
                </ul>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_skilled_worker_how_to_apply_title')}</strong> {t('ns_requirements_skilled_worker_how_to_apply_description')}
                </p>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_skilled_worker_processing_time_title')}</strong> {t('ns_requirements_skilled_worker_processing_time_description')}
                </p>
              </div>

              {/* Occupation In-Demand Stream */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_requirements_occupation_in_demand_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('ns_requirements_occupation_in_demand_eligibility_requirements')}</strong>
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('ns_occupation_in_demand_job_offer')}</li>
                  <ul className="list-disc list-inside ml-4 text-gray-700">
                    <li>{t('ns_occupation_in_demand_noc_33102')}</li>
                    <li>{t('ns_occupation_in_demand_noc_65310')}</li>
                    <li>{t('ns_occupation_in_demand_noc_73300')}</li>
                    <li>{t('ns_occupation_in_demand_noc_73400')}</li>
                    <li>{t('ns_occupation_in_demand_noc_75110')}</li>
                  </ul>
                  <li>{t('ns_occupation_in_demand_experience')}</li>
                  <li>{t('ns_occupation_in_demand_age')}</li>
                  <li>{t('ns_occupation_in_demand_education')}</li>
                  <li>{t('ns_occupation_in_demand_skills')}</li>
                  <li>{t('ns_occupation_in_demand_language')}</li>
                  <li>{t('ns_occupation_in_demand_financial_resources')}</li>
                </ul>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_occupation_in_demand_how_to_apply_title')}</strong> {t('ns_requirements_occupation_in_demand_how_to_apply_description')}
                </p>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_occupation_in_demand_processing_time_title')}</strong> {t('ns_requirements_occupation_in_demand_processing_time_description')}
                </p>
              </div>

              {/* Critical Construction Worker Pilot */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_requirements_critical_construction_worker_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('ns_requirements_critical_construction_worker_eligibility_requirements')}</strong>
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('ns_critical_construction_worker_job_offer')}</li>
                  <ul className="list-disc list-inside ml-4 text-gray-700">
                    <li>{t('ns_critical_construction_worker_noc_70010')}</li>
                    <li>{t('ns_critical_construction_worker_noc_70011')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72011')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72014')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72020')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72102')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72106')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72200')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72201')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72310')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72320')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72401')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72402')}</li>
                    <li>{t('ns_critical_construction_worker_noc_72500')}</li>
                    <li>{t('ns_critical_construction_worker_noc_73100')}</li>
                    <li>{t('ns_critical_construction_worker_noc_73102')}</li>
                    <li>{t('ns_critical_construction_worker_noc_73110')}</li>
                    <li>{t('ns_critical_construction_worker_noc_73200')}</li>
                    <li>{t('ns_critical_construction_worker_noc_73400')}</li>
                    <li>{t('ns_critical_construction_worker_noc_75101')}</li>
                    <li>{t('ns_critical_construction_worker_noc_75110')}</li>
                    <li>{t('ns_critical_construction_worker_noc_75119')}</li>
                  </ul>

                  <li>{t('ns_critical_construction_worker_experience')}</li>
                  <li>{t('ns_critical_construction_worker_age')}</li>
                  <li>{t('ns_critical_construction_worker_education')}</li>
                  {/* <li>{t('ns_critical_construction_worker_skills')}</li> */}
                  <li>{t('ns_critical_construction_worker_language')}</li>
                  <li>{t('ns_critical_construction_worker_financial_resources')}</li>
                </ul>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_critical_construction_worker_how_to_apply_title')}</strong> {t('ns_requirements_critical_construction_worker_how_to_apply_description')}
                </p>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_critical_construction_worker_processing_time_title')}</strong> {t('ns_requirements_critical_construction_worker_processing_time_description')}
                </p>
              </div>

              {/* International Graduates In-Demand Stream */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_requirements_international_graduates_in_demand_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('ns_requirements_international_graduates_in_demand_eligibility_requirements')}</strong>
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('ns_international_graduates_in_demand_job_offer')}</li>
                  <ul className="list-disc list-inside ml-4 text-gray-700">
                    <li>{t('ns_international_graduates_in_demand_noc_32102')}</li>
                    <li>{t('ns_international_graduates_in_demand_noc_32124')}</li>
                    <li>{t('ns_international_graduates_in_demand_noc_33102')}</li>
                    <li>{t('ns_international_graduates_in_demand_noc_42202')}</li>
                  </ul>
                  <li>{t('ns_international_graduates_in_demand_age')}</li>
                  <li>{t('ns_international_graduates_in_demand_highschool')}</li>
                  <li>{t('ns_international_graduates_in_demand_education')}</li>
                  <li>{t('ns_international_graduates_in_demand_skills')}</li>
                  <li>{t('ns_international_graduates_in_demand_language')}</li>
                  <li>{t('ns_international_graduates_in_demand_financial_resources')}</li>
                </ul>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_international_graduates_in_demand_how_to_apply_title')}</strong> {t('ns_requirements_international_graduates_in_demand_how_to_apply_description')}
                </p>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_international_graduates_in_demand_processing_time_title')}</strong> {t('ns_requirements_international_graduates_in_demand_processing_time_description')}
                </p>
              </div>
              {/* International Graduate Entrepreneur Stream */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_requirements_international_graduate_entrepreneur_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('ns_requirements_international_graduate_entrepreneur_eligibility_requirements')}</strong>
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('ns_international_graduate_entrepreneur_recent_graduate')}</li>
                  <li>{t('ns_international_graduate_entrepreneur_experience')}</li>
                  <li>{t('ns_international_graduate_entrepreneur_permit')}</li>
                  <li>{t('ns_international_graduate_entrepreneur_language')}</li>
                  <li>{t('ns_international_graduate_entrepreneur_financial')}</li>
                </ul>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_international_graduate_entrepreneur_how_to_apply_title')}</strong> {t('ns_requirements_international_graduate_entrepreneur_how_to_apply_description')}
                </p>
              </div>

              {/* Entrepreneur Stream */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_requirements_entrepreneur_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('ns_requirements_entrepreneur_eligibility_requirements')}</strong>
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('ns_entrepreneur_age')}</li>
                  <li>{t('ns_entrepreneur_permanent_residence')}</li>
                  <li>{t('ns_entrepreneur_net_worth')}</li>
                  <li>{t('ns_entrepreneur_investment')}</li>
                  <li>{t('ns_entrepreneur_experience')}</li>
                  <li>{t('ns_entrepreneur_language')}</li>
                  <li>{t('ns_entrepreneur_financial_resources')}</li>
                </ul>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_entrepreneur_how_to_apply_title')}</strong> {t('ns_requirements_entrepreneur_how_to_apply_description')}
                </p>
              </div>

              {/* Physician Stream */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_requirements_physician_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('ns_requirements_physician_eligibility_requirements')}</strong>
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('ns_physician_opportunity')}</li>
                  <li>{t('ns_physician_licensure')}</li>
                  {/* <li>{t('ns_physician_language')}</li> */}
                  <li>{t('ns_physician_financial_resources')}</li>
                </ul>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_physician_how_to_apply_title')}</strong> {t('ns_requirements_physician_how_to_apply_description')}
                </p>
              </div>

              {/* Labour Market Priorities for Physicians */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_requirements_labour_market_priorities_physicians_title')}
                </h4>
                <p className="text-gray-700">
                  <strong>{t('ns_requirements_labour_market_priorities_physicians_eligibility_requirements')}</strong>
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('ns_labour_market_priorities_physicians_opportunity')}</li>
                  <li>{t('ns_labour_market_priorities_physicians_return_service')}</li>
                  <li>{t('ns_labour_market_priorities_physicians_express_entry')}</li>
                  <li>{t('ns_labour_market_priorities_physicians_financial_resources')}</li>
                </ul>
                <p className="text-gray-700 mt-4">
                  <strong>{t('ns_requirements_labour_market_priorities_physicians_how_to_apply_title')}</strong> {t('ns_requirements_labour_market_priorities_physicians_how_to_apply_description')}
                </p>
              </div>
            </div>
          </div>

          {/* Step 3: Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-6">
                {t('ns_application_process_title')}
              </h3>
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_application_process_choose_stream_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('ns_application_process_skilled_worker')}</li>
                  <li>{t('ns_application_process_occupation_in_demand')}</li>
                  <li>{t('ns_application_process_entrepreneur')}</li>
                  <li>{t('ns_application_process_physician')}</li>
                </ul>
              </div>
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_application_process_submit_eoi_title')}
                </h4>
                <p className="text-gray-600">{t('ns_application_process_submit_eoi_description')}</p>
              </div>
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_application_process_submit_full_application_title')}
                </h4>
                <p className="text-gray-600">{t('ns_application_process_submit_full_application_description')}</p>
              </div>
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('ns_application_process_await_nomination_title')}
                </h4>
                <p className="text-gray-600">{t('ns_application_process_await_nomination_description')}</p>
              </div>
            </div>
          </div>

          {/* Step 4: Pros & Cons */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('ns_pros_cons_title')}
              </h3>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('ns_pros_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('ns_pros_description_1')}</li>
                <li>{t('ns_pros_description_2')}</li>
              </ul>
              <h4 className="text-md sm:text-lg font-semibold text-black-600 mb-2">
                {t('ns_cons_title')}
              </h4>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('ns_cons_description_1')}</li>
                <li>{t('ns_cons_description_2')}</li>
              </ul>
            </div>
          </div>

          {/* Link Section */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('next_actions')}
                </h3>
                <p className="text-gray-700 mb-4">
                  {t('ns_next_actions_description')}
                </p>
              </div>
              <div className="w-full md:w-1/4 text-center">
                <Link
                  to="https://liveinnovascotia.com/nova-scotia-nominee-program"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-4"
                  />
                  {t('ns_learn_more')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NovaScotia;

import React from 'react';
import { FaTools } from 'react-icons/fa';  // Import a tool icon to represent development
import { useTranslation } from 'react-i18next';

const UnderDevelopment = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100 p-8">
      <div className="bg-white p-6 rounded-lg shadow-md text-center flex flex-col items-center">
        {/* Icon */}
        <FaTools className="text-green-600 text-6xl mb-6" />

        {/* Heading */}
        <h1 className="text-3xl font-bold text-green-600 mb-4">{t('page_under_development_title')}</h1>

        {/* Message */}
        <p className="text-gray-700 text-lg mb-6">{t('page_under_development_message')}</p>

        {/* Decorative line */}
        <div className="w-24 h-1 bg-red-600 mb-6 rounded-full"></div>

        {/* Additional hint or message */}
        <p className="text-gray-500 italic">{t('page_under_development_hint')}</p>
      </div>
    </div>
  );
};

export default UnderDevelopment;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Study = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('study_permit')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Study Permit */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('overview_of_study_permit')}
              </h3>
              <p className="text-gray-700">{t('study_permit_overview')}</p>
            </div>
          </div>

          {/* Step 2: Study Permit Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('study_requirements_title')}
              </h3>
              <p className="text-gray-700 mb-2">{t('study_requirements_intro')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('study_dli_letter_title')}:</strong> {t('study_dli_letter_description')}
                </li>
                <li>
                  <strong>{t('study_pal_title')}:</strong> {t('study_pal_description')}
                  <a
                    href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/get-documents/provincial-attestation-letter.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                  >
                    {t('learn_more')}
                    <svg
                      className="w-4 h-4 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </a>
                  <ul className="list-disc list-inside ml-8">
                    <li>
                      <strong>{t('study_pal_note_title')}:</strong> {t('study_pal_note_description')}
                      <a
                        href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/guide-5269-applying-study-permit-outside-canada.html#study-quebec"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                      >
                        {t('learn_more')}
                        <svg
                          className="w-4 h-4 ml-2"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 7l5 5m0 0l-5 5m5-5H6"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="text-gray-700 mt-2">{t('study_additional_requirements_intro')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('study_irpa_requirements')}</li>
                <li>
                  <strong>{t('study_financial_support_title')}</strong>
                  <a
                    href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/get-documents/financial-support.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                  >
                    {t('learn_more')}
                    <svg
                      className="w-4 h-4 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </a>
                  <ul className="list-disc list-inside ml-8">
                    <li>{t('study_financial_support_duration_less_than_one_year')}</li>
                    <li>{t('study_financial_support_duration_more_than_one_year')}</li>
                  </ul>
                </li>
                <li>{t('study_no_criminal_activity')}</li>
                <li>{t('study_not_a_security_risk')}</li>
                <li>{t('study_good_health')}</li>
                <li>{t('study_additional_documents')}</li>
              </ul>
            </div>
          </div>



          {/* Step 3: Study Permit Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-6">
                {t('study_application_process_title')}
              </h3>

              {/* Step 1: Gather Documents */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2 mt-6">
                  {t('study_step1_title')}
                </h4>
                <p className="text-gray-700">
                  {t('study_step1_checklist_description')}
                  <a
                    href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/imm5483.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                  >
                    {t('learn_more')}
                    <svg
                      className="w-4 h-4 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </a>
                </p>
              </div>

              {/* Step 2: Create Online Account */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('study_step2_title')}
                </h4>
                <p className="text-gray-700">
                  {t('study_step2_create_account_title')} {t('study_step2_create_account_description')}
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('study_step2_pay_fees')}</li>
                  <li>{t('study_step2_submit_application')}</li>
                  <li>{t('study_step2_check_status')}</li>
                </ul>
              </div>

              {/* Step 3: Complete the Application */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('study_step3_title')}
                </h4>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('study_step3_instruction_guide_description')}</li>
                  <li>{t('study_step3_personalized_checklist')}</li>
                </ul>
              </div>

              {/* Step 4: Pay the Fees */}
              <div className="pl-4 border-l-4 border-gray-200 mb-8">
                <h4 className="text-lg font-semibold text-red-600 mb-2">
                  {t('study_step4_title')}
                </h4>
                <p className="text-gray-700">
                  {t('study_step4_fees_include_title')}
                </p>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('study_step4_processing_fees')}</li>
                  <li>{t('study_step4_biometrics')}</li>
                </ul>
              </div>
            </div>
          </div>



          {/* Step 4: Benefits */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('benefits')}
                </h3>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li>{t('open_work_permit_spouse')}
                    <a
                      href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/family-sponsorship/spouse-partner-children/spouse-common-law-partner-canada-open-work-permit.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-1 ml-2 text-sm font-medium text-white bg-green-500 rounded hover:bg-green-700 transition-colors duration-300"
                    >
                      {t('learn_more')}
                      <svg
                        className="w-4 h-4 ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 7l5 5m0 0l-5 5m5-5H6"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>{t('benefit_work_during_study')}</li>
                  <li>{t('benefit_pgwp')}</li>
                  <li>{t('benefit_apply_pr')}</li>
                </ul>
              </div>

              {/* Right side - Call-to-action Button */}
              <div className="w-full md:w-1/4 text-center">
                <Link
                  to="/canada-immigration-support/pgwp-eligibility"
                  target="_blank" // Opens in a new window
                  rel="noopener noreferrer" // Security enhancement
                  className="inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-md sm:text-lg font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-2"
                  />
                  {t('learn_more_about_open_work_permit')}
                </Link>
              </div>
            </div>
          </div>

          {/* Step 5: Next actions */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">{t('next_actions')}</h3>
              <p className="text-gray-700 mb-4">{t('next_actions_study_description')}</p>
              <Link
                to="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada.html"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
              >
                {t('learn_more_about_study')} →
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Study;

import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaBars, FaTimes, FaAngleRight, FaAngleDown } from 'react-icons/fa';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [showEEDropdown, setShowEEDropdown] = useState(false);
  const [showPnpDropdown, setShowPnpDropdown] = useState(false);
  const [showQuebecDropdown, setShowQuebecDropdown] = useState(false);
  const [showSponsorshipDropdown, setShowSponsorshipDropdown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeSubDropdown, setActiveSubDropdown] = useState(null);

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'vi' : 'en');
  };

  const languageToggleTextMobile = i18n.language === 'en'
    ? (
      <div className="flex flex-col items-center text-center">
        <img src="/images/vietnam.png" alt="Vietnam Flag" className="w-8 h-8 mb-2" />
        <span>Đổi sang tiếng Việt</span>
      </div>
    ) : (
      <div className="flex flex-col items-center text-center">
        <img src="/images/united-kingdom.png" alt="England Flag" className="w-8 h-8 mb-2" />
        <span>Change to English</span>
      </div>
    );

  const handleToggle = () => setIsOpen(!isOpen);

  const handleDropdownToggle = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  const handleSubDropdownToggle = (dropdown) => {
    setActiveSubDropdown(activeSubDropdown === dropdown ? null : dropdown);
  };

  return (
    <header className="bg-black p-4 text-white flex flex-col md:flex-row md:justify-between items-center">
      <style>
        {`
          @keyframes popEffect {
            0%, 100% { transform: scale(1) translateY(0); font-size: 1rem; }
            50% { transform: scale(1.1) translateY(-3px); font-size: 1.1rem; }
          }
          .pop-effect { animation: popEffect 1.5s infinite ease-in-out; }
        
          @keyframes twinkle {
            0%, 100% { color: black; }
            50% { color: red; }
          }
          .twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
          @keyframes colorPulse {
            0%, 100% { color: black; }
            50% { color: red; }
          }
          .color-pulse {
            animation: colorPulse 1s infinite;
          }
      `}
      </style>
      <div className="flex justify-between w-full md:w-auto items-center mb-2 mt-2">
        {/* Logo and App Name */}
        <Link to="/" className="flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="text-red-600 w-8 h-8 md:w-10 md:h-10"
            viewBox="0 0 24 24"
          >
            <path d="M13.707 2.293l1.768 3.537c.132.264.372.443.65.488l4.014.669c.739.123 1.048 1.03.527 1.553l-3.011 3.08c-.194.198-.282.481-.235.761l.706 4.086c.127.737-.669 1.301-1.33.943l-3.555-1.856a.842.842 0 0 0-.786 0l-3.555 1.856c-.661.358-1.457-.206-1.33-.943l.706-4.086c.047-.28-.041-.563-.235-.761l-3.011-3.08c-.52-.523-.211-1.43.527-1.553l4.014-.669c.278-.046.518-.224.65-.488l1.768-3.537c.334-.67 1.298-.67 1.632 0z" />
          </svg>
          <span className="font-semibold text-md md:text-lg">{t('app_name')}</span>
        </Link>


        {/* Mobile Menu Toggle Button */}
        <button onClick={handleToggle} className="text-white focus:outline-none md:hidden ml-auto">
          {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      {/* Side Menu Overlay */}
      <div className={`fixed inset-0 bg-black bg-opacity-80 z-50 transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:hidden`}
        onClick={(e) => {
          // Close the menu if clicked outside the menu area
          if (e.target === e.currentTarget) {
            handleToggle();
          }
        }}
      >
        <div className="bg-gray-900 w-80 h-full p-6 text-white overflow-y-auto fixed right-0 top-0 shadow-xl">
          <button onClick={handleToggle} className="flex items-center bg-red-600 hover:bg-red-700 text-white font-semibold px-3 py-2 rounded-lg transition-colors duration-200 focus:outline-none mb-4">
            <FaTimes size={20} />
            <span className="ml-2">{t('close')}</span>
          </button>

          <ul className="space-y-2">
            <li>
              <NavLink to="/" end className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-4 py-3 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-4 py-3 rounded transition-all duration-200'}>
                {t('home')}
              </NavLink>
            </li>

            {/* Permanent Resident Dropdown */}
            <li>
              <button className="text-gray-300 flex justify-between items-center w-full px-4 py-3 hover:bg-gray-800 rounded transition-all duration-200" onClick={() => handleDropdownToggle('permanentResident')}>
                {t('permanent_resident')}
                <span className={`transition-transform duration-300 ${activeDropdown === 'permanentResident' ? 'rotate-90' : ''}`}>▸</span>
              </button>
              {activeDropdown === 'permanentResident' && (
                <ul className="pl-6 mt-1 space-y-1">
                  <li>
                    <NavLink to="/permanent-resident" end className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('pnp_overview_menu')}
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/permanent-resident/express-entry" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('express_entry')}
                    </NavLink>
                  </li> */}

                  {/* Express Entry Submenu */}
                  <li>
                    <button className="text-gray-300 flex justify-between items-center w-full px-3 py-2 hover:bg-gray-800 rounded transition-all duration-200" onClick={() => handleSubDropdownToggle('expressEntry')}>
                      {t('express_entry')}
                      <span className={`transition-transform duration-300 ${activeSubDropdown === 'expressEntry' ? 'rotate-90' : ''}`}>▸</span>
                    </button>
                    {activeSubDropdown === 'expressEntry' && (
                      <ul className="pl-4 mt-1 space-y-1">
                        <li><NavLink to="/permanent-resident/express-entry" end className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('pnp_overview_menu')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/express-entry/federal-skilled-worker" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('federal_skilled_worker')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/express-entry/federal-skilled-trades" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('federal_skilled_trades')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/express-entry/canadian-experience-class" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('canadian_experience_class')}</NavLink></li>

                      </ul>
                    )}
                  </li>

                  {/* PNP Submenu */}
                  <li>
                    <button className="text-gray-300 flex justify-between items-center w-full px-3 py-2 hover:bg-gray-800 rounded transition-all duration-200" onClick={() => handleSubDropdownToggle('pnp')}>
                      {t('pnp')}
                      <span className={`transition-transform duration-300 ${activeSubDropdown === 'pnp' ? 'rotate-90' : ''}`}>▸</span>
                    </button>
                    {activeSubDropdown === 'pnp' && (
                      <ul className="pl-4 mt-1 space-y-1">
                        <li><NavLink to="/permanent-resident/pnp" end className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('pnp_overview_menu')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/ontario" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('ontario')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/british-columbia" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('british_columbia')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/alberta" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('alberta')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/saskatchewan" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('saskatchewan')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/manitoba" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('manitoba')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/nova-scotia" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('nova_scotia')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/new-brunswick" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('new_brunswick')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/prince-edward-island" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('prince_edward_island')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/newfoundland-labrador" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('newfoundland_labrador')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/yukon" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('yukon')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/pnp/northwest-territories" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('northwest_territories')}</NavLink></li>
                      </ul>
                    )}
                  </li>
                  {/* Quebec Programs Submenu */}
                  <li>
                    <button className="text-gray-300 flex justify-between items-center w-full px-3 py-2 hover:bg-gray-800 rounded transition-all duration-200" onClick={() => handleSubDropdownToggle('quebec')}>
                      {t('quebec_programs')}
                      <span className={`transition-transform duration-300 ${activeSubDropdown === 'quebec' ? 'rotate-90' : ''}`}>▸</span>
                    </button>
                    {activeSubDropdown === 'quebec' && (
                      <ul className="pl-4 mt-1 space-y-1">
                        <li><NavLink to="/permanent-resident/quebec" end className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('pnp_overview_menu')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/quebec/quebec-skilled-worker" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('qswp_program')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/quebec/quebec-experience-program" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('quebec_experience_program')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/quebec/quebec-business-immigration" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('quebec_business_immigration')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/quebec/quebec-family-sponsorship" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('quebec_family_sponsorship')}</NavLink></li>
                      </ul>
                    )}
                  </li>
                  {/* Sponsorship Submenu */}
                  <li>
                    <button className="text-gray-300 flex justify-between items-center w-full px-3 py-2 hover:bg-gray-800 rounded transition-all duration-200" onClick={() => handleSubDropdownToggle('sponsorship')}>
                      {t('sponsorship')}
                      <span className={`transition-transform duration-300 ${activeSubDropdown === 'sponsorship' ? 'rotate-90' : ''}`}>▸</span>
                    </button>
                    {activeSubDropdown === 'sponsorship' && (
                      <ul className="pl-4 mt-1 space-y-1">
                        <li><NavLink to="/permanent-resident/sponsorship" end className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('pnp_overview_menu')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/sponsorship/spouse-partner-children" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('sponsor_spouse_partner_children')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/sponsorship/adopted-child" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('sponsor_adopted_child')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/sponsorship/other-relatives" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('sponsor_other_relatives')}</NavLink></li>
                        <li><NavLink to="/permanent-resident/sponsorship/parents-grandparents" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>{t('sponsor_parents_grandparents')}</NavLink></li>
                      </ul>
                    )}
                  </li>
                  <li>
                    <NavLink to="/permanent-resident/startup-visa" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('startup_visa')}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            {/* Temporary Resident Dropdown */}
            <li>
              <button className="text-gray-300 flex justify-between items-center w-full px-4 py-3 hover:bg-gray-800 rounded transition-all duration-200" onClick={() => handleDropdownToggle('temporaryResident')}>
                {t('temporary_resident')}
                <span className={`transition-transform duration-300 ${activeDropdown === 'temporaryResident' ? 'rotate-90' : ''}`}>▸</span>
              </button>
              {activeDropdown === 'temporaryResident' && (
                <ul className="pl-6 mt-1 space-y-1">
                  {/* Temporary Resident Overview Link */}
                  <li>
                    <NavLink to="/temporary-resident" end className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('pnp_overview_menu')}
                    </NavLink>
                  </li>

                  {/* Work, Study, Visitor Links */}
                  <li>
                    <NavLink to="/temporary-resident/work" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('work')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/temporary-resident/study" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('study')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/temporary-resident/visitor" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('visitor')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/temporary-resident/trv" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('trv_application')}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>


            {/* Canada Immigration Support Dropdown */}
            <li>
              <button className="text-gray-300 flex justify-between items-center w-full px-4 py-3 hover:bg-gray-800 rounded transition-all duration-200" onClick={() => handleDropdownToggle('immigrationSupport')}>
                {t('canada_immigration_support')}
                <span className={`transition-transform duration-300 ${activeDropdown === 'immigrationSupport' ? 'rotate-90' : ''}`}>▸</span>
              </button>
              {activeDropdown === 'immigrationSupport' && (
                <ul className="pl-6 mt-1 space-y-1">
                  <li>
                    <NavLink to="/canada-immigration-support" end className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('pnp_overview_menu')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/canada-immigration-support/crs-score-calculator" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('crs_score_calculator')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/canada-immigration-support/pgwp-eligibility" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('pgwp_eligibility')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/canada-immigration-support/pgwp-application" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('pgwp_application')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/canada-immigration-support/clb-conversion" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-3 py-2 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-3 py-2 rounded transition-all duration-200'}>
                      {t('clb_conversion')}
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <NavLink to="/lmia-tracker" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-4 py-3 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-4 py-3 rounded transition-all duration-200'}>
                {t('lmia_map')}
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact-us" className={({ isActive }) => isActive ? 'border-l-4 border-red-500 text-white bg-gray-800 font-semibold block px-4 py-3 transition-all duration-200' : 'text-gray-300 hover:text-white hover:bg-gray-800 block px-4 py-3 rounded transition-all duration-200'}>
                {t('contact_us')}
              </NavLink>
            </li>

            {/* Buttons: Support Us and Language Toggle */}
            <div className="flex space-x-4 mt-6">
              {/* Support Us Button */}
              <Link
                to="/support-us"
                className="bg-white text-black font-semibold py-2 px-4 rounded-md hover:bg-red-600 hover:text-white transition duration-300 ease-in-out flex flex-col items-center text-center"
              >
                <div className="mb-2">
                  <img src="/images/handshake.png" alt="Support Us" className="w-8 h-8 pop-effect" />
                </div>
                <span className="color-pulse">{t('support_us')}</span>
              </Link>


              {/* Language Toggle Button */}
              <button
                onClick={toggleLanguage}
                className="bg-white text-black font-semibold py-2 px-4 rounded-md hover:bg-red-600 hover:text-white transition duration-300 ease-in-out flex flex-col items-center text-center"
              >
                <span>{languageToggleTextMobile}</span>
              </button>
            </div>
          </ul>
        </div>
      </div>




      {/* Desktop Navigation Menu */}
      <nav className={`hidden md:block`}>
        <ul className="flex flex-col md:flex-row md:space-x-8 space-y-4 md:space-y-0 md:ml-4 text-md">
        <li className="flex justify-center">
            <NavLink
              to="/"
              end
              className={({ isActive }) =>
                `text-center ${isActive ? 'text-red-600 font-bold' : 'text-white hover:text-red-600'}`
              }
            >
              {t('home')}
            </NavLink>
          </li>


          {/* Permanent Resident Dropdown */}
          <li className="relative group flex justify-center">
            <NavLink
              to="/permanent-resident"
              className={({ isActive }) =>
                `text-center ${isActive ? 'text-red-600 font-bold' : 'text-white hover:text-red-600'}`
              }
            >
              {t('permanent_resident')}
            </NavLink>
            <div className="absolute left-0 top-full hidden group-hover:block bg-white text-black w-48 rounded-lg shadow-lg z-10">
              {/* <NavLink
                to="/permanent-resident/express-entry"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('express_entry')}
              </NavLink> */}

              {/* Express Entry Dropdown */}
              <div
                className="relative group"
                onMouseEnter={() => setShowEEDropdown(true)}
                onMouseLeave={() => setShowEEDropdown(false)}
              >
                <NavLink
                  to="/permanent-resident/express-entry"
                  className="block px-4 py-2 hover:bg-red-100 flex items-center space-x-1"
                >
                  <span>{t('express_entry')}</span>
                  <FaAngleRight className="text-lg text-gray-600" />
                </NavLink>
                {showEEDropdown && (
                  <div className="absolute left-full top-0 bg-white text-black w-48 rounded-lg shadow-lg z-20">
                    <NavLink
                      to="/permanent-resident/express-entry/federal-skilled-worker"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('federal_skilled_worker')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/express-entry/federal-skilled-trades"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('federal_skilled_trades')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/express-entry/canadian-experience-class"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('canadian_experience_class')}
                    </NavLink>

                  </div>
                )}
              </div>

              {/* PNP Dropdown */}
              <div
                className="relative group"
                onMouseEnter={() => setShowPnpDropdown(true)}
                onMouseLeave={() => setShowPnpDropdown(false)}
              >
                <NavLink
                  to="/permanent-resident/pnp"
                  className="block px-4 py-2 hover:bg-red-100 flex items-center space-x-1"
                >
                  <span>{t('pnp')}</span>
                  <FaAngleRight className="text-lg text-gray-600" />
                </NavLink>
                {showPnpDropdown && (
                  <div className="absolute left-full top-0 bg-white text-black w-48 rounded-lg shadow-lg z-20">
                    <NavLink
                      to="/permanent-resident/pnp/ontario"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('ontario')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/pnp/british-columbia"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('british_columbia')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/pnp/alberta"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('alberta')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/pnp/saskatchewan"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('saskatchewan')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/pnp/manitoba"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('manitoba')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/pnp/nova-scotia"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('nova_scotia')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/pnp/new-brunswick"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('new_brunswick')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/pnp/prince-edward-island"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('prince_edward_island')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/pnp/newfoundland-labrador"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('newfoundland_labrador')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/pnp/yukon"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('yukon')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/pnp/northwest-territories"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('northwest_territories')}
                    </NavLink>
                  </div>
                )}
              </div>

              {/* Quebec Dropdown */}
              <div
                className="relative group"
                onMouseEnter={() => setShowQuebecDropdown(true)}
                onMouseLeave={() => setShowQuebecDropdown(false)}
              >
                <NavLink
                  to="/permanent-resident/quebec"
                  className="block px-4 py-2 hover:bg-red-100 flex items-center space-x-1"
                >
                  <span>{t('quebec_programs')}</span>
                  <FaAngleRight className="text-lg text-gray-600" />
                </NavLink>
                {showQuebecDropdown && (
                  <div className="absolute left-full top-0 bg-white text-black w-48 rounded-lg shadow-lg z-20">
                    <NavLink
                      to="/permanent-resident/quebec/quebec-skilled-worker"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('qswp_program')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/quebec/quebec-experience-program"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('quebec_experience_program')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/quebec/quebec-business-immigration"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('quebec_business_immigration')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/quebec/quebec-family-sponsorship"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('quebec_family_sponsorship')}
                    </NavLink>
                  </div>
                )}
              </div>


              {/* Sponsorship Dropdown */}
              <div
                className="relative group"
                onMouseEnter={() => setShowSponsorshipDropdown(true)}
                onMouseLeave={() => setShowSponsorshipDropdown(false)}
              >
                <NavLink
                  to="/permanent-resident/sponsorship"
                  className="block px-4 py-2 hover:bg-red-100 flex items-center space-x-1"
                >
                  <span>{t('sponsorship')}</span>
                  <FaAngleRight className="text-lg text-gray-600" />
                </NavLink>

                {showSponsorshipDropdown && (
                  <div className="absolute left-full top-0 bg-white text-black w-48 rounded-lg shadow-lg z-20">
                    <NavLink
                      to="/permanent-resident/sponsorship/spouse-partner-children"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('sponsor_spouse_partner_children')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/sponsorship/adopted-child"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('sponsor_adopted_child')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/sponsorship/other-relatives"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('sponsor_other_relatives')}
                    </NavLink>
                    <NavLink
                      to="/permanent-resident/sponsorship/parents-grandparents"
                      className="block px-4 py-2 hover:bg-red-100"
                    >
                      {t('sponsor_parents_grandparents')}
                    </NavLink>
                  </div>
                )}
              </div>


              <NavLink
                to="/permanent-resident/startup-visa"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('startup_visa')}
              </NavLink>
            </div>
          </li>

          {/* Temporary Resident Dropdown */}
          <li className="relative group flex justify-center">
            <NavLink
              to="/temporary-resident"
              className={({ isActive }) =>
                `text-center ${isActive ? 'text-red-600 font-bold' : 'text-white hover:text-red-600'}`
              }
            >
              {t('temporary_resident')}
            </NavLink>
            <div className="absolute left-0 top-full hidden group-hover:block bg-white text-black w-48 rounded-lg shadow-lg z-10">
              <NavLink
                to="/temporary-resident/work"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('work')}
              </NavLink>
              <NavLink
                to="/temporary-resident/study"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('study')}
              </NavLink>
              <NavLink
                to="/temporary-resident/visitor"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('visitor')}
              </NavLink>
              <NavLink
                to="/temporary-resident/trv"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('trv_application')}
              </NavLink>
            </div>
          </li>

          {/* Canada Immigration Support Dropdown */}
          <li className="relative group flex justify-center">
            <NavLink
              to="/canada-immigration-support"
              className={({ isActive }) =>
                `text-center ${isActive ? 'text-red-600 font-bold' : 'text-white hover:text-red-600'}`
              }
            >
              {t('canada_immigration_support')}
            </NavLink>
            <div className="absolute left-0 top-full hidden group-hover:block bg-white text-black w-56 rounded-lg shadow-lg z-10">
              <NavLink
                to="/canada-immigration-support/crs-score-calculator"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('crs_score_calculator')}
              </NavLink>
              <NavLink
                to="/canada-immigration-support/pgwp-eligibility"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('pgwp_eligibility')}
              </NavLink>
              <NavLink
                to="/canada-immigration-support/pgwp-application"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('pgwp_application')}
              </NavLink>
              <NavLink
                to="/canada-immigration-support/clb-conversion"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('clb_conversion')}
              </NavLink>
              {/* <NavLink
                to="/canada-immigration-support/lmia-map"
                className="block px-4 py-2 hover:bg-red-100"
              >
                {t('lmia_map')}
              </NavLink> */}

            </div>
          </li>

          {/* LMIA Tracker */}
          <li className="flex justify-center">
            <NavLink
              to="/lmia-tracker"
              className={({ isActive }) =>
                `text-center ${isActive ? 'text-red-600 font-bold' : 'text-white hover:text-red-600'}`
              }
            >
              {t('lmia_map')}
            </NavLink>
          </li>

          {/* Contact Us */}
          <li className="flex justify-center">
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                `text-center ${isActive ? 'text-red-600 font-bold' : 'text-white hover:text-red-600'}`
              }
            >
              {t('contact_us')}
            </NavLink>
          </li>
        </ul>
      </nav>

      {/* Buttons: Support Us and Language Toggle */}
      <div className="flex space-x-4 w-full md:w-auto justify-center">
        {/* Support Us Button */}
        <Link
          to="/support-us"
          className="flex items-center justify-center w-1/2 md:w-auto bg-white text-black text-center font-semibold py-1 px-2 md:py-2 md:px-4 rounded-md hover:bg-red-600 hover:text-white transition duration-300 ease-in-out md:ml-4 "
        >
          <img
            src="/images/handshake.png"
            alt="Support Us"
            className="inline-block w-5 h-5 sm:w-6 sm:h-6 md:w-8 md:h-8 mr-1 pop-effect"
          />
          <span className="color-pulse text-xs sm:text-sm md:text-base">{t('support_us')}</span>
        </Link>

        {/* Language Toggle Button */}
        <button
          onClick={toggleLanguage}
          className="flex items-center justify-center w-1/2 md:w-auto bg-white text-black font-semibold py-1 px-2 md:py-2 md:px-4 rounded-md hover:bg-red-600 hover:text-white transition duration-300 ease-in-out"
        >
          <span className="text-xs sm:text-sm md:text-base flex items-center">
            {i18n.language === 'en' ? (
              <>
                <img src="/images/vietnam.png" alt="Vietnam Flag" className="inline-block w-5 h-5 sm:w-6 sm:h-6 md:w-8 md:h-8 mr-2" />
                Đổi sang tiếng Việt
              </>
            ) : (
              <>
                <img src="/images/united-kingdom.png" alt="England Flag" className="inline-block w-5 h-5 sm:w-6 sm:h-6 md:w-8 md:h-8 mr-2" />
                Change to English
              </>
            )}
          </span>
        </button>
      </div>


    </header>
  );
};

export default Header;

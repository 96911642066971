import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PNP = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('pnp_program')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Program */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('overview_of_program')}
              </h3>
              <p className="text-gray-700">{t('pnp_overview')}</p>
            </div>
          </div>

          {/* Step 2: General Requirements */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('general_requirements')}
              </h3>
              <p className="text-gray-700">{t('pnp_general_requirements')}</p>
            </div>
          </div>

          {/* Step 3: Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('process')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>{t('pnp_apply_province')}</li>
                <li>{t('pnp_receive_nomination')}</li>
                <li>{t('pnp_apply_for_pr')}</li>
              </ul>
            </div>
          </div>

          {/* Step 4: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Next actions section */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-4">
                {t('next_actions')}
              </h3>
              <p className="text-gray-700 mb-6">{t('next_actions_pnp_description')}</p>

              {/* Link buttons for provinces */}
              <div className="flex flex-wrap justify-center gap-4">
                <Link
                  to="/permanent-resident/pnp/alberta"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  Alberta
                </Link>
                <Link
                  to="/permanent-resident/pnp/british-columbia"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  British Columbia
                </Link>
                <Link
                  to="/permanent-resident/pnp/manitoba"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  Manitoba
                </Link>
                <Link
                  to="/permanent-resident/pnp/new-brunswick"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  New Brunswick
                </Link>
                <Link
                  to="/permanent-resident/pnp/newfoundland-labrador"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  Newfoundland and Labrador
                </Link>
                <Link
                  to="/permanent-resident/pnp/nova-scotia"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  Nova Scotia
                </Link>
                <Link
                  to="/permanent-resident/pnp/ontario"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  Ontario
                </Link>
                <Link
                  to="/permanent-resident/pnp/prince-edward-island"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  Prince Edward Island
                </Link>
                <Link
                  to="/permanent-resident/pnp/saskatchewan"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  Saskatchewan
                </Link>
                <Link
                  to="/permanent-resident/pnp/northwest-territories"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  Northwest Territories
                </Link>
                <Link
                  to="/permanent-resident/pnp/yukon"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-200 hover:bg-green-300 text-gray-800 text-center text-sm font-semibold py-4 px-8 rounded-md shadow-md transition-transform transform hover:scale-105"
                >
                  Yukon
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PNP;

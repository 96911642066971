import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const QuebecPrograms = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('quebec_programs')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Quebec Programs */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('overview_of_program')}
              </h3>
              <p className="text-gray-700">{t('quebec_overview')}</p>
            </div>
          </div>

          {/* Step 2 & Step 3 Combined: Quebec Immigration Programs */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-4">
                {t('quebec_programs_list')}
              </h3>
              <p className="text-gray-700 mb-4">{t('quebec_programs_list_description')}</p>

              {/* Flex container for Quebec Immigration Programs */}
              <div className="flex flex-col md:flex-row gap-6 justify-between">

                {/* Program 1: Québec Skilled Worker Program (QSWP) */}
                <Link
                  to="/permanent-resident/quebec/quebec-skilled-worker"
                  target="_blank"
                  className="w-full md:w-1/3 bg-red-100 p-4 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-lg cursor-pointer no-underline"
                >
                  <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                    {t('qswp_program')}
                  </h4>
                  <p className="text-gray-700 mb-2 text-center">{t('qswp_description')}</p>
                  <p className="text-gray-700 text-center">
                    <strong>{t('click_to_learn_more')}</strong>
                  </p>
                </Link>

                {/* Program 2: Quebec Experience Program */}
                <Link
                  to="/permanent-resident/quebec/quebec-experience-program"
                  target="_blank"
                  className="w-full md:w-1/3 bg-red-100 p-4 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-lg cursor-pointer no-underline"
                >
                  <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                    {t('quebec_experience_program')}
                  </h4>
                  <p className="text-gray-700 mb-2 text-center">{t('quebec_experience_program_description')}</p>
                  <p className="text-gray-700 text-center">
                    <strong>{t('click_to_learn_more')}</strong>
                  </p>
                </Link>

                {/* Program 3: Québec Business Immigration (Investor, Entrepreneur, Self-Employed) */}
                <Link
                  to="/permanent-resident/quebec/quebec-business-immigration"
                  target="_blank"
                  className="w-full md:w-1/3 bg-red-100 p-4 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-lg cursor-pointer no-underline"
                >
                  <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                    {t('quebec_business_immigration')}
                  </h4>
                  <p className="text-gray-700 mb-2 text-center">{t('quebec_business_description')}</p>
                  <p className="text-gray-700 text-center">
                    <strong>{t('click_to_learn_more')}</strong>
                  </p>
                </Link>

                {/* Program 4: Québec Family Sponsorship */}
                <Link
                  to="/permanent-resident/quebec/quebec-family-sponsorship"
                  target="_blank"
                  className="w-full md:w-1/3 bg-red-100 p-4 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-lg cursor-pointer no-underline"
                >
                  <h4 className="text-md sm:text-lg font-semibold text-red-600 mb-2 text-center">
                    {t('quebec_family_sponsorship')}
                  </h4>
                  <p className="text-gray-700 mb-2 text-center">{t('quebec_family_sponsorship_description')}</p>
                  <p className="text-gray-700 text-center">
                    <strong>{t('click_to_learn_more')}</strong>
                  </p>
                </Link>
                
              </div>

            </div>
          </div>



          {/* Step 4: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('next_actions')}
                </h3>
                <p className="text-gray-700 mb-4">
                  {t('next_actions_quebec_description')}
                </p>
              </div>

              {/* Right side - Button aligned to the left */}
              <div className="w-full md:w-1/4 text-center">
                <Link
                  to="https://www.quebec.ca/en/immigration/permanent"
                  target="_blank" // Opens in a new window
                  rel="noopener noreferrer" // Security enhancement
                  className="inline-flex items-center justify-start bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Image at the beginning of the button */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-4"
                  />
                  {t('learn_more_about_quebec')}
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default QuebecPrograms;

import React from 'react';
import { useTranslation } from 'react-i18next';

const PGWPAssessment = () => {
  const { t } = useTranslation();

  return (
    <div className="p-8 bg-gray-100">
      {/* Page Heading */}
      <h1 className="text-4xl font-bold text-center text-red-600 mb-8">{t('assessment_criteria_description')}</h1>
      <div className="relative">
        <div className="border-l-4 border-red-600 ml-6">
          {/* Program Level Check */}
          <div className="relative mb-8 pl-8">
            <div className="absolute w-4 h-4 bg-red-600 rounded-full -left-2 top-1"></div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-red-600 mb-2">{t('pgwp_assessment_program_level')}</h3>
              <p className="text-gray-700">{t('pgwp_assessment_program_level_desc')}</p>
            </div>
          </div>

          {/* Language Proficiency Assessment */}
          <div className="relative mb-8 pl-8">
            <div className="absolute w-4 h-4 bg-red-600 rounded-full -left-2 top-1"></div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-red-600 mb-2">{t('pgwp_assessment_language_proficiency')}</h3>
              <p className="text-gray-700">{t('pgwp_assessment_language_proficiency_desc')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-600 mt-2">
                <li>{t('pgwp_assessment_language_clb7')}</li>
                <li>{t('pgwp_assessment_language_clb5')}</li>
              </ul>
            </div>
          </div>

          {/* Field of Study Requirement */}
          <div className="relative mb-8 pl-8">
            <div className="absolute w-4 h-4 bg-red-600 rounded-full -left-2 top-1"></div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-red-600 mb-2">{t('pgwp_assessment_field_of_study')}</h3>
              <p className="text-gray-700">{t('pgwp_assessment_field_of_study_desc')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-600 mt-2">
                <li>{t('pgwp_agriculture_agri_food')}</li>
                <li>{t('pgwp_healthcare')}</li>
                <li>{t('pgwp_stem')}</li>
                <li>{t('pgwp_trade')}</li>
                <li>{t('pgwp_transport')}</li>
              </ul>
            </div>
          </div>

          {/* Date of Study Permit Application */}
          <div className="relative mb-8 pl-8">
            <div className="absolute w-4 h-4 bg-red-600 rounded-full -left-2 top-1"></div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-red-600 mb-2">{t('pgwp_assessment_study_permit')}</h3>
              <p className="text-gray-700">{t('pgwp_assessment_study_permit_desc')}</p>
            </div>
          </div>

          {/* Language Test Validity */}
          <div className="relative mb-8 pl-8">
            <div className="absolute w-4 h-4 bg-red-600 rounded-full -left-2 top-1"></div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-red-600 mb-2">{t('pgwp_assessment_language_validity')}</h3>
              <p className="text-gray-700">{t('pgwp_assessment_language_validity_desc')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PGWPAssessment;

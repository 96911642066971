import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const BritishColumbia = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {
          `
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
          `
        }
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('bc_program_title')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Programs */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('bc_overview_title')}
              </h3>
              <p className="text-gray-700">{t('bc_overview_intro')}</p>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li>
                  <strong>{t('bc_overview_skills_immigration')}</strong> {t('bc_overview_skills_immigration_description')}
                </li>
                <li>
                  <strong>{t('bc_overview_entrepreneur_immigration')}</strong> {t('bc_overview_entrepreneur_immigration_description')}
                </li>
              </ul>
            </div>
          </div>

          {/* Step 2: Eligibility Requirements for BC PNP */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-4">
                {t('bc_requirements_title')}
              </h3>

              {/* Skills Immigration Streams */}
              <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm border-l-4 border-blue-500">
                <h4 className="text-xl font-bold text-blue-600 mb-4 flex items-center">
                  <span className="mr-2">🔹</span>{t('bc_skills_immigration_streams_title')}
                </h4>
                <ul className="list-inside ml-6 text-gray-700 space-y-4">

                  {/* Skilled Worker */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🔧</span>{t('bc_skilled_worker_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('bc_skilled_worker_job_offer')}</li>
                      <li>{t('bc_skilled_worker_experience')}</li>
                      <li>{t('bc_skilled_worker_qualifications')}</li>
                      <li>{t('bc_skilled_worker_language')}</li>
                      <li>{t('bc_skilled_worker_wage')}</li>
                      <li>{t('bc_skilled_worker_support')}</li>
                    </ul>
                  </li>

                  {/* Health Authority */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🏥</span>{t('bc_health_authority_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('bc_health_authority_job_offer')}</li>
                      <li>{t('bc_health_authority_qualifications')}</li>
                    </ul>
                  </li>

                  {/* Entry Level and Semi-Skilled (ELSS) */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🔹</span>{t('bc_entry_level_semi_skilled_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('bc_elss_job_offer')}</li>
                      <li>{t('bc_elss_experience')}</li>
                      <li>{t('bc_elss_qualifications')}</li>
                      <li>{t('bc_elss_language_education')}</li>
                      <li>{t('bc_elss_wage')}</li>
                      <li>{t('bc_elss_support')}</li>
                    </ul>
                  </li>

                  {/* International Graduate */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🎓</span>{t('bc_international_graduate_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('bc_international_graduate_education')}</li>
                      <li>{t('bc_international_graduate_job_offer')}</li>
                      <li>{t('bc_international_graduate_qualifications')}</li>
                      <li>{t('bc_international_graduate_language')}</li>
                      <li>{t('bc_international_graduate_wage')}</li>
                    </ul>
                  </li>

                  {/* International Post-Graduate */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🎓</span>{t('bc_international_post_graduate_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>{t('bc_international_post_graduate_education')}</li>
                      <li>{t('bc_international_post_graduate_intent')}</li>
                    </ul>
                  </li>

                </ul>
              </div>

              {/* BC PNP Entrepreneur Immigration Streams */}
              <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm border-l-4 border-indigo-500">
                <h4 className="text-xl font-bold text-indigo-600 mb-4 flex items-center">
                  <span className="mr-2">🌐</span>{t('bc_entrepreneur_stream_title')}
                </h4>
                <ul className="list-inside ml-6 text-gray-700 space-y-4">

                  {/* Base Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-indigo-600 flex items-center mb-2">
                      <span className="mr-2">💼</span>{t('bc_base_stream_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('bc_personal_requirements_title')}</strong>
                        <ul className="list-disc ml-6 mt-1 space-y-1">
                          <li>{t('bc_personal_requirements_net_worth')}</li>
                          <li>{t('bc_personal_requirements_experience')}</li>
                          <li>{t('bc_personal_requirements_language')}</li>
                          <li>{t('bc_personal_requirements_admission')}</li>
                          <li>{t('bc_personal_requirements_status')}</li>
                        </ul>
                      </li>
                      <li><strong>{t('bc_business_requirements_title')}</strong>
                        <ul className="list-disc ml-6 mt-1 space-y-1">
                          <li>{t('bc_business_requirements_establish')}</li>
                          <li>{t('bc_business_requirements_investment')}</li>
                          <li>{t('bc_business_requirements_job_creation')}</li>
                        </ul>
                      </li>
                      <li><strong>{t('bc_fees_title')}</strong>
                        <ul className="list-disc ml-6 mt-1 space-y-1">
                          <li>{t('bc_fees_registration')}</li>
                          <li>{t('bc_fees_application')}</li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  {/* Regional Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-indigo-600 flex items-center mb-2">
                      <span className="mr-2">🌍</span>{t('bc_regional_stream_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('bc_regional_features_title')}</strong>: {t('bc_regional_features_description')}</li>
                      <li><strong>{t('bc_regional_requirements_title')}</strong>
                        <ul className="list-disc ml-6 mt-1 space-y-1">
                          <li>{t('bc_regional_requirements_business')}</li>
                          <li>{t('bc_regional_requirements_experience')}</li>
                          <li>{t('bc_regional_requirements_net_worth')}</li>
                          <li>{t('bc_regional_requirements_investment')}</li>
                          <li>{t('bc_regional_requirements_job_creation')}</li>
                          <li>{t('bc_regional_requirements_language')}</li>
                          <li>{t('bc_regional_requirements_status')}</li>
                          <li>{t('bc_regional_requirements_admission')}</li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  {/* Strategic Projects Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-indigo-600 flex items-center mb-2">
                      <span className="mr-2">🚀</span>{t('bc_strategic_projects_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li><strong>{t('bc_company_eligibility_title')}</strong>
                        <ul className="list-disc ml-6 mt-1 space-y-1">
                          <li>{t('bc_company_eligibility_financial')}</li>
                          <li>{t('bc_company_eligibility_investment')}</li>
                          <li>{t('bc_company_eligibility_benefit')}</li>
                        </ul>
                      </li>
                      <li><strong>{t('bc_key_staff_eligibility_title')}</strong>
                        <ul className="list-disc ml-6 mt-1 space-y-1">
                          <li>{t('bc_key_staff_position')}</li>
                          <li>{t('bc_key_staff_offer')}</li>
                        </ul>
                      </li>
                      <li><strong>{t('bc_requirements_title')}</strong>
                        <ul className="list-disc ml-6 mt-1 space-y-1">
                          <li>{t('bc_requirements_equity_investment')}</li>
                          <li>{t('bc_requirements_establish')}</li>
                          <li>{t('bc_requirements_job_creation')}</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

            </div>
          </div>


          {/* Step 3: Application Process */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-6">
                {t('bc_application_process_title')}
              </h3>

              {/* BC PNP Skills Immigration Streams */}
              <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm border-l-4 border-blue-500">
                <h4 className="text-xl font-bold text-blue-600 mb-4 flex items-center">
                  <span className="mr-2">🔹</span>{t('bc_skills_immigration_title')}
                </h4>
                <ul className="list-disc ml-6 text-gray-700 space-y-4">
                  <li><strong>{t('bc_skills_choose_stream_title')}</strong>: {t('bc_skills_choose_stream_description')}</li>
                  <li><strong>{t('bc_skills_registration_title')}</strong>: {t('bc_skills_registration_description')}</li>
                  <li><strong>{t('bc_skills_invitation_to_apply_title')}</strong>: {t('bc_skills_invitation_to_apply_description')}</li>
                  <li><strong>{t('bc_skills_submit_application_title')}</strong>: {t('bc_skills_submit_application_description')}</li>
                  <li><strong>{t('bc_skills_nomination_title')}</strong>: {t('bc_skills_nomination_description')}</li>
                  <li><strong>{t('bc_skills_permanent_residence_title')}</strong>: {t('bc_skills_permanent_residence_description')}</li>
                  <li><strong>{t('bc_skills_additional_notes_title')}</strong>: {t('bc_skills_additional_notes_description')}</li>
                </ul>
              </div>

              {/* BC PNP Entrepreneur Immigration Streams */}
              <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm border-l-4 border-blue-500">
                <h4 className="text-xl font-bold text-blue-600 mb-4 flex items-center">
                  <span className="mr-2">🔹</span>{t('bc_pnp_entrepreneur_immigration_streams_title')}
                </h4>
                <ul className="list-inside ml-6 text-gray-700 space-y-4">

                  {/* Base Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">💼</span>{t('bc_base_stream_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>
                        <strong>{t('bc_prepare_business_proposal_title')}</strong>: {t('bc_prepare_business_proposal_description')}
                      </li>
                      <li>
                        <strong>{t('bc_register_proposal_title')}</strong>: {t('bc_register_proposal_description')}
                        <ul className="list-disc ml-6 mt-1 space-y-1">
                        </ul>
                      </li>
                      <li>
                        <strong>{t('bc_await_invitation_title')}</strong>: {t('bc_await_invitation_description')}
                      </li>
                      <li>
                        <strong>{t('bc_verification_net_worth_title')}</strong>: {t('bc_verification_net_worth_description')}
                      </li>
                      <li>
                        <strong>{t('bc_submit_application_title')}</strong>: {t('bc_submit_application_description')}
                      </li>
                      <li>
                        <strong>{t('bc_performance_agreement_title')}</strong>: {t('bc_performance_agreement_description')}
                      </li>
                      <li>
                        <strong>{t('bc_work_permit_title')}</strong>: {t('bc_work_permit_description')}
                      </li>
                      <li>
                        <strong>{t('bc_build_business_title')}</strong>: {t('bc_build_business_description')}
                      </li>
                      <li>
                        <strong>{t('bc_nominee_declaration_title')}</strong>: {t('bc_nominee_declaration_description')}
                      </li>
                      <li>
                        <strong>{t('bc_permanent_residence_application_title')}</strong>: {t('bc_permanent_residence_application_description')}
                      </li>
                    </ul>
                  </li>

                  {/* Regional Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🌍</span>{t('bc_regional_stream_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>
                        <strong>{t('bc_connect_with_community_title')}</strong>: {t('bc_connect_with_community_description')}
                      </li>
                      <li>
                        <strong>{t('bc_exploratory_visit_title')}</strong>: {t('bc_exploratory_visit_description')}
                      </li>
                      <li>
                        <strong>{t('bc_request_referral_title')}</strong>: {t('bc_request_referral_description')}
                      </li>
                    </ul>
                  </li>

                  {/* Strategic Projects Stream */}
                  <li>
                    <h5 className="text-lg font-semibold text-blue-600 flex items-center mb-2">
                      <span className="mr-2">🚀</span>{t('bc_strategic_projects_title')}
                    </h5>
                    <ul className="list-disc ml-6 mt-1 space-y-1">
                      <li>
                        <strong>{t('bc_exploratory_visit_title')}</strong>: {t('bc_leader_exploratory_visit_description')}
                      </li>
                      <li>
                        <strong>{t('bc_discuss_proposal_title')}</strong>: {t('bc_discuss_proposal_description')}
                      </li>
                      <li>
                        <strong>{t('bc_register_interest_title')}</strong>: {t('bc_register_interest_description')}
                      </li>
                      <li>
                        <strong>{t('bc_invitation_apply_title')}</strong>: {t('bc_invitation_apply_description')}
                      </li>
                      <li>
                        <strong>{t('bc_full_application_title')}</strong>: {t('bc_full_application_description')}
                      </li>
                      <li>
                        <strong>{t('bc_performance_agreements_title')}</strong>: {t('bc_performance_agreements_description')}
                      </li>
                      <li>
                        <strong>{t('bc_work_permit_arrival_title')}</strong>: {t('bc_work_permit_arrival_description')}
                      </li>
                      <li>
                        <strong>{t('bc_business_establishment_title')}</strong>: {t('bc_business_establishment_description')}
                      </li>
                      <li>
                        <strong>{t('bc_permanent_residency_nomination_title')}</strong>: {t('bc_permanent_residency_nomination_description')}
                      </li>
                      <li>
                        <strong>{t('bc_permanent_residency_application_title')}</strong>: {t('bc_permanent_residency_application_description')}
                      </li>
                    </ul>
                  </li>

                </ul>
              </div>

            </div>
          </div>
          {/* Step 4: Next actions that should be taken immediately */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">

              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('next_actions')}
                </h3>
                <p className="text-gray-700 mb-4">
                  {t('bc_next_actions_description')}
                </p>
                <Link
                  to="https://www.welcomebc.ca/immigrate-to-b-c/about-the-bc-provincial-nominee-program"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-red-700 transition duration-300 "
                >
                  {t('bc_learn_more')} →
                </Link>
              </div>

              {/* Right side - Call-to-action button centered */}
              <div className="w-full md:w-1/4 flex justify-center"> {/* Center the button */}
                <Link
                  to="/canada-immigration-support/clb-conversion"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Smaller Image */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-2"  // Smaller image
                  />
                  {t('convert_your_clb_score')}
                </Link>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  );
};

export default BritishColumbia;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CanadianExperienceClass = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 sm:p-8 bg-gray-100">
      <style>
        {`
          @keyframes twinkle {
            0%, 100% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.7;
              transform: scale(1.05);
            }
          }
          .animate-twinkle {
            animation: twinkle 1s infinite ease-in-out;
          }
        `}
      </style>

      {/* Heading */}
      <h1 className="text-2xl sm:text-4xl font-bold text-center text-red-600 mb-8">
        {t('canadian_experience_class_program')}
      </h1>

      {/* Timeline */}
      <div className="relative">
        <div className="timeline border-l-2 border-red-300 ml-3 sm:ml-6">

          {/* Step 1: Overview of Program */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('overview_of_program')}
              </h3>
              <p className="text-gray-700">{t('canadian_experience_class_overview')}</p>
            </div>
          </div>

          {/* Step 2: Eligibility Requirements with call-to-action button */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">
              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('eligibility_requirements')}
                </h3>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <li><strong>{t('work_experience')}</strong>: {t('cec_work_experience_description')}</li>
                  <li><strong>{t('language_proficiency')}</strong>: {t('cec_language_proficiency_description')}</li>
                  <li><strong>{t('education')}</strong>: {t('cec_education_description')}</li>
                  <li><strong>{t('proof_of_funds')}</strong>: {t('cec_no_proof_of_funds')}</li>
                  <li><strong>{t('cec_health_security')}</strong>: {t('cec_health_security_description')}</li>
                </ul>
              </div>

              {/* Right side - Call-to-action button */}
              <div className="w-full md:w-1/4 flex justify-center">
                <Link
                  to="/canada-immigration-support/clb-conversion"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110"
                >
                  {/* Smaller Image */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-2"
                  />
                  {t('convert_your_clb_score')}
                </Link>
              </div>
            </div>
          </div>

          {/* Step 3: How to Apply */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                {t('how_to_apply')}
              </h3>
              <ul className="list-disc list-inside ml-4 text-gray-700">
                <li><strong>{t('check_eligibility')}</strong>: {t('cec_check_eligibility_description')}</li>
                <li><strong>{t('prepare_documents')}</strong>: {t('cec_prepare_documents_description')}</li>
                <li><strong>{t('create_express_entry_profile')}</strong>: {t('cec_create_express_entry_profile_description')}</li>
                <li><strong>{t('receive_ita')}</strong>: {t('cec_receive_ita_description')}</li>
                <li><strong>{t('submit_pr_application')}</strong>: {t('cec_submit_pr_application_description')}</li>
              </ul>
            </div>
          </div>

          {/* Step 4: Pros and Cons with call-to-action button */}
          <div className="timeline-step relative mb-8 pl-4 sm:pl-8">
            <div className="timeline-circle absolute w-3 h-3 sm:w-4 sm:h-4 bg-red-600 rounded-full -left-2 top-1/2 transform -translate-y-1/2"></div>

            {/* Flex container to divide text and button */}
            <div className="timeline-content bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">
              {/* Left side - Text */}
              <div className="w-full md:w-3/4 mb-4 md:mb-0">
                <h3 className="text-lg sm:text-xl font-semibold text-red-600 mb-2">
                  {t('pros_cons')}
                </h3>
                <ul className="list-disc list-inside ml-4 text-gray-700">
                  <strong>{t('pros')}</strong>:
                  <ul className="ml-4 list-disc">
                    <li>{t('cec_no_proof_of_funds')}</li>
                    <li>{t('cec_familiarity_with_canada')}</li>
                  </ul>

                  <strong>{t('cons')}</strong>:
                  <ul className="ml-4 list-disc">
                    <li>{t('cec_high_competition')}</li>
                  </ul>
                </ul>
              </div>

              {/* Right side - Call-to-action button */}
              <div className="w-full md:w-1/4 flex justify-center">
                <Link
                  to="/canada-immigration-support/crs-score-calculator"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center bg-green-300 hover:bg-green-400 text-gray-800 text-lg sm:text-xl font-semibold py-3 sm:py-4 px-8 sm:px-12 rounded-full animate-twinkle shadow-lg transform transition-transform duration-300 hover:scale-110 ml-5 text-center"
                >
                  {/* Smaller Image */}
                  <img
                    src="/images/click-here.png"
                    alt="Click Here"
                    className="w-8 h-8 sm:w-12 sm:h-12 mr-2"
                  />
                  {t('calculate_your_crs_score')}
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default CanadianExperienceClass;
